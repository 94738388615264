import { createColumnHelper } from "@tanstack/react-table"
import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import { OrderDetailsType, SupportTableDataType } from "types/orderManagement"
import { capitalize, getDisplayDate } from "utils/helper"

const columnHelper = createColumnHelper<OrderDetailsType>()
const supportColumnHelper = createColumnHelper<SupportTableDataType>()

export const ecommerceColumns = [
  columnHelper.accessor("name", {
    header: "Items",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("categoryId", {
    header: "Category",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("price", {
    header: "Price",
    enableSorting: false,
    cell: (info: any) => info.getValue()?.value,
  }),
  // columnHelper.accessor("status", {
  //   header: "Return Status",
  //   enableSorting: false,
  //   cell: (info: any) => info.getValue() || "N/A",
  // }),
  columnHelper.accessor("quantity", {
    header: "Quantity",
    enableSorting: false,
    cell: (info: any) => info?.getValue()?.count,
  }),
  // columnHelper.accessor("returns", {
  //   header: "Status",
  //   enableSorting: false,
  //   cell: (info: any) => info.getValue()?.status,
  // }),
]

export const mobilityColumns = [
  columnHelper.accessor("name", {
    header: "Items",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("categoryId", {
    header: "Category",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("price", {
    header: "Price",
    cell: (info: any) => info.getValue().value,
  }),
  columnHelper.accessor("quantity", {
    header: "Quantity",
    cell: (info: any) => info.getValue().count,
  }),
]

export const supportColumns = [
  supportColumnHelper.accessor("id", {
    header: "Issue Id",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  supportColumnHelper.accessor("title", {
    header: "Title",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  supportColumnHelper.accessor("status", {
    header: "Issue Status",
    enableSorting: false,
    cell: (info: any) =>
      info.getValue() ? (
        <RoleStatusBadge type={capitalize(info.getValue().toLowerCase())} />
      ) : null,
  }),
  supportColumnHelper.accessor("created_at", {
    header: "Raised On",
    enableSorting: false,
    cell: (info: any) => getDisplayDate(info.getValue()),
  }),
]
