import { createContext, useContext, useState } from "react"

interface contextType {
    isCoinsVisible: boolean
    setIsCoinsVisible: Function
    coinsApiInProgress: boolean
    setCoinsApiInProgress: Function
}
const CoinsContext = createContext<contextType>({
    isCoinsVisible: false,
    setIsCoinsVisible: () => {},
    coinsApiInProgress: false,
    setCoinsApiInProgress: () => {},
})

export const CoinsProvider = ({ children }: any) => {
    const [isCoinsVisible, setIsCoinsVisible] = useState(false)
    const [coinsApiInProgress, setCoinsApiInProgress] = useState(false)

    return (
        <CoinsContext.Provider
            value={{
                isCoinsVisible,
                setIsCoinsVisible,
                coinsApiInProgress,
                setCoinsApiInProgress,
            }}
        >
            {children}
        </CoinsContext.Provider>
    )
}

export const useCoins = () => useContext(CoinsContext)
