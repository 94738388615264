import axios from "axios"
import { getStorageItem } from "services/storage"
import { searchBaseUrl } from "services/url"

const tagsUrl = `${searchBaseUrl}/tag`

export const getTags = async (page: string | null, size: string | null) => {
  if (page !== null && size !== null) {
    const response = await axios({
      method: "GET",
      url: `${tagsUrl}?page=${page}&pageSize=${size}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const getAllTags = async () => {
  const response = await axios({
    method: "GET",
    url: `${tagsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const postTag = async (sendAsMultiPart: boolean, tagData: any) => {
  let formData
  if (sendAsMultiPart) {
    formData = new FormData()
    for (const key in tagData) {
      formData.append(key, tagData[key])
    }
  }
  const response = await axios({
    method: "POST",
    url: `${tagsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
      ...(sendAsMultiPart && { "Content-Type": "multipart/form-data" }),
    },
    data: sendAsMultiPart ? formData : tagData,
  })
  return await response.data
}

export const putTag = async (
  sendAsMultiPart: boolean,
  tagId: string,
  tagData: any
) => {
  let formData
  if (sendAsMultiPart) {
    formData = new FormData()
    for (const key in tagData) {
      formData.append(key, tagData[key])
    }
  }
  const response = await axios({
    method: "PUT",
    url: `${tagsUrl}/${tagId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
      ...(sendAsMultiPart && { "Content-Type": "multipart/form-data" }),
    },
    data: sendAsMultiPart ? formData : tagData,
  })
  return await response.data
}
