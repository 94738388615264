import CustomTable from "components/CustomTable/CustomTable"
import { SupportTableDataType } from "types/orderManagement"
import { supportColumns } from "./Columns"

type Props = {
  supportTicketsData: SupportTableDataType[]
}

export default function SupportTable({ supportTicketsData }: Props) {
  const count = supportTicketsData?.length || 0

  return (
    <div>
      <div className="txt-18 txtw-6">Support Tickets</div>
      <div className="txt-14 txt-4 clr-grey mb-20">
        {count} ticket{count === 1 ? "" : "s"}
      </div>
      <CustomTable
        columns={supportColumns}
        data={supportTicketsData}
        className="mb-30"
      />
    </div>
  )
}
