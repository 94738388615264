import "react-phone-input-2/lib/style.css"
import { useAdminTypeContext } from "context/adminType"
import MobilityCustomerManagement from "components/CustomerManagement/MobilityCustomerManagement"
import ECommerceCustomerManagement from "components/CustomerManagement/ECommerceCustomerManagement"

const CustomerManagement = () => {
  const { adminType } = useAdminTypeContext()

  return adminType === "mobility" ? (
    <MobilityCustomerManagement />
  ) : (
    <ECommerceCustomerManagement />
  )
}

export default CustomerManagement
