import axios from "axios"
import { getStorageItem } from "services/storage"
import { adminBaseUrl } from "services/url"

const packagingUrl = `${adminBaseUrl}/charges/packaging`

export const getAllPackagingCharges = async () => {
  const response = await axios({
    method: "GET",
    url: `${packagingUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const postPackaging = async (packagingData: any) => {
  const response = await axios({
    method: "POST",
    url: `${packagingUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...packagingData,
    },
  })
  return await response.data
}

export const putPackaging = async (packagingId: string, packagingData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${packagingUrl}/${packagingId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...packagingData,
    },
  })
  return await response.data
}
