import { Header } from "components/Common/Header"
import ECommerceDashboard from "components/Dashboard/ECommerceDashboard"
import MobilityDashboard from "components/Dashboard/MobilityDashboard"
import { useAdminTypeContext } from "context/adminType"

function Dashboard() {
  const { adminType } = useAdminTypeContext()

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1 flex flex-col">
        {adminType === "mobility" ? (
          <MobilityDashboard />
        ) : (
          <ECommerceDashboard />
        )}
      </main>
    </div>
  )
}

export default Dashboard
