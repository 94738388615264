import axios from "axios"
import { getStorageItem } from "services/storage"
import { searchBaseUrl } from "services/url"

const layoutsUrl = `${searchBaseUrl}/layout`

export const getLayouts = async (page: string | null, size: string | null) => {
  if (page !== null && size !== null) {
    const response = await axios({
      method: "GET",
      url: `${layoutsUrl}?page=${page}&pageSize=${size}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const getAllLayouts = async () => {
  const response = await axios({
    method: "GET",
    url: `${layoutsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getOutletLayout = async (outletId: string) => {
  const response = await axios({
    method: "GET",
    url: `${layoutsUrl}/mapping?hubId=${outletId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getLayoutOutlets = async (layoutId: string) => {
  const response = await axios({
    method: "GET",
    url: `${layoutsUrl}/mapping?layoutId=${layoutId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const postLayout = async (layoutData: any) => {
  const response = await axios({
    method: "POST",
    url: `${layoutsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...layoutData,
    },
  })
  return await response.data
}

export const putLayout = async (layoutId: string, layoutData: any) => {
  const response = await axios({
    method: "PUT",
    url: `${layoutsUrl}/${layoutId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...layoutData,
    },
  })
  return await response.data
}

export const attachLayout = async (outletId: string, layoutId: string) => {
  const response = await axios({
    method: "POST",
    url: `${layoutsUrl}/attach`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      layoutId,
      hubId: outletId,
    },
  })
  return await response.data
}
