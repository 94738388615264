import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Common/Button";
import { EmptyPage } from "components/Common/EmptyPage";
import { Header } from "components/Common/Header";
import { SearchBarContainer } from "components/Common/SearchBarContainer";
import { AddDeliveryCharge, DeliveryCard } from "components/Charges";
import { useNotification } from "context";
import { getAllDeliveryCharges } from "services/apis/charges";
import { NoOffersSVG } from "assets/icons/index";
import { checkAuth } from "utils/helper";
import { useUpdateTokens } from "utils/hooks";

const DeliveryCharges = () => {
  const [deliveryChargesList, setDeliveryChargesList] = useState<Array<any>>(
    []
  );
  const [isAddDeliveryModalVisible, setIsAddDeliveryModalVisible] =
    useState(false);
  const [editDeliveryModal, setEditDeliveryModal] = useState({
    isOpen: false,
    delivery: {},
  });
  const { setText } = useNotification();
  const navigate = useNavigate();
  useUpdateTokens();

  useEffect(() => {
    (async () => {
      if (!isAddDeliveryModalVisible && !editDeliveryModal.isOpen) {
        checkAuth(
          async () => {
            const response = await getAllDeliveryCharges();
            if (response.success) {
              setDeliveryChargesList([...response.data]);
            }
          },
          setText,
          navigate
        );
      }
    })();
  }, [isAddDeliveryModalVisible, editDeliveryModal.isOpen, setText, navigate]);

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <div className="pg-subheader h-40 flex justify-stretch align-center mt-30 mb-30 mx-80">
          <SearchBarContainer
            item={["Charges"]}
            itemsCount={deliveryChargesList?.length}
            listingCount={deliveryChargesList?.length}
          />
          <Button
            type="filled-primary"
            text="+ Add Delivery Charge"
            onClick={() => {
              setIsAddDeliveryModalVisible(true);
            }}
            classNames={{
              root: "brd-10",
              label: "txt-14 txtw-5",
            }}
          />
        </div>
        {deliveryChargesList.length > 0 ? (
          <>
            <section className="min-h-70 grid grid-cols-4 items-stretch gap-20 mt-5 mb-5 mx-80">
              {deliveryChargesList?.map((del) => (
                <DeliveryCard
                  key={del._id}
                  delivery={del}
                  setEditDeliveryModal={setEditDeliveryModal}
                />
              ))}
            </section>
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Delivery charges" />
        )}
      </main>
      <AddDeliveryCharge
        isAddDeliveryModalVisible={isAddDeliveryModalVisible}
        setIsAddDeliveryModalVisible={setIsAddDeliveryModalVisible}
        editDeliveryModal={editDeliveryModal}
        setEditDeliveryModal={setEditDeliveryModal}
      />
    </div>
  );
};

export default DeliveryCharges;
