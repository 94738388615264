import { createContext, useContext, useState } from "react"

interface intervalType {
    id: number
    setId: Function
}
const TokenIntervalContext = createContext<intervalType>({
    id: 0,
    setId: () => {},
})

export const IntervalProvider = ({ children }: any) => {
    const [id, setId] = useState(0)

    return (
        <TokenIntervalContext.Provider
            value={{
                id,
                setId,
            }}
        >
            {children}
        </TokenIntervalContext.Provider>
    )
}

export const useTokenInterval = () => useContext(TokenIntervalContext)
