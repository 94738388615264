import styled from "styled-components";

// Styled components
export const SectionContainer = styled.div`
  background-color: #f8f9fa; /* Light background */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 12px; /* Padding around content */
  margin-bottom: 10px; /* Space between sections */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth hover transition */

  &:hover {
    background-color: #e9ecef; /* Slightly darker background on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly larger shadow */
  }
`;

export const DragIcon = styled.span`
  cursor: grab;
  font-size: 16px;
  color: #6c757d; /* Subtle icon color */

  &:active {
    cursor: grabbing; /* Change cursor when dragging */
  }
`;

export const SectionName = styled.span`
  font-size: 16px;
  color: #495057; /* Text color */
`;
