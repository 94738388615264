export const setStorageItem = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(
      `Error setting sessionStorage item with key "${key}":`,
      error
    );
  }
};

export const removeStorageItem = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(
      `Error removing sessionStorage item with key "${key}":`,
      error
    );
  }
};

export const getStorageItem = (key: string) => {
  try {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(
      `Error getting sessionStorage item with key "${key}":`,
      error
    );
    return null;
  }
};

export const emptyStorage = () => {
  try {
    sessionStorage.clear();
  } catch (error) {
    console.error("Error clearing sessionStorage:", error);
  }
};
