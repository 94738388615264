import { Divider } from "@mantine/core"
import { Header } from "components/Common/Header"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import { useGetCustomerListQuery } from "hooks/Admin/customerAPI"
import { CustomerAPIOptionsType } from "types/customerManagement"
import CustomerTable from "./CustomerTable"
import { useAdminTypeContext } from "context/adminType"
import { mobilityColumns } from "./Columns"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer"

function MobilityCustomerManagement() {
  const location = useLocation()
  const [customersAPIOptions, setCustomerAPIOptions] =
    useState<CustomerAPIOptionsType>({
      page: 1,
      size: "10",
    })

  const { data: customersList } = useGetCustomerListQuery({
    page: customersAPIOptions?.page,
    size: customersAPIOptions?.size,
  })

  const { adminType } = useAdminTypeContext()

  const onPageChange = (value: number) =>
    setCustomerAPIOptions((prev) => ({ ...prev, page: value }))

  const onRecordsSizeChange = (value: string) =>
    setCustomerAPIOptions((prev) => ({ ...prev, size: value }))

  useEffect(() => {
    if (location) {
      setCustomerAPIOptions((prev) => ({
        ...prev,
        page: Number(location?.search?.split("=")[1]) || 1,
      }))
    }
  }, [location])

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Customer Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Customer", "Customers"]}
            itemsCount={customersList?.data?.totalCount}
            listingCount={customersList?.data?.count}
          />
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        <div className="mx-80 pb-20">
          <OptionsBarContainer
            // placeholder="Search for customers"
            isSearch={false}
            selectedTab="customer"
            // onSearchChange={onSearchChange}
            isSort={false}
            isFilter={false}
            buttonText={"Download CSV"}
            onButtonClick={() => {}}
          />
        </div>
        <CustomerTable
          columns={mobilityColumns(customersAPIOptions?.page)}
          adminType={adminType}
          tableData={customersList?.data?.details}
          customersAPIOptions={customersAPIOptions}
          onPageChange={onPageChange}
          onRecordsSizeChange={onRecordsSizeChange}
          totalEntries={customersList?.data?.totalCount}
        />
      </main>
    </div>
  )
}

export default MobilityCustomerManagement
