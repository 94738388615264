import axios from "axios"
import { userAppBaseURL } from "services/url"

const userUrl = `${userAppBaseURL}/user`

export const getOtp = async (email: string) => {
    const response = await axios({
        method: "POST",
        url: `${userUrl}/requestOtp`,
        data: {
            email:email
        }
    })
    return await response.data
}

export const loginUser = async (email: string, otp: any) => {
    const response = await axios({
        method: "POST",
        url: `${userUrl}/login`,
        data: {
            email:email,
            otp:otp
        }
    })
    return await response.data
}