import { Header } from "components/Common/Header"
import "./ChangePassword.scss"

const ChangePassword = () => {
  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <section className="min-h-70 flex flex-col bg-white mx-80 brd-10">
          Change Password
        </section>
      </main>
    </div>
  )
}

export default ChangePassword
