// Need to use the React-specific entry point to allow generating React hooks
import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "hooks/baseQuery"
import { mobilityRidesBaseURL } from "services/url"
import { PayloadType } from "types/common"

export const mobilityOrderAPI = createApi({
  reducerPath: "mobilityOrderAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: String(mobilityRidesBaseURL),
  }),
  endpoints: (builder) => ({
    getMobilityRidesList: builder.query({
      query: ({ page, size }: PayloadType) => {
        const searchParams = new URLSearchParams()
        searchParams.set("page", String(page))
        searchParams.set("size", String(size))

        return {
          method: "GET",
          url: `/mobility/rider/admin/getRides?${searchParams.toString()}&filter=RIDE_INIT`,
        }
      },
    }),

    getMobilityRideDetails: builder.query({
      query: (id: any) => {
        return {
          method: "GET",
          url: `/mobility/rider/admin/rideDetails/${id}`,
        }
      },
    }),
  }),
})

export const { useGetMobilityRideDetailsQuery, useGetMobilityRidesListQuery } =
  mobilityOrderAPI
