import axios from "axios"
import { getStorageItem } from "services/storage"
import { searchBaseUrl } from "services/url"

const categoryUrl = `${searchBaseUrl}/category`

export const getAllCategories = async () => {
    const response = await axios({
      method: "GET",
      url: `${categoryUrl}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
}
