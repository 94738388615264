import { useAdminTypeContext } from "context/adminType"
import ECommerceOrderDetails from "components/OrderDetails/ECommerceOrderDetails"
import MobilityRideDetails from "components/OrderDetails/MobilityRideDetails"

const OrderDetails = () => {
  const { adminType } = useAdminTypeContext()

  return adminType === "mobility" ? (
    <MobilityRideDetails />
  ) : (
    <ECommerceOrderDetails />
  )
}

export default OrderDetails
