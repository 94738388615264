import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
import { Modal } from "components/Common/Modal";
import { useNotification } from "context";
import { postPackaging, putPackaging } from "services/apis/charges/packaging";
import { checkAuth } from "utils/helper";

const AddPackagingCharge = ({
  isAddPackagingModalVisible,
  setIsAddPackagingModalVisible,
  editPackagingModal,
  setEditPackagingModal,
}: {
  isAddPackagingModalVisible: boolean;
  setIsAddPackagingModalVisible: Function;
  editPackagingModal: any;
  setEditPackagingModal: Function;
}) => {
  const [packagingApiInProgress, setPackagingApiInProgress] = useState(false);
  const basicDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      title: "",
      basePrice: "",
      minOrderVal: "",
    },
    validate: {
      title: (value) => (value.length === 0 ? "Title is required" : null),
      basePrice: (value) =>
        value?.length === 0 ? "Base price is required" : null,
      minOrderVal: (value) =>
        value?.length === 0 ? "Order value is required" : null,
    },
  });
  const { setText } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (editPackagingModal.isOpen) {
        basicDetailsForm.setValues({
          title: editPackagingModal.packaging.label,
          basePrice: editPackagingModal.packaging.basePrice,
          minOrderVal: editPackagingModal.packaging.freeOnOrderAmountAbove,
        });
      }
    })();
  }, [basicDetailsForm, editPackagingModal]);

  async function handleAddPackaging() {
    if (isAddPackagingModalVisible) {
      checkAuth(
        async () => {
          const response = await postPackaging({
            label: basicDetailsForm.values.title,
            basePrice: String(basicDetailsForm.values.basePrice),
            freeOnOrderAmountAbove: String(basicDetailsForm.values.minOrderVal),
          });
          if (response.success) {
            setIsAddPackagingModalVisible(false);
            basicDetailsForm.setValues({
              title: "",
              basePrice: "",
              minOrderVal: "",
            });
          }
        },
        setText,
        navigate,
        setPackagingApiInProgress
      );
    } else {
      checkAuth(
        async () => {
          const response = await putPackaging(
            editPackagingModal.packaging._id,
            {
              label: basicDetailsForm.values.title,
              basePrice: String(basicDetailsForm.values.basePrice),
              freeOnOrderAmountAbove: String(
                basicDetailsForm.values.minOrderVal
              ),
            }
          );
          if (response.success) {
            setEditPackagingModal({
              isOpen: false,
              packaging: {},
            });
            basicDetailsForm.setValues({
              title: "",
              basePrice: "",
              minOrderVal: "",
            });
          }
        },
        setText,
        navigate,
        setPackagingApiInProgress
      );
    }
  }

  function handleFormSubmit() {
    handleAddPackaging();
  }

  function handleFormError() {}

  return (
    <Modal
      title={`${
        isAddPackagingModalVisible ? "Add" : "Edit"
      } the Packaging charge`}
      opened={isAddPackagingModalVisible || editPackagingModal.isOpen}
      onClose={() => {
        if (isAddPackagingModalVisible) {
          setIsAddPackagingModalVisible(false);
        } else {
          setEditPackagingModal({
            isOpen: false,
            packaging: {},
          });
        }
        basicDetailsForm.setValues({
          title: "",
          basePrice: "",
          minOrderVal: "",
        });
      }}
    >
      <form
        onSubmit={basicDetailsForm.onSubmit(handleFormSubmit, handleFormError)}
        className="w-500 pl-20 pr-20"
      >
        <div className="grid grid-cols-2 col-gap-40 row-gap-25">
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="TITLE"
            required
          >
            <TextInput
              placeholder="Charge title"
              variant="filled"
              {...basicDetailsForm.getInputProps("title")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            classNames={{
              root: "gcol-start-1 gcol-end-2",
              label: "clr-grey txt-12 txtw-4",
            }}
            label={"BASE PRICE"}
            required
          >
            <NumberInput
              placeholder="₹"
              variant="filled"
              {...basicDetailsForm.getInputProps("basePrice")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="FREE ON ORDERS ABOVE"
            required
          >
            <NumberInput
              placeholder="₹"
              variant="filled"
              {...basicDetailsForm.getInputProps("minOrderVal")}
            />
          </Input.Wrapper>
        </div>
        <div className="flex justify-end bd-top mt-10 pt-10">
          <Button
            type="filled-primary"
            text={`${isAddPackagingModalVisible ? "Add" : "Save"}`}
            loading={packagingApiInProgress}
            onClick={() => {}}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddPackagingCharge;
