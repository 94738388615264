import { ActionIcon } from "@mantine/core";
import { SortIconSVG } from "assets/icons/index";

interface rowType {
  rowItems: {
    value: string;
    sort?: boolean;
  }[];
}

const ItemsHeaderRow = ({ rowItems }: rowType) => {
  return (
    <div className={`grid grid-cols-${rowItems.length} grid-rowh-75`}>
      {rowItems?.map((rowItem, index) => (
        <div
          key={index}
          className={`flex align-center txt-12 txtw-6 clr-grey pl-30 pr-30 bd-btm`}
          style={
            rowItem.value === "Name"
              ? { display: "inherit", backgroundColor: "#F9FAFB" }
              : rowItem.value === "Email"
              ? { display: "inherit", backgroundColor: "#F9FAFB" }
              : rowItem.value === "Role"
              ? {
                  display: "flex",
                  justifyContent: "start",
                  backgroundColor: "#F9FAFB",
                }
              : rowItem.value === "Phone"
              ? {
                  display: "flex",
                  justifyContent: "start",
                  backgroundColor: "#F9FAFB",
                }
              : {
                  display: "flex",
                  justifyContent: "start",
                  backgroundColor: "#F9FAFB",
                }
          }
        >
          {rowItem.value}
          {rowItem.sort && (
            <ActionIcon
              style={{
                marginBottom: "2px",
              }}
            >
              <SortIconSVG />
            </ActionIcon>
          )}
        </div>
      ))}
    </div>
  );
};

export default ItemsHeaderRow;
