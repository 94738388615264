import { MantineProvider } from "@mantine/styles";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "styles/main.scss";
import { IntervalProvider } from "context/tokenInterval";
import {
  CoinsProvider,
  NotificationProvider,
  FiltersProvider,
  SortByProvider,
} from "context";

import { Provider } from "react-redux";
import { store } from "./store";
import { worker } from "./mocks";
import { AdminTypeProvider } from "context/adminType";
import { AppProvider } from "./context/userContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <MantineProvider
      theme={{
        fontFamily: "Mulish",
        colors: {
          primary: [
            "#F0792E",
            "#F0792E1F",
            "#F0792E",
            "#F0792E",
            "#F0792E",
            "#F0792E",
            "#F0792E",
            "#F0792E",
            "#F0792E",
            "#F0792E",
          ],
        },
        primaryColor: "primary",
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <IntervalProvider>
        <NotificationProvider>
          <CoinsProvider>
            <FiltersProvider>
              <SortByProvider>
                <AdminTypeProvider>
                  <AppProvider>
                    <App />
                  </AppProvider>
                </AdminTypeProvider>
              </SortByProvider>
            </FiltersProvider>
          </CoinsProvider>
        </NotificationProvider>
      </IntervalProvider>
    </MantineProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

worker.start({
  onUnhandledRequest: "bypass",
});
