import { Timeline } from "@mantine/core";
import styles from "./TicketManegement.module.scss";
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOrdersSVG } from "assets/icons";
import { TimelineProps } from "types/ticket";
import { getDisplayDate, getDisplayTime } from "utils/helper";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const replyHistory = (timeline: any[]) => {
  return timeline.map((item) => {
    const personName = item.updated_by?.person?.name || "Unknown";
    const shortDescWithPersonName = `${item.short_desc} ${personName}`;
    return {
      ...item,
      short_desc: capitalizeFirstLetter(shortDescWithPersonName),
      timeline_action: item.complainant_action ?? item.respondent_action ?? "",
    };
  });
};

export default function ReplyHistory({ timeline }: TimelineProps) {
  const processedreply = replyHistory(timeline);

  return processedreply && processedreply.length > 0 ? (
    <div className="flex flex-col my-32">
      <div className="px-12 flex flex-col gap-20">
        <Timeline
          active={processedreply?.length}
          bulletSize={32}
          lineWidth={2}
          classNames={{ itemBullet: styles.bullet, item: styles.line }}
        >
          {processedreply.map(({ description, updatedAt }) => (
            <Timeline.Item
              key={description}
              bullet={<span className={styles["bullet-marker"]} />}
            >
              <div className="ml-13 txt-16">
                <span>
                  <p className="txtw-10 clr-black">{description}</p>
                </span>

                <p className="txtw-2 clr-grey">
                  {getDisplayDate(updatedAt)}, {getDisplayTime(updatedAt)}
                </p>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  ) : (
    <EmptyPage item=" Reply History Found" img={NoOrdersSVG} />
  );
}
