import axios from "axios"
import { getStorageItem } from "services/storage"
import { adminBaseUrl } from "services/url"

const coinsUrl = `${adminBaseUrl}/coins`

export const getCoinValue = async () => {
  const response = await axios({
    method: "GET",
    url: `${coinsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const patchCoinValue = async (coinValue: string) => {
  const response = await axios({
    method: "PATCH",
    url: `${coinsUrl}/update`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      coinValue,
    },
  })
  return await response.data
}
