import { Header } from "components/Common/Header"
import "./MyProfile.scss"
import { EditProfile } from "components/Profile"

const MyProfile = () => {
  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main flex-1">
        <section className="flex flex-col bg-white mx-80 brd-10 mt-30 mb-30 mx-80 p-20">
          <EditProfile />
        </section>
      </main>
    </div>
  )
}

export default MyProfile
