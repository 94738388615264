import { useEffect, useState } from "react"
import { Modal } from "components/Common/Modal"
import BasicDetails from "./BasicDetails"
import { useBrands } from "context"

const AddBrandModal = ({
  editBrandModal,
  setEditBrandModal,
}: {
  editBrandModal: any
  setEditBrandModal: (editBrand: any) => void
}) => {
  const [brandModalPg, setBrandModalPg] = useState(1)
  const { isAddBrandModalOpen, setIsAddBrandModalOpen } = useBrands()

  useEffect(() => {
    if (!isAddBrandModalOpen || !editBrandModal.isOpen) {
      setBrandModalPg(1)
    }
  }, [isAddBrandModalOpen, editBrandModal.isOpen])

  return (
    <Modal
      opened={isAddBrandModalOpen || editBrandModal.isOpen}
      onClose={() => {
        if (isAddBrandModalOpen) {
          setIsAddBrandModalOpen(false)
        } else {
          setEditBrandModal((editModal: any) => ({
            isOpen: false,
            brandId: "",
          }))
        }
      }}
      title={`${isAddBrandModalOpen ? "add a brand" : "edit a brand"}`}
    >
      <div className="pt-20 pb-20 pl-20 pr-20">
        <p className="txt-12 txtw-5 clr-grey mb-5">{brandModalPg}/1</p>
        {brandModalPg === 1 ? (
          <BasicDetails
            editBrandModal={editBrandModal}
            setIsAddBrandModalOpen={setIsAddBrandModalOpen}
            setEditBrandModal={setEditBrandModal}
          />
        ) : brandModalPg === 2 ? (
          <></>
        ) : brandModalPg === 3 ? (
          <></>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  )
}

export default AddBrandModal
