import { Modal } from "components/Common/Modal";
import { Button } from "components/Common/Button";
import { TextInput } from "@mantine/core";
import { ImageUpload } from "components/Common/ImageUpload";
import React, { useEffect, useRef } from "react";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { useAdminTypeContext } from "context/adminType";

type ImageType = {
  link: string;
  _id: string;
};

type FormValues = {
  subCategoryName: string;
  category: string;
  image: ImageType | null;
  visible: boolean;
  _id: string | null;
  code?: string | null;
};

// Validation schema for B2C and B2B
const B2CSchema = Yup.object().shape({
  subCategoryName: Yup.string().required("Sub Category name is required"), // Required validation
  image: Yup.object()
    .shape({
      link: Yup.string(),
      _id: Yup.string(),
    })
    .optional(),
  visible: Yup.boolean().optional(),
});

const B2BSchema = Yup.object().shape({
  subCategoryName: Yup.string().required("Sub Category name is required"), // Required validation
  image: Yup.object()
    .shape({
      link: Yup.string(),
      _id: Yup.string(),
    })
    .optional(),
  visible: Yup.boolean().optional(),
  code: Yup.string().required("Code is required"),
});

const SubCategoryEditModal = ({
  open,
  isAddModal,
  onSave,
  onUpdate,
  onClose,
  selectedSubCategory,
  categoryId,
}: {
  open: boolean;
  isAddModal: boolean;
  onSave: (data: any) => void;
  onUpdate: (data: any) => void;
  onClose: () => void;
  selectedSubCategory?: any;
  categoryId: string;
}) => {
  const { adminType } = useAdminTypeContext();

  // Form definition
  const form = useForm<FormValues>({
    initialValues: {
      subCategoryName: "",
      category: categoryId,
      image: null,
      visible: true,
      _id: null,
    },
    validate:
      adminType === "e-commerce-b2b"
        ? yupResolver(B2BSchema)
        : yupResolver(B2CSchema),
  });

  const prevSelectedSubCategoryRef = useRef<any>();

  // Update form values when selected subcategory changes
  useEffect(() => {
    if (
      selectedSubCategory &&
      selectedSubCategory !== prevSelectedSubCategoryRef.current
    ) {
      form.setValues({
        subCategoryName: selectedSubCategory.subCategoryName,
        category: selectedSubCategory.category,
        image: selectedSubCategory.image,
        visible: selectedSubCategory.visible,
        _id: selectedSubCategory._id,
        code: selectedSubCategory.code,
      });
      prevSelectedSubCategoryRef.current = selectedSubCategory;
    }
  }, [selectedSubCategory, form]);

  // Handle form submission
  const handleSubmit = form.onSubmit((values) => {
    const formData = {
      _id: values._id,
      subCategoryName: values.subCategoryName,
      category: categoryId,
      image: values.image?._id,
    };

    if (selectedSubCategory) {
      onUpdate(formData);
    } else {
      onSave(formData);
    }
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Modal
      opened={open}
      onClose={onModalClose}
      title={isAddModal ? "Add Sub Category" : "Edit Sub Category"}
    >
      <form className="pt-20 pl-20 pr-20 w-500" onSubmit={handleSubmit}>
        <div className="flex justify-center align-center flex-col">
          <ImageUpload
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={form.values?.image?.link}
            setMedia={(img) => form.setFieldValue("image", img[0])}
            mediaValidationText={(form?.errors?.image as string) || undefined}
            setMediaValidationText={(err) => form.setFieldError("image", err)}
            mediaSize={2000000}
          />
        </div>

        {adminType === "e-commerce-b2b" && (
          <TextInput
            label="CODE"
            classNames={{
              wrapper: "clr-grey txt-12 txtw-4 pb-10",
              label: "clr-grey txt-12 txtw-4",
            }}
            className="cursor-default"
            variant="filled"
            {...form.getInputProps("code")}
          />
        )}

        <TextInput
          label="SUBCATEGORY NAME"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          variant="filled"
          {...form.getInputProps("subCategoryName")}
        />

        <div className="flex justify-end  mt-20 pt-10">
          <Button type="filled-primary" text="Save" onClick={() => {}} />
        </div>
      </form>
    </Modal>
  );
};

export default SubCategoryEditModal;
