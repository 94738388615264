const EmptyPage = ({ img, item }: { img: string; item: string }) => {
    return (
        <section className="no-outlets-div flex justify-center align-center flex-col">
            <div>
                <img src={img} alt="no outlets yet" />
            </div>
            <p className="txt-18 txtw-4">No {item} to show yet</p>
        </section>
    )
}

export default EmptyPage
