import axios from "axios"
import { getStorageItem } from "services/storage"
import { searchBaseUrl } from "services/url"

const cuisinesUrl = `${searchBaseUrl}/cuisine`

export const getAllCuisines = async () => {
  const response = await axios({
    method: "GET",
    url: `${cuisinesUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getCuisine = async (cuisineId: string) => {
  const response = await axios({
    method: "GET",
    url: `${cuisinesUrl}?cuisineId=${cuisineId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const postCuisine = async (cuisineData: any) => {
  const formData = new FormData()
  for (const key in cuisineData) {
    formData.append(key, cuisineData[key])
  }
  const response = await axios({
    method: "POST",
    url: `${cuisinesUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  })
  return await response.data
}

export const putCuisine = async (cuisineData: any) => {
  const formData = new FormData()
  for (const key in cuisineData) {
    formData.append(key, cuisineData[key])
  }
  const response = await axios({
    method: "PUT",
    url: `${cuisinesUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  })
  return await response.data
}
