import { BrandProvider, BrandsProvider } from "context";
import { PackagingCharges } from "pages/Charges";
import DeliveryCharges from "pages/Charges/Delivery/Delivery";
import BuyerAppInfo from "pages/BuyerAppInfo/BuyerAppInfo";

import {
  Brands,
  Brand,
  Offers,
  Banners,
  AccessManagement,
  CategoryManagement,
  TicketManagement,
  Settlements,
  // CampaignManagement,
  Customer,
} from "../pages";
import MyProfile from "pages/MyProfile/MyProfile";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import KeyManagement from "pages/KeyManagement/KeyManagement";
import CustomerManagement from "pages/CustomerManagement";
import OrderManagement from "pages/OrderManagement";
import Dashboard from "pages/Dashboard/Dashboard";
import OrderDetails from "pages/OrderDetails/OrderDetails";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import Complaints from "components/TicketManagement/Complaints";
import ComplaintDetails from "components/TicketManagement/ComplaintDetails";
import Pages from "pages/pages/pages";
import Sections from "pages/Sections/sections";

export const privateRoutes = [
  {
    path: "/hub",
    component: Dashboard,
  },
  {
    path: "/admins",
    component: AccessManagement,
    // context: BrandsProvider,
  },
  {
    path: "/customers",
    component: CustomerManagement,
  },
  {
    path: "/customers/:id",
    component: Customer,
  },
  // {
  //   path: "/customers/:id/:orderId",
  //   component: CustomerOrderDetails,
  // },
  {
    path: "/category",
    component: CategoryManagement,
  },
  {
    path: "/orders",
    component: OrderManagement,
  },
  {
    path: "/rides",
    component: OrderManagement,
  },
  // {
  //   path: "/campaign-management",
  //   component: CampaignManagement,
  // },
  {
    path: "/tickets-management",
    component: TicketManagement,
  },
  {
    path: "/tickets-management/:status",
    component: Complaints,
  },
  {
    path: "/tickets-management/:status/:id",
    component: ComplaintDetails,
  },
  {
    path: "/settlements",
    component: Settlements,
  },
  {
    path: "/offers",
    component: Offers,
  },
  {
    path: "/banners",
    component: Banners,
  },
  {
    path: "/pages",
    component: Pages, // TODO
  },
  {
    path: "/section",
    component: Sections, // TODO
  },
  {
    path: "/brands",
    component: Brands,
    context: BrandsProvider,
  },
  {
    path: "/brands/:brandId",
    component: Brand,
    context: BrandProvider,
  },
  {
    path: "/delivery-charges",
    component: DeliveryCharges,
    // context: BrandsProvider,
  },
  {
    path: "/packaging-charges",
    component: PackagingCharges,
    // context: BrandsProvider,
  },
  {
    path: "/profile",
    component: MyProfile,
    // context: BrandsProvider,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    // context: BrandsProvider,
  },
  {
    path: "/bank-details",
    component: KeyManagement,
    // context: BrandsProvider,
  },
  {
    path: "/buyer-app-info",
    component: BuyerAppInfo,
  },
  {
    path: "/orders/:id",
    component: OrderDetails,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
];

export const NAVTABS_LIST = [
  {
    name: "Dashboard",
    value: "hub",
    imgAlt: "hub",
  },
  {
    name: "Management",
    value: "management",
    pageSize: 6,
    imgAlt: "Management",
  },
  {
    name: "Settlements",
    value: "settlements",
    pageSize: 6,
    imgAlt: "Settlements",
  },
  {
    name: "Category",
    value: "category",
    pageSize: 10,
    imgAlt: "orders",
  },
  {
    name: "Orders",
    value: "orders",
    pageSize: 10,
    imgAlt: "orders",
  },
  {
    name: "Rides",
    value: "rides",
    pageSize: 10,
    imgAlt: "rides",
  },
  {
    name: "Customers",
    value: "customers",
    pageSize: 10,
    imgAlt: "customers",
  },
  {
    name: "Admin",
    value: "admins",
    pageSize: 10,
    imgAlt: "customers",
  },
  {
    name: "Offers",
    value: "offers",
    imgAlt: "offers",
  },
  {
    name: "Banners",
    value: "banners",
    imgAlt: "banners",
  },
  {
    name: "Pages",
    value: "Pages",
    imgAlt: "Pages",
  },
  {
    name: "Section",
    value: "Section",
    imgAlt: "Section",
  },
];

export const MODES_OF_PAYMENTS = [
  "Cash on Delivery",
  "Protean Wallet",
  "UPI",
  "Netbanking",
  "Other Wallets",
];

export const ISSUE_STATUS = ["OPEN", "CLOSED"];
export const ORDER_MANAGEMENT_STATUS = [
  "Created",
  "Accepted",
  "In-progress",
  "Completed",
  "Cancelled",
];
export const SORT_BY = ["asc", "desc"];

export const DELIVERY_MODES = ["Home Delivery", "Takeaway"];

export const ALL_CUISINES = [
  "North Indian",
  "Chinese",
  "Punjabi",
  "Italian",
  "South Indian",
];

export const INITIAL_ORDERS_FILTERS = {
  brands: [],

  status: [],
};
export const INITIAL_SORT_BY = {
  sortByTitle: [],
};

export const ORDER_STATUS = {
  CREATED: "CREATED",
  PAYMENT_FAILED: "PAYMENT FAILED",
  PAYMENT_SUCCESS: "PAYMENT SUCCESS",
  CANCELLED_BY_KITCHEN: "CANCELLED BY KITCHEN",
  FOOD_PREPARATION: "PREPAIRING",
  FOOD_READY: "FOOD READY",
  OUTLET_ASSIGNED_FOR_DELIVERY: "OUTLET ASSIGNED",
  RIDER_ASSIGNED: "RIDER ASSIGNED",
  RIDER_REACHED_SOURCE: "RIDER AT OUTLET",
  DISPATCH: "FOOD DISPATCHED",
  RIDER_REACHED_DESTINATION: "RIDER AT DESTINATION",
  DELIVERED: "DELIVERED",
  CANCELLED_BY_RIDER: "CANCELLED BY RIDER",
  CANCELLED_BY_CUSTOMER: "CANCELLED BY CUSTOMER",
  UNDELIVERED: "NOT DELIVERED",
};
