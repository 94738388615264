import axios from "axios"
import { getStorageItem } from "services/storage"
import { searchBaseUrl } from "services/url"

const itemsUrl = `${searchBaseUrl}/item`

export const getBrandItems = async (brandId: string) => {
  const response = await axios({
    method: "GET",
    url: `${itemsUrl}?brandId=kn896yqf1`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getAllItems = async (query: string) => {
  const response = await axios({
    method: "GET",
    url: `${itemsUrl}?text=${query}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getAllItemsFromCuisine = async (query: string) => {
  const response = await axios({
    method: "GET",
    url: `${itemsUrl}?cuisineId=${query}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getItem = async (itemId: string) => {
  const response = await axios({
    method: "GET",
    url: `${itemsUrl}/${itemId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}
