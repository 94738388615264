import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Dialog } from "@mantine/core";
import ProtectedRoute from "components/Common/ProtectedRoute";
import CoinsModal from "components/CoinsModal";
import { privateRoutes } from "constants/index";
import { Login } from "../pages";
import { useNotification } from "context";
import { AppContext } from "context/userContext";
import { useContext } from "react";
import Spinner from "services/spinner/Spinner";

const AppRouter = () => {
  const { text, setText } = useNotification();
  const { spinner } = useContext(AppContext);
  return (
    <>
      {spinner && <Spinner />}
      <Dialog
        position={{ top: 20, right: 20 }}
        withCloseButton
        onClose={() => setText("")}
        opened={text.length > 0}
      >
        {text}
      </Dialog>
      <BrowserRouter>
        <CoinsModal />
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />
          {privateRoutes?.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute>
                  {route?.context ? (
                    <route.context>
                      <route.component />
                    </route.context>
                  ) : (
                    <route.component />
                  )}
                </ProtectedRoute>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
