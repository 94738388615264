import { Modal as MantineModal } from "@mantine/core"
import "./Modal.scss"

interface propTypes {
    opened: boolean
    onClose: () => void
    title: string
    classes?: Object
    children: React.ReactNode
    size?: any
}

const Modal = ({ opened, onClose, title, classes, children,size }: propTypes) => {
    return (
        <MantineModal
            classNames={{
                title: "modal-title",
                header: "modal-header",
                ...classes,
            }}
            centered
            opened={opened}
            onClose={onClose}
            title={title}
            radius={10}
            size={size?size:"fit-content"}
            closeOnClickOutside={false}
        >
            {children}
        </MantineModal>
    )
}

export default Modal
