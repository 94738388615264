import { createContext, useContext, useState } from "react"
import { brandType } from "types/brand"

const BrandsContext = createContext<any>({})

export const BrandsProvider = ({ children }: { children: any }) => {
    const [brandsList, setBrandsList] = useState<Array<brandType>>([])
    const [brandsPagination, setBrandsPagination] = useState<{
        page: number
        size: string
        totalEntries: number
    }>({
        page: 1,
        size: "10",
        totalEntries: 0,
    })
    const [isAddBrandModalOpen, setIsAddBrandModalOpen] =
        useState<boolean>(false)
    const [editBrandModal, setEditBrandModal] = useState<{
        isOpen: boolean
        brandId: string
    }>({
        isOpen: false,
        brandId: "",
    })
    const [deleteBrandModal, setDeleteBrandModal] = useState<{
        brandId: string
        isOpen: boolean
    }>({
        isOpen: false,
        brandId: "",
    })

    return (
        <BrandsContext.Provider
            value={{
                brandsList,
                setBrandsList,
                brandsPagination,
                setBrandsPagination,
                isAddBrandModalOpen,
                setIsAddBrandModalOpen,
                editBrandModal,
                setEditBrandModal,
                deleteBrandModal,
                setDeleteBrandModal,
            }}
        >
            {children}
        </BrandsContext.Provider>
    )
}

export const useBrands = () => useContext(BrandsContext)
