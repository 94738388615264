import { createContext, useContext, useEffect, useState } from "react"
import { getStorageItem, setStorageItem } from "services/storage"
import { AdminTypeTypes } from "types/admin"
const AdminTypeContext = createContext<any>({})

export const AdminTypeProvider = ({ children }: { children: any }) => {
  const initialAdminType = getStorageItem("adminType") || "e-commerce-b2c"

  useEffect(() => {
    setStorageItem("adminType", initialAdminType)
  }, [initialAdminType])

  const [adminType, setAdminType] = useState<AdminTypeTypes>(initialAdminType)

  const storeAdminType = (currentAdminType: AdminTypeTypes) => {
    setAdminType(currentAdminType)
    setStorageItem("adminType", currentAdminType)
    window.location.reload()
  }

  return (
    <AdminTypeContext.Provider
      value={{
        adminType,
        storeAdminType,
      }}
    >
      {children}
    </AdminTypeContext.Provider>
  )
}

export const useAdminTypeContext = () => useContext(AdminTypeContext)
