import "react-phone-input-2/lib/style.css"
import { useAdminTypeContext } from "context/adminType"
import MobilityCustomers from "components/Customers/MobilityCustomers"
import ECommerceCustomers from "components/Customers/EcommerceCustomers"

const Customer = () => {
  const { adminType } = useAdminTypeContext()

  return adminType === "mobility" ? (
    <MobilityCustomers />
  ) : (
    <ECommerceCustomers />
  )
}

export default Customer
