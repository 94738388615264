import { useEffect, useRef, useState } from "react";
import "./OTPInput.scss";

const OTPInput = ({
  setOtp,
  handleEmailSubmit,
}: {
  setOtp: Function;
  handleEmailSubmit: Function;
}) => {
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const firstRef = useRef<HTMLInputElement | null>();
  const secondRef = useRef<HTMLInputElement | null>();
  const thirdRef = useRef<HTMLInputElement | null>();
  const fourthRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    setOtp(`${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}`);
  }, [firstDigit, secondDigit, thirdDigit, fourthDigit, setOtp]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <input
        ref={(e) => (firstRef.current = e)}
        type="number"
        value={firstDigit}
        onChange={(e) => {
          setFirstDigit(e.target.value[e.target.value.length - 1]);
          if (e.target.value !== "") {
            secondRef?.current?.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "ArrowRight") {
            secondRef?.current?.focus();
          }
        }}
        className="otp-input flex txt-14 brd-10"
      />
      <input
        ref={(e) => (secondRef.current = e)}
        type="number"
        value={secondDigit}
        onChange={(e) => {
          setSecondDigit(e.target.value[e.target.value.length - 1]);
          if (e.target.value === "") {
            firstRef?.current?.focus();
          } else {
            thirdRef?.current?.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            firstRef?.current?.focus();
          } else if (e.key === "ArrowRight") {
            thirdRef?.current?.focus();
          }
        }}
        className="otp-input flex txt-14 brd-10"
      />
      <input
        ref={(e) => (thirdRef.current = e)}
        type="number"
        value={thirdDigit}
        onChange={(e) => {
          setThirdDigit(e.target.value[e.target.value.length - 1]);
          if (e.target.value === "") {
            secondRef?.current?.focus();
          } else {
            fourthRef?.current?.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            secondRef?.current?.focus();
          } else if (e.key === "ArrowRight") {
            fourthRef?.current?.focus();
          }
        }}
        className="otp-input flex txt-14 brd-10"
      />
      <input
        ref={(e) => (fourthRef.current = e)}
        type="number"
        value={fourthDigit}
        onChange={(e) => {
          setFourthDigit(e.target.value[e.target.value.length - 1]);
          if (e.target.value === "") {
            thirdRef?.current?.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            thirdRef?.current?.focus();
          } else if (e.key === "Enter") {
            handleEmailSubmit();
          }
        }}
        className="otp-input flex txt-14 brd-10"
      />
    </div>
  );
};

export default OTPInput;
