import axios from "axios"
// import { postCategory } from "types/category"
import {  userAppBaseURL } from "services/url"
import { getStorageItem } from "services/storage"
const adminType = getStorageItem("adminType")
export const getCategories = async (page: number = 1, size: string = "10") => {
  const searchParams = new URLSearchParams()
  searchParams.set("page", String(page))
  searchParams.set("size", String(size))
  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true))
  }
  const response = await axios({
    method: "GET",
    url: userAppBaseURL + "/category",
    params: searchParams,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}


// export const editCategory = async (categoryId: string, categoryData: any) => {
//   const response = await axios({
//     method: "PATCH",
//     url: `${userAppBaseURL}/category`,
//     headers: {
//       Authorization: `${getStorageItem("token")}`,
//     },
//     data: {
//       query: {
//         _id: categoryId,
//       },
//       payload: {
//         ...categoryData,
//       },
//     },
//   })
//   return await response.data
// }

export const deleteCategory = async (categoryId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${userAppBaseURL}/category/${categoryId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response
}
