import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, NumberInput, TextInput, SegmentedControl } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
import { Modal } from "components/Common/Modal";
import { useNotification } from "context";
import { postDelivery, putDelivery } from "services/apis/charges";
import { checkAuth } from "utils/helper";

const AddDeliveryCharge = ({
  isAddDeliveryModalVisible,
  setIsAddDeliveryModalVisible,
  editDeliveryModal,
  setEditDeliveryModal,
}: {
  isAddDeliveryModalVisible: boolean;
  setIsAddDeliveryModalVisible: Function;
  editDeliveryModal: any;
  setEditDeliveryModal: Function;
}) => {
  const [deliveryApiInProgress, setDeliveryApiInProgress] = useState(false);
  const basicDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      title: "",
      basePrice: "",
      minOrderVal: "",
      chargeType: "flat",
    },
    validate: {
      title: (value) => (value.length === 0 ? "Title is required" : null),
      basePrice: (value) =>
        value?.length === 0 ? "Base price is required" : null,
      minOrderVal: (value) =>
        value?.length === 0 ? "Order value is required" : null,
    },
  });
  const { setText } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (editDeliveryModal.isOpen) {
        basicDetailsForm.setValues({
          title: editDeliveryModal.delivery.label,
          basePrice: editDeliveryModal.delivery.useFlatCharge
            ? editDeliveryModal.delivery.flatCharge
            : editDeliveryModal.delivery.basePrice,
          minOrderVal: editDeliveryModal.delivery.freeOnOrderAmountAbove,
          chargeType: editDeliveryModal.delivery.useFlatCharge
            ? "flat"
            : "dynamic",
        });
      }
    })();
  }, [basicDetailsForm, editDeliveryModal]);

  async function handleAddDelivery() {
    if (isAddDeliveryModalVisible) {
      checkAuth(
        async () => {
          const response = await postDelivery({
            label: basicDetailsForm.values.title,
            ...(basicDetailsForm.values.chargeType === "dynamic"
              ? {
                  basePrice: String(basicDetailsForm.values.basePrice),
                  useFlatCharge: false,
                }
              : {
                  flatCharge: String(basicDetailsForm.values.basePrice),
                  useFlatCharge: true,
                }),
            freeOnOrderAmountAbove: String(basicDetailsForm.values.minOrderVal),
          });
          if (response.success) {
            setIsAddDeliveryModalVisible(false);
            basicDetailsForm.setValues({
              title: "",
              basePrice: "",
              minOrderVal: "",
            });
          }
        },
        setText,
        navigate,
        setDeliveryApiInProgress
      );
    } else {
      checkAuth(
        async () => {
          const response = await putDelivery(editDeliveryModal.delivery._id, {
            label: basicDetailsForm.values.title,
            ...(basicDetailsForm.values.chargeType === "dynamic"
              ? {
                  basePrice: String(basicDetailsForm.values.basePrice),
                  useFlatCharge: false,
                }
              : {
                  flatCharge: String(basicDetailsForm.values.basePrice),
                  useFlatCharge: true,
                }),
            freeOnOrderAmountAbove: String(basicDetailsForm.values.minOrderVal),
          });
          if (response.success) {
            setEditDeliveryModal({
              isOpen: false,
              delivery: {},
            });
            basicDetailsForm.setValues({
              title: "",
              basePrice: "",
              minOrderVal: "",
              chargeType: "flat",
            });
          }
        },
        setText,
        navigate,
        setDeliveryApiInProgress
      );
    }
  }

  function handleFormSubmit() {
    handleAddDelivery();
  }

  function handleFormError() {}

  return (
    <Modal
      title={`${
        isAddDeliveryModalVisible ? "Add" : "Edit"
      } the Delivery charge`}
      opened={isAddDeliveryModalVisible || editDeliveryModal.isOpen}
      onClose={() => {
        if (isAddDeliveryModalVisible) {
          setIsAddDeliveryModalVisible(false);
        } else {
          setEditDeliveryModal({
            isOpen: false,
            delivery: {},
          });
        }
        basicDetailsForm.setValues({
          title: "",
          basePrice: "",
          minOrderVal: "",
          chargeType: "flat",
        });
      }}
    >
      <form
        onSubmit={basicDetailsForm.onSubmit(handleFormSubmit, handleFormError)}
        className="w-500 pl-20 pr-20"
      >
        <div className="grid grid-cols-2 col-gap-40 row-gap-25">
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="TITLE"
            required
          >
            <TextInput
              placeholder="Charge title"
              variant="filled"
              {...basicDetailsForm.getInputProps("title")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            classNames={{
              root: "flex flex-col",
              label: "clr-grey txt-12 txtw-4",
            }}
            label="TYPE"
            required
          >
            <SegmentedControl
              data={[
                { label: "Flat", value: "flat" },
                { label: "Dynamic", value: "dynamic" },
              ]}
              {...basicDetailsForm.getInputProps("chargeType")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            classNames={{
              root: "gcol-start-1 gcol-end-2",
              label: "clr-grey txt-12 txtw-4",
            }}
            label={
              basicDetailsForm.values.chargeType === "flat"
                ? "FLAT PRICE"
                : "BASE PRICE"
            }
            required
          >
            <NumberInput
              placeholder="₹"
              variant="filled"
              {...basicDetailsForm.getInputProps("basePrice")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="FREE ON ORDERS ABOVE"
            required
          >
            <NumberInput
              placeholder="₹"
              variant="filled"
              {...basicDetailsForm.getInputProps("minOrderVal")}
            />
          </Input.Wrapper>
        </div>
        <div className="flex justify-end bd-top mt-10 pt-10">
          <Button
            type="filled-primary"
            text={`${isAddDeliveryModalVisible ? "Add" : "Save"}`}
            loading={deliveryApiInProgress}
            onClick={() => {}}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddDeliveryCharge;
