import { getStorageItem } from "./storage"

const mobilityRides =
  "https://mobility-buyer-api-dev.ondc-dev.grappus.com/api/v1"

export const adminBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/admin/v1`
export const searchBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/search/v1`
export const userAppBaseURL = `${
  getStorageItem("adminType") === "e-commerce-b2b"
    ? process.env.REACT_APP_API_BASE_URL
    : process.env.REACT_APP_API_BASE_URL
}`

export const mobilityRidesBaseURL = process.env
  .REACT_APP_MOBILITY_RIDES_BASE_URL
  ? String(process.env.REACT_APP_MOBILITY_RIDES_BASE_URL)
  : mobilityRides
// urls

// export const devAPIBaseURL =
//   process.env.REACT_APP_API_BASE_URL || "http://65.1.64.15/api/v1"
