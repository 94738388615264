import React from "react";
import { Modal } from "components/Common/Modal";
import { Accordion, Divider } from "@mantine/core";
import "./SettlementDetailModal.scss";

interface Item {
  id: string;
  name: string;
  quantity: {
    count: number;
  };
  price: {
    value: number;
  };
}

interface Breakup {
  title: string;
  price: {
    value: number;
  };
  "@ondc/org/title_type": string;
  "@ondc/org/item_quantity"?: {
    count: number;
  };
}

const SettlementDetailModal = ({
  editModalVisible,
  data,
  onClose,
}: {
  data: any;
  editModalVisible: any;
  onClose: any;
}) => {
  const onCloseModal = () => {
    onClose();
  };

  const totalItemPrice = (data?.breakup ?? [])

    .filter((breakup: Breakup) => breakup["@ondc/org/title_type"] === "item")

    .reduce((total: number, breakup: Breakup) => {
      return (
        total +
        breakup.price.value * (breakup["@ondc/org/item_quantity"]?.count ?? 1)
      );
    }, 0);

  return (
    <Modal opened={editModalVisible} onClose={onCloseModal} title={"Overview"}>
      <div className="modal-content">
        <h3>Product Details</h3>
        <Accordion>
          {(data?.items ?? []).map((item: Item, index: number) => (
            <Accordion.Item key={index} value={`item-${index}`}>
              <Accordion.Control>{item.name}</Accordion.Control>
              <Accordion.Panel>
                <div className="product-details">
                  <div>
                    <strong>Item ID:</strong> {item?.id}
                  </div>
                  <div>
                    <strong>Item Name:</strong> {item?.name}
                  </div>
                  <div>
                    <strong>Quantity:</strong> {item?.quantity?.count}
                  </div>
                  <div>
                    <strong>Price:</strong> {item?.price?.value}
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />

        <h3>Order Details</h3>
        <div>
          <strong>Bapp ID:</strong> {data?.orderDetails?.bppId}
        </div>
        <div>
          <strong>Name:</strong> ₹ {data?.orderDetails?.customer?.email}
        </div>
        <div>
          <strong>Email:</strong> {data?.orderDetails?.customer?.email}
        </div>
        <div>
          <strong>Phone:</strong> {data?.orderDetails?.customer?.phone}
        </div>
        <Accordion>
          {/* {(data?.items ?? []).map((item: Item, index: number) => ( */}
          <Accordion.Item value={"sd"}>
            <Accordion.Control>{"More Details"}</Accordion.Control>
            <Accordion.Panel>
              <div className="product-details">
                <div>
                  <strong>CreatedAt:</strong>
                  {data?.orderDetails?.payment?.createdAt}
                </div>
                <div>
                  <strong>Payment ID:</strong>
                  {data?.orderDetails?.payment?.id}
                </div>
                <div>
                  <strong>Method:</strong> {data?.orderDetails?.payment?.method}
                </div>
                {/* <div>
                  <strong>Price:</strong> {item?.price?.value}
                </div> */}
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />

        <div className="breakup-details">
          <h3>Total Order Breakup</h3>
          <div>
            <strong>Base Price :</strong> ₹{totalItemPrice.toFixed(2)}
          </div>
          {(data?.breakup ?? [])
            .filter(
              (breakup: Breakup) => breakup["@ondc/org/title_type"] !== "item"
            )
            .map((breakup: Breakup, index: number) => (
              <div key={index}>
                <div>
                  <strong>{breakup.title}:</strong> ₹ {breakup.price.value}
                </div>
              </div>
            ))}
        </div>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />

        <div className="settlement-details">
          <h3>Settlement Details</h3>
          <div>
            <strong>Settlement ID:</strong> {data?.settlement_id}
          </div>
          <div>
            <strong>Total Amount:</strong> ₹ {data?.total_amount}
          </div>
          <div>
            <strong>Buyer Commission:</strong> ₹ {data?.buyer_commission}
          </div>
          <div>
            <strong>Settlement Amount:</strong> ₹ {data?.settlement_amount}
          </div>
          <div>
            <strong>Order Status:</strong> {data?.order_status}
          </div>
          <div>
            <strong>Timestamp:</strong>{" "}
            {new Date(data?.timestamp).toLocaleString()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SettlementDetailModal;
