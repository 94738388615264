import CustomTable from "components/CustomTable/CustomTable";
import Listing from "components/Dashboard/Listing";
import { Link } from "react-router-dom";
import { formatCurrencyValue } from "utils/helper";
import styles from "./Dashboard.module.scss";
import { createColumnHelper } from "@tanstack/react-table";
import BarChartComponent from "components/Dashboard/Charts/BarChart";
import LineChartComponent from "components/Dashboard/Charts/LineChart";
import { useGetAdminDashboardDataQuery } from "hooks/Admin/adminDashboardAPI";
import { useEffect, useState } from "react";

export default function ECommerceDashboard() {
  const { data: dashboardDetails } = useGetAdminDashboardDataQuery();

  const {
    bestSellingProducts,
    bestSellingStores,
    orderByLocation,
    customers,
    orders,
    revenue,
    revenueAndOrdersByMonth,
    categoryWiseSale,
    topSearches,
  } = dashboardDetails?.data ?? {};

  let overviewData = [
    { title: "Total Orders", value: orders || 0, link: "/orders" },
    { title: "Total Customers", value: customers || 0, link: "/customers" },
    { title: "Total Revenue", value: revenue || 0, link: "" },
  ];

  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (categoryWiseSale) {
      const categories = Object.keys(categoryWiseSale);
      setSelectedCategory(categories[0]);
      setCategoryData(categoryWiseSale[categories[0]]);
    }
  }, [categoryWiseSale]);

  const handleCategoryChange = (event: { target: { value: any } }) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setCategoryData(categoryWiseSale[category]);
  };

  const columnHelper = createColumnHelper();

  const topkeywordsColumns = [
    // columnHelper.accessor("sno", {
    //   header: "S.No",
    //   cell: (info: any) => info.getValue(),
    // }),
    columnHelper.accessor("searchTerm", {
      header: "Keyword",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("searchCount", {
      header: "No. of Searches",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),
  ];

  const leftSideTableDetails = [
    { title: "Best Selling Products", data: bestSellingProducts },
    { title: "Top Stores", data: bestSellingStores },
  ];

  return (
    <div className="flex flex-col justify-stretch my-30 mx-80">
      <p className="txtw-7 pb-10 txt-18">Quick Overview</p>
      <div className="flex w-full flex-wrap justify-stretch gap-20">
        {overviewData?.map((item, idx) => (
          <div
            className="flex flex-1 bd-full brd-8 bg-white px-24 py-20"
            key={`overview-card-${idx}`}
          >
            <div className="flex flex-col" key={item?.title}>
              <span className="txt-14 clr-grey txtw-5">{item?.title}</span>
              <span className="txtw-8 txt-36 mt-8 mb-16">
                {typeof item?.value === "string" &&
                  item?.value.includes("₹") &&
                  "₹"}
                {formatCurrencyValue(item?.value)}
              </span>
              {item?.link && (
                <Link
                  to={item?.link}
                  className="txt-14 txtw-6 clr-primary no-underline"
                >
                  View Details
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-30">
        <p className="txtw-7 pb-10 txt-18">Total Revenue & Orders</p>
        <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
          <LineChartComponent
            data={revenueAndOrdersByMonth}
            dataKeys={[
              {
                key: "totalOrders",
                fill: "#667085",
                legend: "Order",
              },
              {
                key: "totalRevenue",
                fill: "#f0792e",
                legend: "Revenue",
              },
            ]}
            yaxis={{
              ticks: [0, 1000, 2000, 3000, 4000, 5000],
            }}
          />
        </div>
      </div>
      <div className="mt-30">
        <div className="w-full flex justify-between items-center mb-10">
          <p className="txtw-7 pb-10 txt-18">
            Sales by Categories -
            <select
              id="categoryDropdown"
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="form-control"
            >
              {categoryWiseSale &&
                Object.keys(categoryWiseSale).map((category, idx) => (
                  <option key={idx} value={category}>
                    {category}
                  </option>
                ))}
            </select>
          </p>
        </div>
        <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
          <BarChartComponent
            data={categoryData}
            dataKeys={[
              {
                key: "totalOrders",
                fill: "#f0792e",
                stackId: "a",
                radius: 0,
              },
              {
                key: "totalRevenue",
                fill: "#d0d5dd",
                stackId: "a",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex justify-stretch">
        <div className="flex flex-col flex-1 mr-30">
          {leftSideTableDetails?.map((table, idx) => (
            <div className="mt-30" key={`listing-table-${idx}`}>
              <div className="flex flex-col justify-stretch bd-full brd-8 bg-white px-20">
                <p className="txtw-7 pb-10 txt-18 pt-20">{table.title}</p>
                <Listing data={table.data} />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-30 flex-1">
          <div className="h-full flex flex-col bd-full brd-8 bg-white px-20">
            <div className="flex justify-stretch align-center pt-20">
              <p className="txtw-7 pb-10 txt-18">Top Keywords</p>
              <Link
                to="/keywords"
                className="pb-10 txt-14 txtw-6 clr-primary no-underline"
              >
                View All
              </Link>
            </div>
            {topSearches && (
              <CustomTable
                data={topSearches}
                columns={topkeywordsColumns}
                className={styles.table}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-30">
        <p className="txtw-7 pb-10 txt-18">Sales & Orders by Location</p>
        <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
          {orderByLocation ? (
            <BarChartComponent
              data={orderByLocation?.slice(0, 10)}
              dataKeys={[
                {
                  key: "totalOrders",
                  fill: "#f0792e",
                },
              ]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
