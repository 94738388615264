import { createContext, useContext, useState } from "react";

const SortByContext = createContext<any>({});

export const SortByProvider = ({ children }: { children: any }) => {
  const [isSortByModalVisible, setIsSortByModalVisible] =
    useState<boolean>(false);

  return (
    <SortByContext.Provider
      value={{
        isSortByModalVisible,
        setIsSortByModalVisible,
      }}
    >
      {children}
    </SortByContext.Provider>
  );
};

export const useSortBy = () => useContext(SortByContext);
