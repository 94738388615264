export { default as ClockGreenSVG } from "./ClockGreen.svg"
export { default as CloseSVG } from "./Close.svg"
export { default as CrossSVG } from "./Cross.svg"
export { default as ShoppingCartSVG } from "./ShoppingCart.svg"
export { default as ShoppingCartGraySVG } from "./ShoppingCartGray.svg"
export { default as StorefrontSVG } from "./Storefront.svg"
export { default as StorefrontGraySVG } from "./StorefrontGray.svg"
export { default as UserCircleSVG } from "./UserCircle.svg"
export { default as UserCircleGraySVG } from "./UserCircleGray.svg"
export { default as ShieldStarSVG } from "./ShieldStar.svg"
export { default as ShieldStarGraySVG } from "./ShieldStarGray.svg"
export { default as TagSimpleSVG } from "./TagSimple.svg"
export { default as TagSimpleGraySVG } from "./TagSimpleGray.svg"
export { default as TickSVG } from "./Tick.svg"
export { default as TickWhiteSVG } from "./TickWhite.svg"
export { default as LoadingSVG } from "./Loading.svg"
export { default as NoOrdersSVG } from "./NoOrders.svg"
export { default as NoOffersSVG } from "./NoOffers.svg"
export { default as CheckCircleSVG } from "./CheckCircle.jpg"
export { default as GoogleLogoSVG } from "./GoogleLogo.svg"
export { default as ProteanLoginLogoSVG } from "./ProteanLoginLogo.svg"
export { default as ProteanHeaderLogo } from "./ProteanHeaderLogo.svg"
export { default as TotalIssuesSVG } from "./users-check.svg"
export { default as OpenIssuesSVG } from "./open-ticket.svg"
export { default as ClosedIssuesSVG } from "./closed-ticket.svg"
export { default as HomeSVG } from "./home-line.svg"

export const ViewStatusSVG = ({ color = "#667085" }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PencilIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19H19M1.00003 19H2.67457C3.16376 19 3.40835 19 3.63852 18.9447C3.84259 18.8957 4.03768 18.8149 4.21663 18.7053C4.41846 18.5816 4.59141 18.4086 4.93732 18.0627L17.5001 5.49998C18.3285 4.67156 18.3285 3.32841 17.5001 2.49998C16.6716 1.67156 15.3285 1.67156 14.5001 2.49998L1.93729 15.0627C1.59139 15.4086 1.41843 15.5816 1.29475 15.7834C1.18509 15.9624 1.10428 16.1574 1.05529 16.3615C1.00003 16.5917 1.00003 16.8363 1.00003 17.3255V19Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TrashboxIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1H13M1 4H19M17 4L16.2987 14.5193C16.1935 16.0975 16.1409 16.8867 15.8 17.485C15.4999 18.0118 15.0472 18.4353 14.5017 18.6997C13.882 19 13.0911 19 11.5093 19H8.49065C6.90891 19 6.11803 19 5.49834 18.6997C4.95276 18.4353 4.50009 18.0118 4.19998 17.485C3.85911 16.8867 3.8065 16.0975 3.70129 14.5193L3 4M8 8.5V13.5M12 8.5V13.5"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SortIconSVG = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.5 9.375L0 5.875H7L3.5 9.375Z" fill="#999999" />
    <path d="M3.5 0.625L0 4.125H7L3.5 0.625Z" fill="#999999" />
  </svg>
)

export const DownArrowSVG = () => (
  <svg
    width="7"
    height="5"
    viewBox="0 0 7 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.5 4.25L0 0.75H7L3.5 4.25Z" fill="#898989" />
  </svg>
)

export const EnvelopeSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 4.375L10 11.25L2.5 4.375"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.63281 10L2.69531 15.4453"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3047 15.4453L11.3672 10"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LockSVG = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.375 5.31252H2.625C2.38338 5.31252 2.1875 5.50839 2.1875 5.75002V11.875C2.1875 12.1167 2.38338 12.3125 2.625 12.3125H11.375C11.6167 12.3125 11.8125 12.1167 11.8125 11.875V5.75002C11.8125 5.50839 11.6167 5.31252 11.375 5.31252Z"
      stroke="#898989"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.03101 5.31252V3.34376C5.03101 2.82162 5.23843 2.32086 5.60764 1.95164C5.97685 1.58243 6.47762 1.37501 6.99976 1.37501C7.52191 1.37501 8.02267 1.58243 8.39188 1.95164C8.7611 2.32086 8.96852 2.82162 8.96852 3.34376V5.31252"
      stroke="#898989"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00025 9.46878C7.36268 9.46878 7.6565 9.17497 7.6565 8.81253C7.6565 8.45009 7.36268 8.15628 7.00025 8.15628C6.63781 8.15628 6.34399 8.45009 6.34399 8.81253C6.34399 9.17497 6.63781 9.46878 7.00025 9.46878Z"
      fill="#898989"
    />
  </svg>
)

export const PhoneGraySVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.22656 9.75C7.8697 11.0781 8.94366 12.1493 10.2734 12.7891C10.3715 12.8355 10.48 12.8556 10.5882 12.8474C10.6965 12.8392 10.8007 12.8029 10.8906 12.7422L12.8437 11.4375C12.93 11.379 13.0298 11.3433 13.1336 11.3337C13.2374 11.3241 13.342 11.341 13.4375 11.3828L17.0938 12.9531C17.2187 13.0051 17.3231 13.0968 17.3909 13.2139C17.4586 13.3311 17.486 13.4673 17.4688 13.6016C17.3529 14.506 16.9114 15.3373 16.2269 15.9398C15.5424 16.5423 14.6619 16.8748 13.75 16.875C10.9321 16.875 8.22956 15.7556 6.23699 13.763C4.24442 11.7704 3.125 9.06792 3.125 6.25C3.12521 5.33812 3.45767 4.45756 4.06018 3.77309C4.66269 3.08862 5.49395 2.64713 6.39844 2.53125C6.53269 2.514 6.66888 2.54137 6.78605 2.60913C6.90322 2.67689 6.99487 2.78129 7.04687 2.90625L8.61719 6.57031C8.65802 6.66433 8.67511 6.76696 8.66693 6.86914C8.65876 6.97131 8.62558 7.06992 8.57031 7.15625L7.26562 9.14062C7.20754 9.23038 7.17345 9.33353 7.16661 9.44022C7.15977 9.54691 7.18041 9.65357 7.22656 9.75V9.75Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LeftArrowSVG = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6 13L1 7M1 7L6.6 1M1 7H15.4"
      stroke="#F0792E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LeftArrowBlackSVG = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6 13L1 7M1 7L6.6 1M1 7H15.4"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const EnvelopePrimarySVG = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 5.3125L11 12.875L2.75 5.3125"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 5.3125H19.25V17C19.25 17.1823 19.1776 17.3572 19.0486 17.4861C18.9197 17.6151 18.7448 17.6875 18.5625 17.6875H3.4375C3.25516 17.6875 3.0803 17.6151 2.95136 17.4861C2.82243 17.3572 2.75 17.1823 2.75 17V5.3125Z"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.49609 11.5L2.96484 17.4898"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0352 17.4898L12.5039 11.5"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const BigspoonHeaderTitleSVG = () => (
  <svg
    width="86"
    height="15"
    viewBox="0 0 86 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.733398 13.8662V0.921746H6.32884C7.01168 0.921746 7.61864 1.06012 8.14973 1.33688C8.69347 1.60105 9.11708 1.97215 9.42056 2.45018C9.72405 2.9282 9.87579 3.488 9.87579 4.12956C9.87579 4.87176 9.67979 5.50075 9.28779 6.01651C8.90844 6.5197 8.44057 6.87193 7.88419 7.07321V7.14868C8.52909 7.34996 9.07282 7.73364 9.5154 8.29973C9.95798 8.86581 10.1793 9.56398 10.1793 10.3942C10.1793 11.1239 10.0022 11.7466 9.64818 12.2623C9.29411 12.7781 8.82625 13.1743 8.24457 13.4511C7.6629 13.7279 7.02432 13.8662 6.32884 13.8662H0.733398ZM3.16125 11.7151H5.93052C6.43633 11.7151 6.85361 11.5767 7.18239 11.3C7.5238 11.0232 7.69451 10.6207 7.69451 10.0923C7.69451 9.58914 7.53645 9.16772 7.22032 8.82807C6.91684 8.47584 6.44897 8.29973 5.81672 8.29973H3.16125V11.7151ZM3.16125 6.16747H5.66498C6.24665 6.16747 6.67658 6.01022 6.95477 5.69573C7.24561 5.36866 7.39103 4.99127 7.39103 4.56356C7.39103 4.07295 7.23929 3.70185 6.93581 3.45026C6.64497 3.19867 6.2593 3.07287 5.77878 3.07287H3.16125V6.16747Z"
      fill="black"
    />
    <path d="M11.579 13.8662V0.921746H14.0069V13.8662H11.579Z" fill="black" />
    <path
      d="M20.4281 14.0927C18.8349 14.0927 17.5957 13.5517 16.7105 12.4699C15.838 11.388 15.4017 9.69607 15.4017 7.39399C15.4017 5.10449 15.8822 3.41881 16.8433 2.33696C17.8043 1.24253 19.1636 0.695312 20.9213 0.695312C21.7938 0.695312 22.6094 0.877718 23.3681 1.24253C24.1268 1.60734 24.7338 2.14197 25.189 2.84644C25.6569 3.5509 25.8908 4.4189 25.8908 5.45043H23.4061C23.4061 4.56985 23.1532 3.90313 22.6474 3.45026C22.1542 2.99739 21.5536 2.77096 20.8454 2.77096C19.8338 2.77096 19.0878 3.14206 18.6073 3.88426C18.1394 4.61388 17.9055 5.62654 17.9055 6.92225V7.86573C17.9055 9.18659 18.1457 10.2118 18.6262 10.9415C19.1067 11.6585 19.8528 12.017 20.8644 12.017C21.6105 12.017 22.2174 11.734 22.6853 11.1679C23.1658 10.6018 23.4061 9.84702 23.4061 8.90355V7.99781L24.4303 8.90355H20.4851V6.8279H25.8908V13.8662H24.3165L23.8992 12.4321C23.4819 12.9731 22.9698 13.3882 22.3628 13.6775C21.7559 13.9543 21.111 14.0927 20.4281 14.0927Z"
      fill="black"
    />
    <path
      d="M35.8533 14.0927C35.0566 14.0927 34.3169 13.9417 33.634 13.6398C32.9512 13.3379 32.4012 12.8913 31.9839 12.3001C31.5666 11.6962 31.3579 10.954 31.3579 10.0735C31.3579 9.99798 31.3579 9.9225 31.3579 9.84702C31.3706 9.77155 31.3769 9.70865 31.3769 9.65833H33.8996C33.8996 9.67091 33.8933 9.71494 33.8806 9.79042C33.8806 9.85332 33.8806 9.90992 33.8806 9.96024C33.8806 10.5766 34.064 11.0735 34.4307 11.4509C34.7974 11.8283 35.3095 12.017 35.9671 12.017C36.7005 12.017 37.2126 11.8598 37.5034 11.5453C37.7943 11.2182 37.9397 10.866 37.9397 10.4886C37.9397 10.0483 37.8259 9.69607 37.5983 9.4319C37.3707 9.16772 37.0672 8.95387 36.6878 8.79033C36.3211 8.61422 35.9102 8.45697 35.4549 8.31859C35.0124 8.18022 34.5635 8.02297 34.1082 7.84686C33.653 7.67074 33.2357 7.44431 32.8564 7.16755C32.4897 6.87822 32.1925 6.50712 31.9649 6.05425C31.7373 5.60139 31.6235 5.02272 31.6235 4.31826C31.6235 3.50058 31.8258 2.82757 32.2304 2.29922C32.6351 1.75829 33.1662 1.35575 33.8237 1.09157C34.4939 0.827399 35.2084 0.695312 35.9671 0.695312C36.7384 0.695312 37.4402 0.827399 38.0725 1.09157C38.7174 1.35575 39.2295 1.77087 39.6088 2.33696C40.0008 2.89047 40.1968 3.6138 40.1968 4.50695V4.73339H37.7121V4.56356C37.7121 4.01006 37.554 3.57606 37.2379 3.26157C36.9344 2.93449 36.5045 2.77096 35.9481 2.77096C35.3664 2.77096 34.9175 2.90305 34.6014 3.16722C34.2853 3.41881 34.1272 3.74588 34.1272 4.14843C34.1272 4.52582 34.241 4.82774 34.4686 5.05417C34.6962 5.2806 34.9934 5.4693 35.3601 5.62025C35.7395 5.77121 36.1567 5.91588 36.612 6.05425C37.0672 6.18005 37.5161 6.33101 37.9587 6.50712C38.4139 6.68324 38.8249 6.91596 39.1916 7.20529C39.5709 7.48205 39.8744 7.84686 40.102 8.29973C40.3296 8.74001 40.4434 9.29981 40.4434 9.97911C40.4434 10.9729 40.2474 11.7717 39.8554 12.3755C39.4634 12.9668 38.9197 13.4008 38.2242 13.6775C37.5414 13.9543 36.7511 14.0927 35.8533 14.0927Z"
      fill="black"
    />
    <path
      d="M42.1102 13.8662V0.921746H46.7573C47.6551 0.921746 48.4074 1.09157 49.0144 1.43122C49.6214 1.75829 50.0766 2.21745 50.3801 2.8087C50.6836 3.38736 50.8353 4.05409 50.8353 4.80887C50.8353 5.61397 50.6646 6.32472 50.3232 6.94112C49.9818 7.54494 49.5076 8.01668 48.9006 8.35633C48.2936 8.68341 47.5792 8.84694 46.7573 8.84694H44.5381V13.8662H42.1102ZM44.5381 6.71469H46.5107C47.105 6.71469 47.5539 6.54486 47.8574 6.20521C48.1735 5.86556 48.3316 5.40011 48.3316 4.80887C48.3316 4.28052 48.1735 3.8591 47.8574 3.54461C47.5413 3.23012 47.0924 3.07287 46.5107 3.07287H44.5381V6.71469Z"
      fill="black"
    />
    <path
      d="M57.206 14.0927C55.5243 14.0927 54.2028 13.5454 53.2418 12.451C52.2808 11.3566 51.8003 9.67091 51.8003 7.39399C51.8003 5.10449 52.2808 3.41881 53.2418 2.33696C54.2028 1.24253 55.5243 0.695312 57.206 0.695312C58.8878 0.695312 60.2093 1.24253 61.1703 2.33696C62.1313 3.41881 62.6118 5.10449 62.6118 7.39399C62.6118 9.67091 62.1313 11.3566 61.1703 12.451C60.2093 13.5454 58.8878 14.0927 57.206 14.0927ZM57.206 12.017C58.1797 12.017 58.9068 11.6522 59.3873 10.9226C59.8678 10.1804 60.1081 9.16143 60.1081 7.86573V6.92225C60.1081 5.62654 59.8678 4.61388 59.3873 3.88426C58.9068 3.14206 58.1797 2.77096 57.206 2.77096C56.245 2.77096 55.5179 3.14206 55.0248 3.88426C54.5443 4.61388 54.304 5.62654 54.304 6.92225V7.86573C54.304 9.16143 54.5443 10.1804 55.0248 10.9226C55.5179 11.6522 56.245 12.017 57.206 12.017Z"
      fill="black"
    />
    <path
      d="M69.1119 14.0927C67.4301 14.0927 66.1087 13.5454 65.1477 12.451C64.1867 11.3566 63.7062 9.67091 63.7062 7.39399C63.7062 5.10449 64.1867 3.41881 65.1477 2.33696C66.1087 1.24253 67.4301 0.695312 69.1119 0.695312C70.7937 0.695312 72.1151 1.24253 73.0761 2.33696C74.0372 3.41881 74.5177 5.10449 74.5177 7.39399C74.5177 9.67091 74.0372 11.3566 73.0761 12.451C72.1151 13.5454 70.7937 14.0927 69.1119 14.0927ZM69.1119 12.017C70.0856 12.017 70.8127 11.6522 71.2932 10.9226C71.7737 10.1804 72.014 9.16143 72.014 7.86573V6.92225C72.014 5.62654 71.7737 4.61388 71.2932 3.88426C70.8127 3.14206 70.0856 2.77096 69.1119 2.77096C68.1509 2.77096 67.4238 3.14206 66.9306 3.88426C66.4501 4.61388 66.2099 5.62654 66.2099 6.92225V7.86573C66.2099 9.16143 66.4501 10.1804 66.9306 10.9226C67.4238 11.6522 68.1509 12.017 69.1119 12.017Z"
      fill="black"
    />
    <path
      d="M76.1052 13.8662V0.921746H78.2485L82.2697 8.05442C82.3455 8.15506 82.4277 8.30602 82.5162 8.50729C82.6048 8.69598 82.6806 8.8721 82.7439 9.03564C82.8197 9.18659 82.8577 9.26207 82.8577 9.26207L82.9146 9.20546C82.9146 9.20546 82.9146 9.12998 82.9146 8.97903C82.9146 8.82807 82.9082 8.66454 82.8956 8.48842C82.8956 8.3123 82.8956 8.1928 82.8956 8.1299V0.921746H85.0579V13.8662H82.8956L78.8745 6.73356C78.8239 6.6455 78.748 6.50712 78.6469 6.31843C78.5583 6.11715 78.4761 5.93475 78.4003 5.77121C78.3244 5.60767 78.2865 5.52591 78.2865 5.52591L78.2106 5.58252C78.2106 5.58252 78.2106 5.6517 78.2106 5.79008C78.2232 5.91588 78.2296 6.06683 78.2296 6.24295C78.2422 6.40648 78.2485 6.54486 78.2485 6.65808V13.8662H76.1052Z"
      fill="black"
    />
  </svg>
)

export const CloudUploadSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 14L12.5 10L8.5 14"
      stroke="#D9D9D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 19H19C21.485 19 23.5 16.985 23.5 14.5C23.5 12.185 21.745 10.301 19.497 10.05C19.498 10.033 19.5 10.017 19.5 10C19.5 6.134 16.366 3 12.5 3C8.634 3 5.5 6.134 5.5 10C5.5 10.017 5.502 10.033 5.503 10.05C3.255 10.301 1.5 12.185 1.5 14.5C1.5 16.985 3.515 19 6 19H7.5"
      stroke="#D9D9D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 10V20"
      stroke="#D9D9D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ExportSVG = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.21875 5.03125L10.5 1.75L13.7812 5.03125"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 10.5V1.75"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 8H16.125C16.2908 8 16.4497 8.06585 16.5669 8.18306C16.6842 8.30027 16.75 8.45924 16.75 8.625V16.75C16.75 16.9158 16.6842 17.0747 16.5669 17.1919C16.4497 17.3092 16.2908 17.375 16.125 17.375H4.875C4.70924 17.375 4.55027 17.3092 4.43306 17.1919C4.31585 17.0747 4.25 16.9158 4.25 16.75V8.625C4.25 8.45924 4.31585 8.30027 4.43306 8.18306C4.55027 8.06585 4.70924 8 4.875 8H6.75"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MagnifyingGlassSVG = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4375 9.875C7.61212 9.875 9.375 8.11212 9.375 5.9375C9.375 3.76288 7.61212 2 5.4375 2C3.26288 2 1.5 3.76288 1.5 5.9375C1.5 8.11212 3.26288 9.875 5.4375 9.875Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22168 8.72168L10.4998 10.9998"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const BlockSVG = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49984 1.4165C4.59409 1.4165 1.4165 4.59409 1.4165 8.49984C1.4165 12.4056 4.59409 15.5832 8.49984 15.5832C12.4056 15.5832 15.5832 12.4056 15.5832 8.49984C15.5832 4.59409 12.4056 1.4165 8.49984 1.4165ZM2.83317 8.49984C2.83317 7.19225 3.28225 5.99092 4.02884 5.03113L11.9685 12.9708C10.9783 13.7456 9.75717 14.1666 8.49984 14.1665C5.37538 14.1665 2.83317 11.6243 2.83317 8.49984ZM12.9708 11.9685L5.03113 4.02884C6.02141 3.25413 7.24253 2.83321 8.49984 2.83317C11.6243 2.83317 14.1665 5.37538 14.1665 8.49984C14.1661 9.75709 13.7453 10.9781 12.9708 11.9685Z"
      fill="#EF0000"
    />
  </svg>
)

export const BlockGraySVG = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49984 1.4165C4.59409 1.4165 1.4165 4.59409 1.4165 8.49984C1.4165 12.4056 4.59409 15.5832 8.49984 15.5832C12.4056 15.5832 15.5832 12.4056 15.5832 8.49984C15.5832 4.59409 12.4056 1.4165 8.49984 1.4165ZM2.83317 8.49984C2.83317 7.19225 3.28225 5.99092 4.02884 5.03113L11.9685 12.9708C10.9783 13.7456 9.75717 14.1666 8.49984 14.1665C5.37538 14.1665 2.83317 11.6243 2.83317 8.49984ZM12.9708 11.9685L5.03113 4.02884C6.02141 3.25413 7.24253 2.83321 8.49984 2.83317C11.6243 2.83317 14.1665 5.37538 14.1665 8.49984C14.1661 9.75709 13.7453 10.9781 12.9708 11.9685Z"
      fill="#9B9B9B"
    />
  </svg>
)

export const OrdersBoxGraySVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.6665 4.6665V6.4165C4.6665 6.7385 4.92725 6.99984 5.24984 6.99984H8.74984C9.07242 6.99984 9.33317 6.7385 9.33317 6.4165V4.6665"
      stroke="#929292"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4168 4.6665H0.583496V2.33317C0.583496 1.68917 1.10616 1.1665 1.75016 1.1665H12.2502C12.8942 1.1665 13.4168 1.68917 13.4168 2.33317V4.6665Z"
      stroke="#929292"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.1665 4.6665V11.6665C1.1665 12.3105 1.68917 12.8332 2.33317 12.8332H11.6665C12.3105 12.8332 12.8332 12.3105 12.8332 11.6665V4.6665"
      stroke="#929292"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarGraySVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M0.583496 5.8335H13.4168"
        stroke="black"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2502 13.4167H1.75016C1.10616 13.4167 0.583496 12.894 0.583496 12.25V2.91667C0.583496 2.27267 1.10616 1.75 1.75016 1.75H12.2502C12.8942 1.75 13.4168 2.27267 13.4168 2.91667V12.25C13.4168 12.894 12.8942 13.4167 12.2502 13.4167Z"
        stroke="black"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 0.583496V3.50016"
        stroke="black"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 0.583496V3.50016"
        stroke="black"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const RatingStarGraySVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 7.885L7.6855 9.75L6.9065 6.235L9.5 3.87L6.08475 3.565L4.75 0.25L3.41525 3.565L0 3.87L2.5935 6.235L1.8145 9.75L4.75 7.885Z"
      fill="#B42318"
    />
  </svg>
)

export const HashSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.06592 6.75H15.7503"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.375 2.8125L10.125 15.1875"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 2.8125L5.625 15.1875"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 11.25H14.9344"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MapTrifoldSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 12.9375L2.25 14.0625V3.9375L6.75 2.8125"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 15.1875L6.75 12.9375V2.8125L11.25 5.0625V15.1875Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 5.0625L15.75 3.9375V14.0625L11.25 15.1875"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PhoneSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50391 8.77484C7.08273 9.97016 8.04929 10.9342 9.24609 11.51C9.33437 11.5518 9.43202 11.5699 9.52942 11.5625C9.62681 11.5551 9.72061 11.5225 9.80156 11.4678L11.5594 10.2936C11.637 10.2409 11.7268 10.2088 11.8202 10.2002C11.9137 10.1916 12.0078 10.2068 12.0938 10.2444L15.3844 11.6577C15.4968 11.7045 15.5908 11.7869 15.6518 11.8924C15.7128 11.9978 15.7374 12.1204 15.7219 12.2412C15.6176 13.0553 15.2202 13.8034 14.6042 14.3457C13.9882 14.8879 13.1957 15.1872 12.375 15.1873C9.83887 15.1873 7.40661 14.1799 5.61329 12.3865C3.81997 10.5932 2.8125 8.16097 2.8125 5.62484C2.81268 4.80415 3.1119 4.01165 3.65416 3.39562C4.19642 2.7796 4.94456 2.38226 5.75859 2.27796C5.87942 2.26244 6.00199 2.28707 6.10745 2.34806C6.2129 2.40904 6.29538 2.503 6.34219 2.61546L7.75547 5.91312C7.79222 5.99774 7.8076 6.0901 7.80024 6.18206C7.79288 6.27402 7.76302 6.36276 7.71328 6.44046L6.53906 8.2264C6.48679 8.30718 6.4561 8.40002 6.44995 8.49604C6.44379 8.59206 6.46237 8.68805 6.50391 8.77484V8.77484Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CoinSVG = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.1875C13.0386 11.1875 16.3125 9.67646 16.3125 7.8125C16.3125 5.94854 13.0386 4.4375 9 4.4375C4.96142 4.4375 1.6875 5.94854 1.6875 7.8125C1.6875 9.67646 4.96142 11.1875 9 11.1875Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.1875V14.5625"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6875 7.8125V11.1875C1.6875 12.875 4.5 14.5625 9 14.5625C13.5 14.5625 16.3125 12.875 16.3125 11.1875V7.8125"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 10.4917V13.8667"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 10.4917V13.8667"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const WalletSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8125 4.5V13.5C2.8125 13.7984 2.93103 14.0845 3.142 14.2955C3.35298 14.5065 3.63913 14.625 3.9375 14.625H15.1875C15.3367 14.625 15.4798 14.5657 15.5852 14.4602C15.6907 14.3548 15.75 14.2117 15.75 14.0625V6.1875C15.75 6.03832 15.6907 5.89524 15.5852 5.78975C15.4798 5.68426 15.3367 5.625 15.1875 5.625H3.9375C3.63913 5.625 3.35298 5.50647 3.142 5.29549C2.93103 5.08452 2.8125 4.79837 2.8125 4.5ZM2.8125 4.5C2.8125 4.20163 2.93103 3.91548 3.142 3.7045C3.35298 3.49353 3.63913 3.375 3.9375 3.375H13.5"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6562 10.9688C13.1222 10.9688 13.5 10.591 13.5 10.125C13.5 9.65901 13.1222 9.28125 12.6562 9.28125C12.1903 9.28125 11.8125 9.65901 11.8125 10.125C11.8125 10.591 12.1903 10.9688 12.6562 10.9688Z"
      fill="#F0792E"
    />
  </svg>
)

export const UsersSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1875 11.25C8.20679 11.25 9.84375 9.61304 9.84375 7.59375C9.84375 5.57446 8.20679 3.9375 6.1875 3.9375C4.16821 3.9375 2.53125 5.57446 2.53125 7.59375C2.53125 9.61304 4.16821 11.25 6.1875 11.25Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M10.9268 4.07109C11.25 3.98343 11.5833 3.93852 11.9182 3.9375C12.8879 3.9375 13.8178 4.32271 14.5035 5.00839C15.1892 5.69407 15.5744 6.62405 15.5744 7.59375C15.5744 8.56345 15.1892 9.49343 14.5035 10.1791C13.8178 10.8648 12.8879 11.25 11.9182 11.25"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.125 13.8794C1.6959 13.067 2.45393 12.404 3.33507 11.9462C4.2162 11.4885 5.19456 11.2495 6.1875 11.2495C7.18044 11.2495 8.1588 11.4885 9.03993 11.9462C9.92107 12.404 10.6791 13.067 11.25 13.8794"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.918 11.25C12.911 11.2494 13.8895 11.4881 14.7708 11.9458C15.652 12.4036 16.41 13.0669 16.9805 13.8797"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SlidersSVG = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 8.9375V17.375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 3.625V5.8125"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 8.9375C11.3629 8.9375 12.0625 8.23794 12.0625 7.375C12.0625 6.51206 11.3629 5.8125 10.5 5.8125C9.63706 5.8125 8.9375 6.51206 8.9375 7.375C8.9375 8.23794 9.63706 8.9375 10.5 8.9375Z"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.125 15.1875V17.375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.125 3.625V12.0625"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.125 15.1875C16.9879 15.1875 17.6875 14.4879 17.6875 13.625C17.6875 12.7621 16.9879 12.0625 16.125 12.0625C15.2621 12.0625 14.5625 12.7621 14.5625 13.625C14.5625 14.4879 15.2621 15.1875 16.125 15.1875Z"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 12.6875V17.375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 3.625V9.5625"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 12.6875C5.73794 12.6875 6.4375 11.9879 6.4375 11.125C6.4375 10.2621 5.73794 9.5625 4.875 9.5625C4.01206 9.5625 3.3125 10.2621 3.3125 11.125C3.3125 11.9879 4.01206 12.6875 4.875 12.6875Z"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LocationGraySVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00075 13.4166C7.00075 13.4166 11.6668 7.802 11.6668 5.39575C11.6668 2.9895 10.1111 0.583252 7.00075 0.583252C3.88925 0.583252 2.3335 2.9895 2.3335 5.39575C2.3335 7.802 7.00075 13.4166 7.00075 13.4166"
      stroke="#929292"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7C6.03342 7 5.25 6.216 5.25 5.25C5.25 4.284 6.03342 3.5 7 3.5C7.966 3.5 8.75 4.284 8.75 5.25C8.75 6.216 7.966 7 7 7Z"
      stroke="#929292"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ClockSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M9 5.0625V9H12.9375"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ClockBlackSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
      stroke="#000000"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M9 5.0625V9H12.9375"
      stroke="#000000"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ForkKnifeSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.90625 2.25V5.0625"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.90625 8.15625V15.75"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 2.25L8.4375 5.625C8.4375 6.29633 8.17082 6.94016 7.69611 7.41486C7.22141 7.88957 6.57758 8.15625 5.90625 8.15625C5.23492 8.15625 4.59109 7.88957 4.11639 7.41486C3.64168 6.94016 3.375 6.29633 3.375 5.625L3.9375 2.25"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3438 11.25H10.4062C10.4062 11.25 11.25 3.375 14.3438 2.25V15.75"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IdBadgeSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.8125C10.2426 11.8125 11.25 10.8051 11.25 9.5625C11.25 8.31986 10.2426 7.3125 9 7.3125C7.75736 7.3125 6.75 8.31986 6.75 9.5625C6.75 10.8051 7.75736 11.8125 9 11.8125Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 13.5C6.01796 12.976 6.52752 12.5508 7.11332 12.2579C7.69911 11.965 8.34506 11.8125 9 11.8125C9.65494 11.8125 10.3009 11.965 10.8867 12.2579C11.4725 12.5508 11.982 12.976 12.375 13.5"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.625 15.1875V2.8125C14.625 2.50184 14.3732 2.25 14.0625 2.25L3.9375 2.25C3.62684 2.25 3.375 2.50184 3.375 2.8125V15.1875C3.375 15.4982 3.62684 15.75 3.9375 15.75H14.0625C14.3732 15.75 14.625 15.4982 14.625 15.1875Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 4.5H11.25"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RightArrowSVG = () => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1.5L5.5 6L1 10.5" stroke="#F0792E" strokeWidth="2" />
  </svg>
)

export const PlusSVG = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00049 8H14.0005"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 2.50049V13.5005"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DownloadSVG = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71875 9.09375L10 12.375L13.2812 9.09375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.625V12.375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 12.375V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V12.375"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ClockClockwiseSVG = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6.75V10.5"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 12.375L10 10.5"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3906 8.28931H17.5156V5.16431"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8589 15.3594C13.8977 16.3214 12.6727 16.9768 11.3389 17.2425C10.0051 17.5082 8.62252 17.3724 7.36595 16.8522C6.10938 16.332 5.03532 15.4508 4.27964 14.3201C3.52396 13.1894 3.12061 11.86 3.12061 10.5C3.12061 9.14002 3.52396 7.81058 4.27964 6.67987C5.03532 5.54917 6.10938 4.66798 7.36595 4.14779C8.62252 3.62761 10.0051 3.49179 11.3389 3.75752C12.6727 4.02324 13.8977 4.67858 14.8589 5.64063L17.5152 8.28907"
      stroke="#F0792E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ChatSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 7.3125H11.25"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 9.5625H11.25"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5258 13.7742L9.48516 15.5109C9.43419 15.5938 9.36284 15.6623 9.27791 15.7097C9.19298 15.7572 9.0973 15.7822 9 15.7822C8.9027 15.7822 8.80702 15.7572 8.72209 15.7097C8.63716 15.6623 8.56581 15.5938 8.51484 15.5109L7.47422 13.7742C7.42527 13.6909 7.35542 13.6217 7.27157 13.5736C7.18771 13.5255 7.09276 13.5002 6.99609 13.5H2.8125C2.66332 13.5 2.52024 13.4407 2.41475 13.3352C2.30926 13.2298 2.25 13.0867 2.25 12.9375V3.9375C2.25 3.78832 2.30926 3.64524 2.41475 3.53975C2.52024 3.43426 2.66332 3.375 2.8125 3.375H15.1875C15.3367 3.375 15.4798 3.43426 15.5852 3.53975C15.6907 3.64524 15.75 3.78832 15.75 3.9375V12.9375C15.75 13.0867 15.6907 13.2298 15.5852 13.3352C15.4798 13.4407 15.3367 13.5 15.1875 13.5H11.0039C10.9072 13.5002 10.8123 13.5255 10.7284 13.5736C10.6446 13.6217 10.5747 13.6909 10.5258 13.7742V13.7742Z"
      stroke="#F0792E"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LocationSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0005 16.3334C9.0005 16.3334 14.3332 9.91675 14.3332 7.16675C14.3332 4.41675 12.5552 1.66675 9.0005 1.66675C5.4445 1.66675 3.6665 4.41675 3.6665 7.16675C3.6665 9.91675 9.0005 16.3334 9.0005 16.3334"
      stroke="#F0792E"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9C7.89533 9 7 8.104 7 7C7 5.896 7.89533 5 9 5C10.104 5 11 5.896 11 7C11 8.104 10.104 9 9 9Z"
      stroke="#F0792E"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ConcentricCircleGraySVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      fill="white"
      stroke="#D9D9D9"
      strokeWidth="2"
    />
    <circle cx="12" cy="12" r="5" fill="#D9D9D9" />
  </svg>
)

export const CalendarBlackSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 1.875V4.375"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 1.875V4.375"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 6.875H16.875"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CircleWavyCheckSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25781 15.7422C3.53906 15.0234 4.01563 13.5156 3.64844 12.6328C3.28125 11.75 1.875 10.9766 1.875 10C1.875 9.02344 3.26563 8.28125 3.64844 7.36719C4.03125 6.45313 3.53906 4.97656 4.25781 4.25781C4.97656 3.53906 6.48438 4.01563 7.36719 3.64844C8.25 3.28125 9.02344 1.875 10 1.875C10.9766 1.875 11.7187 3.26563 12.6328 3.64844C13.5469 4.03125 15.0234 3.53906 15.7422 4.25781C16.4609 4.97656 15.9844 6.48438 16.3516 7.36719C16.7188 8.25 18.125 9.02344 18.125 10C18.125 10.9766 16.7344 11.7187 16.3516 12.6328C15.9688 13.5469 16.4609 15.0234 15.7422 15.7422C15.0234 16.4609 13.5156 15.9844 12.6328 16.3516C11.75 16.7188 10.9766 18.125 10 18.125C9.02344 18.125 8.28125 16.7344 7.36719 16.3516C6.45313 15.9688 4.97656 16.4609 4.25781 15.7422Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4375 8.125L8.85156 12.5L6.5625 10.3125"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const StorefrontBlackSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 10.9062V16.25C3.75 16.4158 3.81585 16.5747 3.93306 16.6919C4.05027 16.8092 4.20924 16.875 4.375 16.875H15.625C15.7908 16.875 15.9497 16.8092 16.0669 16.6919C16.1842 16.5747 16.25 16.4158 16.25 16.25V10.9062"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21875 3.125H15.7812C15.9168 3.12612 16.0485 3.17075 16.1568 3.25233C16.2651 3.33391 16.3443 3.44812 16.3828 3.57812L17.5 7.5H2.5L3.61719 3.57812C3.65568 3.44812 3.73492 3.33391 3.84322 3.25233C3.95152 3.17075 4.08317 3.12612 4.21875 3.125V3.125Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 7.5V8.75C7.5 9.41304 7.23661 10.0489 6.76777 10.5178C6.29893 10.9866 5.66304 11.25 5 11.25C4.33696 11.25 3.70107 10.9866 3.23223 10.5178C2.76339 10.0489 2.5 9.41304 2.5 8.75V7.5"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 7.5V8.75C12.5 9.41304 12.2366 10.0489 11.7678 10.5178C11.2989 10.9866 10.663 11.25 10 11.25C9.33696 11.25 8.70107 10.9866 8.23223 10.5178C7.76339 10.0489 7.5 9.41304 7.5 8.75V7.5"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 7.5V8.75C17.5 9.41304 17.2366 10.0489 16.7678 10.5178C16.2989 10.9866 15.663 11.25 15 11.25C14.337 11.25 13.7011 10.9866 13.2322 10.5178C12.7634 10.0489 12.5 9.41304 12.5 8.75V7.5"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PauseCircleSVG = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
      stroke="#F0792E"
      strokeMiterlimit="10"
    />
    <path
      d="M6.5 6.5V10.5"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 6.5V10.5"
      stroke="#F0792E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const UploadImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M22.5 38V25.5H10V22.5H22.5V10H25.5V22.5H38V25.5H25.5V38H22.5Z"
      fill="#554AF0"
    />
  </svg>
)

export const SortByIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.6667 10.4167V5.66669C16.6667 4.26656 16.6667 3.56649 16.3942 3.03171C16.1545 2.56131 15.7721 2.17885 15.3017 1.93917C14.7669 1.66669 14.0668 1.66669 12.6667 1.66669H7.33334C5.93321 1.66669 5.23315 1.66669 4.69837 1.93917C4.22796 2.17885 3.84551 2.56131 3.60583 3.03171C3.33334 3.56649 3.33334 4.26656 3.33334 5.66669V14.3334C3.33334 15.7335 3.33334 16.4336 3.60583 16.9683C3.84551 17.4387 4.22796 17.8212 4.69837 18.0609C5.23315 18.3334 5.93317 18.3334 7.33323 18.3334H10.4167M11.6667 9.16669H6.66668M8.33334 12.5H6.66668M13.3333 5.83335H6.66668M12.5 15.8334L15 18.3334M15 18.3334L17.5 15.8334M15 18.3334V13.3334"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const FiltersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
