import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useNotification } from "context";
import { uploadMedia } from "services/apis/admin";
import { checkAuth } from "utils/helper";
import { UploadImage } from "assets/icons/index";
import "./ImageUpload.scss";

const ImageUpload = ({
  mediaTypes,
  media,
  setMedia,
  mediaValidationText,
  setMediaValidationText,
  mediaSize,
}: {
  mediaTypes: Array<string>;
  media?: string;
  setMedia: (media: any) => void;
  mediaValidationText?: null | string;
  setMediaValidationText?: (text: string) => void;
  mediaSize?: number;
}) => {
  const [currentMedia, setCurrentMedia] = useState("");
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
  const navigate = useNavigate();
  const { setText } = useNotification();

  const previousAcceptedFiles = useRef(acceptedFiles);

  // Memoize mediaTypes to avoid unnecessary re-renders
  const mediaTypesMemo = useMemo(() => mediaTypes, [mediaTypes]);

  useEffect(() => {
    if (media && typeof media === "string") {
      setCurrentMedia(media);
    }
  }, [media]);

  const handleFileUpload = useCallback(async () => {
    if (acceptedFiles.length > 0) {
      if (mediaTypesMemo.includes(acceptedFiles[0].type)) {
        if (setMediaValidationText) setMediaValidationText("");

        if (
          acceptedFiles[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          setMedia(acceptedFiles[0]);
        } else if (mediaSize && acceptedFiles[0].size > mediaSize) {
          setMediaValidationText &&
            setMediaValidationText(
              `Media should be under ${mediaSize / 1000}KB`
            );
        } else {
          checkAuth(
            async () => {
              const response = await uploadMedia(acceptedFiles[0]);
              if (response.success) {
                setMedia(response.data);
                setCurrentMedia(response.data);
              }
            },
            setText,
            navigate
          );
        }
      } else {
        setMediaValidationText && setMediaValidationText("Invalid file format");
        setCurrentMedia("");
      }
    }
  }, [
    mediaTypesMemo,
    mediaSize,
    setMedia,
    setMediaValidationText,
    setText,
    navigate,
    acceptedFiles,
  ]);

  useEffect(() => {
    if (
      acceptedFiles !== previousAcceptedFiles.current &&
      acceptedFiles.length > 0 &&
      mediaTypesMemo.includes(acceptedFiles[0].type)
    ) {
      handleFileUpload();
      previousAcceptedFiles.current = acceptedFiles;
    }
  }, [acceptedFiles, mediaTypesMemo, handleFileUpload]);

  function getMediaPreview() {
    if (
      mediaTypesMemo.includes("image/png") ||
      mediaTypesMemo.includes("image/jpeg")
    ) {
      return (
        <img
          src={currentMedia}
          alt=""
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "50%",
          }}
          className="bg-gray "
        />
      );
    } else if (mediaTypesMemo.includes("video/mp4")) {
      return (
        <video width="150px" height="auto" controls>
          <source src={currentMedia} type="video/mp4" />
        </video>
      );
    }
    return <></>;
  }

  return (
    <div
      {...getRootProps({
        className: "mb-20 w-max cursor-ptr",
      })}
    >
      <input className="input-zone" {...getInputProps()} />
      <div
        className={`${
          mediaValidationText && "dropzone-error"
        }  flex flex-col align-center pt-40 pr-40 pb-40 pl-40 brd-10`}
      >
        {currentMedia === "" ? (
          <div className="circleUpload">
            <span className="images">
              <UploadImage />
            </span>
          </div>
        ) : (
          getMediaPreview()
        )}
        <p className="clr-grey txt-12 mt-10">
          Image should be 1000px and less than 2mb
        </p>
        <p
          style={{
            color: "#554AF0",
          }}
        >
          Upload
        </p>
      </div>
      {mediaValidationText && (
        <p className="txt-12 clr-red mt-5">{mediaValidationText}</p>
      )}
    </div>
  );
};

export default ImageUpload;
