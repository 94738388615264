import { Box } from "@mantine/core"
import { capitalize, getStatusBgColor, getStatusTxtColor } from "utils/helper"
import "./Badge.scss"
import { ReactNode } from "react"

interface propsType {
  type: string
  children?: ReactNode
  noColor?: boolean
}

const RoleStatusBadge = ({ type, children, noColor }: propsType) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: noColor
          ? theme.colors.primary[1]
          : getStatusBgColor(type),
        textAlign: "center",
        borderColor: noColor
          ? theme.colors.primary[0]
          : getStatusTxtColor(type),
        border: "1px solid",
        width: "fit-content",
        color: noColor ? theme.colors.primary[0] : getStatusTxtColor(type),
        padding: children ? "2px 8px 2px 6px" : "3px 10px",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
      })}
    >
      {children || null}
      <p className="txt-12 txtw-7">{capitalize(type)}</p>
    </Box>
  )
}

export default RoleStatusBadge
