import axios from "axios"
import { postBrandType } from "types/brand"
import { adminBaseUrl } from "services/url"
import { getStorageItem } from "services/storage"

const brandsUrl = `${adminBaseUrl}/brands`

export const getBrands = async (page: string | null, size: string | null) => {
  if (page !== null && size !== null) {
    const response = await axios({
      method: "GET",
      url: `${brandsUrl}?page=${page}&size=${size}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const getAllBrands = async () => {
  const response = await axios({
    method: "GET",
    url: `${brandsUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getBrand = async (brandId: string | undefined) => {
  if (brandId) {
    const response = await axios({
      method: "GET",
      url: `${brandsUrl}/${brandId}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const postBrand = async (brandData: postBrandType) => {
  const response = await axios({
    method: "POST",
    url: brandsUrl,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...brandData,
    },
  })
  return await response.data
}

export const deleteBrand = async (brandId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${brandsUrl}/${brandId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response
}

export const editBrand = async (brandId: string, brandData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${brandsUrl}/${brandId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...brandData,
    },
  })
  return await response.data
}
