import { Link, useNavigate } from "react-router-dom";
import { Menu } from "@mantine/core";
import NavBar from "components/Common/NavBar/NavBar";
// import { logoutUser } from "services/apis/admin"
// import { emptyStorage, getStorageItem } from "services/storage"
// import AdminTypeDropdown from "components/Common/AdminTypeDropdown/AdminTypeDropdown";
// import { ProteanHeaderLogo } from "assets/icons/index";
import styles from "./Header.module.scss";
// import { useTokenInterval } from "context/tokenInterval"
// import { checkAuth } from "utils/helper"
// import { useNotification } from "context"
import LogoutIcon from "assets/icons/log-out-04.svg";
import { getStorageItem } from "services/storage";
import ICICI from "assets/images/ICICI_Bank.jpg";
import CSCLogo from "assets/images/CSC_logo.png";
import WITSLogo from "assets/images/WITS_logo.png";
import KHOJLELogo from "assets/images/khojle-logo.png";

const Header = () => {
  const navigate = useNavigate();

  async function handleLogout() {
    sessionStorage.clear();
    navigate("/login");
  }
  const firstName = getStorageItem("firstName");
  const lastName = getStorageItem("lastName");

  return (
    <header className={styles.header}>
      <Link to="/hub" className="flex align-center">
        {process.env.REACT_APP_BRAND === "ICICI" ? (
          <img src={ICICI} className="w-150" alt="logo" />
        ) : (
          ""
        )}
        {process.env.REACT_APP_BRAND === "CSC" ? (
          <img src={CSCLogo} className="w-80" alt="logo" />
        ) : (
          ""
        )}
        {process.env.REACT_APP_BRAND === "WITSLAB" ? (
          <img src={WITSLogo} className="w-80" alt="logo" />
        ) : (
          ""
        )}
        {process.env.REACT_APP_BRAND === "KHOJLE" ? (
          <img src={KHOJLELogo} className="w-100" alt="logo" />
        ) : (
          ""
        )}
      </Link>
      <NavBar />

      <div className={styles["profile-buttons"]}>
        <img
          alt="logout-icon"
          src={LogoutIcon}
          className="cursor-ptr"
          onClick={(e: any) => {
            e.stopPropagation();
            handleLogout();
          }}
        />
        <Menu trigger="hover" width={150}>
          <Menu.Target>
            <div className={styles["profile-name"]}>
              <p>
                {firstName ? firstName.substring(0, 1) : "N/"}
                {lastName ? lastName.substring(0, 1) : "A"}
              </p>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={(e: any) => {
                e.stopPropagation();
                navigate("/profile");
              }}
            >
              My Profile
            </Menu.Item>
            {/* <Menu.Item
              onClick={(e: any) => {
                e.stopPropagation()
                navigate("/change-password")
              }}
            >
              Change Password
            </Menu.Item> */}
            <Menu.Item
              onClick={(e: any) => {
                e.stopPropagation();
                navigate("/buyer-app-info");
              }}
            >
              Buyer App Info
            </Menu.Item>
            <Menu.Item
              onClick={(e: any) => {
                e.stopPropagation();
                navigate("/bank-details");
              }}
            >
              Bank Details
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
