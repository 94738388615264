import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "context";
import { useTokenInterval } from "context/tokenInterval";
import { getToken } from "services/apis/admin";
import { setStorageItem } from "services/storage";
import { checkAuth } from "utils/helper";

export const useUpdateSearchParams = (
  page: number,
  size: string,
  setSearchParams: (params: any) => void
) => {
  useEffect(() => {
    const newParams = {
      page: String(page),
      size,
    };
    setSearchParams(newParams);
  }, [page, setSearchParams, size]);
};

export const useUpdateTokens = () => {
  const { id, setId } = useTokenInterval();
  const { setText } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      const id = setInterval(async () => {
        checkAuth(
          async () => {
            const response = await getToken();
            if (response.success) {
              setStorageItem("bsRefreshToken", response.data.refreshToken);
              setStorageItem("token", response.data.accessToken);
            }
          },
          setText,
          navigate
        );
      }, 28 * 60000);
      setId(id);
    }
  }, [id, navigate, setId, setText]);
};
