import { Box } from "@mantine/core"
import {
    getStatusBgColor,
    getStatusImgAlt,
    getStatusImgSrc,
    getStatusTxtColor,
} from "utils/helper"
import "./Badge.scss"

interface propsType {
    type: string
}

const OpenStatusBadge = ({ type }: propsType) => {
    return (
        <Box
            sx={{
                backgroundColor: getStatusBgColor(type),
                textAlign: "center",
                width: "80px",
                color: getStatusTxtColor(type),
                padding: "3px 10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                className="status-img"
                src={getStatusImgSrc(type)}
                alt={getStatusImgAlt(type)}
            />
            <p className="txt-12 txt-ucase txtw-5 ml-5">{type}</p>
        </Box>
    )
}

export default OpenStatusBadge
