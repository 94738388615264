import { createColumnHelper } from "@tanstack/react-table"
import { Link } from "react-router-dom"
import { ECommerceCustomersTableFields } from "types/customerManagement"
import { getDisplayDate, getDisplayTime, padDecimals } from "utils/helper"

const columnHelper = createColumnHelper<ECommerceCustomersTableFields>()

export const ecommerceColumns = [
  columnHelper.accessor("name", {
    enableSorting: false,
    header: "Customer Name",

    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    enableSorting: false,
    header: "Email",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("mobile", {
    header: "Contact",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("totalOrders", {
    header: "Total Orders",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("totalRevenue", {
    enableSorting: false,
    header: "Total Revenue",
    cell: (info: any) => "₹ " + padDecimals(info.getValue().toFixed(2)),
  }),
  columnHelper.accessor("lastLoginAt", {
    enableSorting: false,
    header: "Last Login On",
    cell: (info: any) =>
      `${getDisplayDate(info.getValue())} ${getDisplayTime(info.getValue())}`,
  }),
  columnHelper.accessor("signupAt", {
    enableSorting: false,
    header: "Signup On",
    cell: (info: any) =>
      `${getDisplayDate(info.getValue())} ${getDisplayTime(info.getValue())}`,
  }),
  columnHelper.accessor("_id", {
    header: "Action",
    enableSorting: false,
    cell: (info: any) => (
      <Link
        to={`/customers/${info.row.original._id}`}
        className="txt-12 clr-primary no-underline"
      >
        View
      </Link>
    ),
  }),
]

export const mobilityColumns = (currentPage: any = 1) => [
  columnHelper.accessor("name", {
    header: "Rider Name",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("mobile", {
    header: "Contact",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("totalOrders", {
    header: "Total Rides",
    cell: (info: any) => info.getValue(),
  }),
  // columnHelper.accessor("totalRevenue", {
  //   header: "Total Revenue",
  //   cell: (info: any) => "₹" + padDecimals(info.getValue()),
  // }),
  columnHelper.accessor("lastLoginAt", {
    header: "Last Login On",
    cell: (info: any) =>
      `${getDisplayDate(info.getValue())} ${getDisplayTime(info.getValue())}`,
  }),
  columnHelper.accessor("signupAt", {
    header: "Signup On",
    cell: (info: any) =>
      `${getDisplayDate(info.getValue())} ${getDisplayTime(info.getValue())}`,
  }),
  columnHelper.accessor("_id", {
    header: "Action",
    enableSorting: false,
    cell: (info: any) => (
      <Link
        to={`/customers/${info.row.original._id}`}
        className="txt-12 clr-primary no-underline"
        state={currentPage}
      >
        View
      </Link>
    ),
  }),
]
