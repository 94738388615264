import { useAdminTypeContext } from "context/adminType"
import ECommerceOrderManagement from "components/OrderManagement/ECommerceOrderManagement"
import MobilityRidesManagement from "components/OrderManagement/MobilityRidesManagement"

const OrderManagement = () => {
  const { adminType } = useAdminTypeContext()

  return adminType === "mobility" ? (
    <MobilityRidesManagement />
  ) : (
    <ECommerceOrderManagement />
  )
}

export default OrderManagement
