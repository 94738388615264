import {
  ExpandedState,
  Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import "./CustomTable.module.scss";
import cx from "classnames";
import { ReactComponent as DownArrow } from "assets/icons/DownArrow.svg";
import { ReactComponent as UpDownArrow } from "assets/icons/UpDownArrow.svg";
import { ReactComponent as UpArrow } from "assets/icons/UpArrow.svg";
import { ReactComponent as ChevronDown } from "assets/icons/ChevronDown.svg";
import { ReactComponent as ChevronRight } from "assets/icons/ChevronRight.svg";
import React, { useState } from "react";

type Props<TData> = {
  data: any;
  columns: any;
  className?: any;
  renderRowSubComponent?: any;
  expandable?: boolean;
  getRowCanExpand?: (row?: Row<TData>) => boolean;
  enableHiding?: boolean;
  columnVisibility?: Record<string, boolean>;
  rowClickHandler?: (row: TData) => void;
};

function CustomTable({
  data,
  columns,
  className,
  renderRowSubComponent,
  expandable = false,
  getRowCanExpand,
  enableHiding,
  columnVisibility,
  rowClickHandler,
}: Props<any>) {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnVisibilityState, setColumnVisibilityState] = useState({
    ...columnVisibility,
  });

  const modifiedColumns = [
    ...(getRowCanExpand?.()
      ? [
          {
            id: "expander",
            header: () => null,
            cell: ({ row }: any) => {
              return row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: {
                      cursor: "pointer",
                      border: "none",
                      width: 24,
                      height: 24,
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "transparent",
                    },
                  }}
                >
                  {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
                </button>
              ) : null;
            },
          },
        ]
      : []),
    ...columns,
  ];

  const table = useReactTable({
    data,
    columns: modifiedColumns,
    state: {
      expanded,
      columnVisibility: columnVisibilityState,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    enableExpanding: expandable,
    manualExpanding: true,
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibilityState,
    enableHiding,
  });

  return (
    <table className={cx(className)}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`${headerGroup.id}-${Math.random()}`}>
            {headerGroup.headers.map((header) => {
              const isSorted = header.column.getIsSorted();
              const canSort = header.column.getCanSort();
              return (
                <th key={`${header.id}-${Math.random()}`}>
                  <div className="flex align-center h-25">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {canSort &&
                      (isSorted === "desc" ? (
                        <span
                          className="txt-15 clr-black txtw-6 pl-20 cursor-ptr"
                          onClick={() => header.column.toggleSorting()}
                        >
                          <DownArrow />
                        </span>
                      ) : isSorted === "asc" ? (
                        <span
                          className="txt-15 clr-black txtw-6 pl-20 cursor-ptr"
                          onClick={() => header.column.toggleSorting()}
                        >
                          <UpArrow />
                        </span>
                      ) : (
                        <span
                          className="txt-15 clr-black txtw-6 pl-20 cursor-ptr"
                          onClick={() => header.column.toggleSorting()}
                        >
                          <UpDownArrow />
                        </span>
                      ))}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <React.Fragment key={`${row.id}-${Math.random()}`}>
            <tr
              key={`${row.id}-${Math.random()}`}
              onClick={
                rowClickHandler
                  ? () => rowClickHandler(row.original)
                  : undefined
              }
              style={rowClickHandler ? { cursor: "pointer" } : undefined}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={`${cell.id}-${Math.random()}`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {row.getIsExpanded() && (
              <tr>
                {/* 2nd row is a custom 1 cell row */}
                <td colSpan={row.getVisibleCells().length}>
                  {renderRowSubComponent({ row })}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default CustomTable;
