import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Textarea, TextInput } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { Dropzone } from "components/Common/Dropzone";
import { Button } from "components/Common/Button";
import { editBrand, getBrand, postBrand } from "services/apis/admin";
import { useNotification } from "context";
import { checkAuth, getNextDayClosingTime } from "utils/helper";

const BasicDetails = ({
  editBrandModal,
  setIsAddBrandModalOpen,
  setEditBrandModal,
}: {
  editBrandModal: any;
  setIsAddBrandModalOpen: (isAddBrandModalOpen: boolean) => void;
  setEditBrandModal: (isEditBrandModalOpen: any) => void;
}) => {
  const [brandLogo, setBrandLogo] = useState("");
  const [brandLogoValidationText, setBrandLogoValidationText] = useState("");
  const [brandIntro, setBrandIntro] = useState("");
  const [brandIntroValidationText, setBrandIntroValidationText] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [brandApiInProgress, setBrandApiInProgress] = useState(false);
  const basicDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      name: "",
      email: "",
      startTime: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        9,
        0,
        0,
        0
      ),
      endTime: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        21,
        0,
        0,
        0
      ),
    },
    validate: {
      name: (value) => (value.length === 0 ? "Name is required" : null),
      email: (value) =>
        value.length === 0
          ? "Email is required"
          : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? null
          : "Invalid email",
    },
  });
  const navigate = useNavigate();
  const { setText } = useNotification();

  useEffect(() => {
    (async () => {
      if (editBrandModal.isOpen) {
        checkAuth(
          async () => {
            const response = await getBrand(editBrandModal.brandId);
            if (response?.success) {
              basicDetailsForm.setValues({
                name: response?.data?.name,
                email: response?.data?.email,
                startTime: new Date(response?.data?.openingTime),
                endTime: new Date(response?.data?.closingTime),
              });
              setBrandDescription(response?.data?.description);
              setBrandLogo(response?.data?.media?.logo);
              setBrandIntro(response?.data?.media?.intro);
            }
          },
          setText,
          navigate
        );
      }
    })();
  }, [
    basicDetailsForm,
    editBrandModal.brandId,
    editBrandModal.isOpen,
    navigate,
    setText,
  ]);

  useEffect(() => {
    if (brandDescription.length > 360) {
      setBrandDescription(brandDescription.slice(0, 360));
    }
  }, [brandDescription]);

  async function handleAddBrand() {
    checkAuth(
      async () => {
        const response = await postBrand({
          name: basicDetailsForm.values.name,
          description: brandDescription,
          openingTime: basicDetailsForm.values.startTime,
          closingTime: getNextDayClosingTime(
            basicDetailsForm.values.startTime,
            basicDetailsForm.values.endTime
          ),
          email: basicDetailsForm.values.email,
          media: {
            logo: brandLogo,
            intro: brandIntro,
          },
        });
        if (response.success) {
          setIsAddBrandModalOpen(false);
        }
      },
      setText,
      navigate,
      setBrandApiInProgress
    );
  }

  async function handleEditBrand() {
    checkAuth(
      async () => {
        const response = await editBrand(editBrandModal.brandId, {
          name: basicDetailsForm.values.name,
          description: brandDescription,
          openingTime: basicDetailsForm.values.startTime,
          closingTime: getNextDayClosingTime(
            basicDetailsForm.values.startTime,
            basicDetailsForm.values.endTime
          ),
          email: basicDetailsForm.values.email,
          media: {
            logo: brandLogo,
            intro: brandIntro,
          },
        });
        if (response.success) {
          setEditBrandModal({
            isOpen: false,
            brandId: "",
          });
        }
      },
      setText,
      navigate,
      setBrandApiInProgress
    );
  }

  function handleBasicDetailsSubmit(values: typeof basicDetailsForm.values) {
    if (editBrandModal.isOpen) {
      handleEditBrand();
    } else {
      handleAddBrand();
    }
  }

  function handleBasicDetailsError(errors: typeof basicDetailsForm.errors) {}

  return (
    <form
      onSubmit={basicDetailsForm.onSubmit(
        handleBasicDetailsSubmit,
        handleBasicDetailsError
      )}
    >
      <p className="txt-16 mb-20">Basic Details</p>
      <div className="grid grid-cols-2 col-gap-40 row-gap-25">
        <div>
          <p className="txt-12 txtw-5 clr-grey txt-ucase mb-10">logo</p>
          <Dropzone
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={brandLogo}
            setMedia={setBrandLogo}
            mediaValidationText={brandLogoValidationText}
            setMediaValidationText={setBrandLogoValidationText}
            mediaSize={1000000}
          />
        </div>
        <div>
          <p className="txt-12 txtw-5 clr-grey txt-ucase mb-10">brand image</p>
          <Dropzone
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={brandIntro}
            setMedia={setBrandIntro}
            mediaValidationText={brandIntroValidationText}
            setMediaValidationText={setBrandIntroValidationText}
            mediaSize={1000000}
          />
        </div>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="NAME"
          withAsterisk
        >
          <TextInput
            placeholder="Enter Name"
            variant="filled"
            {...basicDetailsForm.getInputProps("name")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="EMAIL"
          withAsterisk
        >
          <TextInput
            placeholder="Enter Email"
            variant="filled"
            {...basicDetailsForm.getInputProps("email")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="OPERATIONAL HOURS"
        >
          <div className="flex align-center">
            <TimeInput
              clearable
              variant="filled"
              format="12"
              {...basicDetailsForm.getInputProps("startTime")}
            />
            <span className="clr-grey ml-10 mr-10">-</span>
            <TimeInput
              clearable
              variant="filled"
              format="12"
              {...basicDetailsForm.getInputProps("endTime")}
            />
          </div>
        </Input.Wrapper>
        <div className="gcol-start-1 gcol-end-3">
          <p className="clr-grey txtw-4 txt-12 txt-ucase">
            description &#40;
            {360 - brandDescription.length} characters left&#41;
          </p>
          <Textarea
            placeholder="Enter Description"
            variant="filled"
            value={brandDescription}
            onChange={(e) => {
              if (
                brandDescription.length < 360 ||
                e.currentTarget.value.length <= brandDescription.length
              ) {
                setBrandDescription(e.currentTarget.value);
              }
            }}
          />
        </div>
      </div>
      <div className={"flex justify-end bd-top pt-10 mt-10"}>
        <Button
          type="filled-primary"
          text={editBrandModal.isOpen ? "Save" : "Add"}
          loading={brandApiInProgress}
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

export default BasicDetails;
