import { ActionIcon } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import { NoOffersSVG, PencilIconSVG, TrashboxIconSVG } from "assets/icons";
import CustomTable from "components/CustomTable/CustomTable";
import {
  useCreateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useGetSubCategoryListQuery,
  useUpdateSubCategoryMutation,
} from "hooks/Admin/subCategoryAPI";
import React, { MouseEvent, useState } from "react";
import styles from "./SubCategory.module.scss";
import SubCategoryEditModal from "./SubCategoryEdit/SubCategoryModal";
import defaultCategory from "assets/images/default_category.png";
import { Button } from "components/Common/Button";
import { EmptyPage } from "components/Common/EmptyPage";
import { currentRoleIsUser } from "utils/helper";
import { DeleteModal } from "components/Common/DeleteModal";
import { useNotification } from "context";

// type SubCategoryAPIOptionsType = {
//   page: number
//   size: string
// }

type Props = {
  categoryId: string;
};

const columnHelper = createColumnHelper<any>();

function SubCategory({ categoryId }: Props) {
  // const [subCategoryAPIOptions, setSubCategoryAPIOptions] = useState<SubCategoryAPIOptionsType>({
  //   page: 1,
  //   size: "10",
  // })

  // useUpdateTokens()

  const { data: subCategoryList } = useGetSubCategoryListQuery({
    // page: subCategoryAPIOptions?.page,
    // size: subCategoryAPIOptions?.size,
    categoryId: categoryId,
  });
  const [createSubCategory] = useCreateSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();
  const { setText } = useNotification();
  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] =
    useState<boolean>(false);
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [toBeDeletedSubCategory, setToBeDeletedSubCategory] =
    useState<any>(null);
  const [isRemoveSubCategoryModalVisible, setRemoveSubCategoryModalVisible] =
    useState<boolean>(false);

  const onEditCategory: any = (data: any) => {
    setShowCreateSubCategoryModal(true);
    setSelectedSubCategory(data);
  };

  // const onClickCreateSubCategory = () => {
  //   setShowCreateSubCategoryModal(true)
  // }

  const onDeleteSubCategory =
    (data: any) => (event: MouseEvent<HTMLElement>) => {
      setRemoveSubCategoryModalVisible(true);
      setToBeDeletedSubCategory(data._id);
    };

  const onClose = () => {
    setShowCreateSubCategoryModal(false);
    setSelectedSubCategory(null);
  };

  async function handleRemoveSubCategory() {
    const response = await deleteSubCategory({ id: toBeDeletedSubCategory });
    if (response?.data?.success) {
      setRemoveSubCategoryModalVisible(false);
      setToBeDeletedSubCategory(null);
      setText("Subcategory Removed");
    }
  }

  const onSave = async (data: any) => {
    try {
      const res = await createSubCategory({
        ...data,
        image: data.image,
        _id: undefined,
      });
      if (res?.data?.success) {
        setText("Subcategory Added");
      }
      if (res.error) console.log(res.error);
      else onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdate = async (data: any) => {
    try {
      const { _id, ...payload } = data;
      const res = await updateSubCategory({
        _id,
        payload: {
          ...payload,
          image: payload.image,
        },
      });
      if (res?.data?.success) {
        setText("Subcategory Updated");
      }
      if (res.error) console.log(res.error);
      else onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    columnHelper.accessor("subCategoryName", {
      header: ({ table }) => <span>Sub Category</span>,
      cell: (info) => {
        const ImageLink = info?.row?.original?.image?.link || defaultCategory;
        return (
          <>
            <div
              className={styles.categoryContainer}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className={styles.categoryImage}
                alt="category-icon"
                src={ImageLink}
                style={{
                  marginRight: "20px",
                  width: 40,
                  height: 40,
                  borderRadius: 99999,
                }}
              />
              <span className="mr-10">{info.getValue()}</span>
            </div>
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action}>
          <ActionIcon onClick={() => onEditCategory(info.row.original)}>
            <PencilIconSVG />
          </ActionIcon>
          <ActionIcon onClick={onDeleteSubCategory(info.row.original)}>
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      ),
      size: 80,
    },
  ];

  const columnVisibility = {
    action: !currentRoleIsUser(),
  };

  return (
    <div>
      <div className={styles.header}>
        <h3 className="mb-20">Sub Category</h3>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 ">
          <div className="flex justify-end">
            <Button
              type="filled-primary"
              text="Add New"
              onClick={(e) => {
                e.stopPropagation();
                setShowCreateSubCategoryModal(true);
                setSelectedSubCategory(null);
                setIsAddModal(true);
              }}
              classNames={{
                root: "pg-subheader-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          </div>
        </div>
        {/* <Button
          text='Create Subcategory'
          onClick={onClickCreateSubCategory}
          type='filled-primary'
        /> */}
      </div>
      {subCategoryList?.data?.length ? (
        <CustomTable
          columns={columns}
          data={subCategoryList?.data}
          className={styles.table}
          columnVisibility={columnVisibility}
        />
      ) : (
        <EmptyPage img={NoOffersSVG} item="SubCategories" />
      )}

      {showCreateSubCategoryModal && (
        <SubCategoryEditModal
          open={showCreateSubCategoryModal}
          categoryId={categoryId}
          onSave={onSave}
          onUpdate={onUpdate}
          onClose={onClose}
          isAddModal={isAddModal}
          selectedSubCategory={selectedSubCategory}
        />
      )}

      <DeleteModal
        isDeleteModalOpen={isRemoveSubCategoryModalVisible}
        closeDeleteModal={() => setRemoveSubCategoryModalVisible(false)}
        data={{
          text: "category",
        }}
        onConfirm={handleRemoveSubCategory}
      />
    </div>
  );
}

export default SubCategory;
