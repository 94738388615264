import { createColumnHelper } from "@tanstack/react-table"
import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import { Link } from "react-router-dom"
import {
  ECommerceCustomerDetailsTableFields,
  MobilityCustomerDetailsTableFields,
} from "types/customerManagement"
import { padDecimals } from "utils/helper"
type CustomerDetailsType =
  | ECommerceCustomerDetailsTableFields
  | MobilityCustomerDetailsTableFields

const columnHelper = createColumnHelper<CustomerDetailsType>()

export const ecommerceColumns = [
  columnHelper.accessor("customerOrderId", {
    header: "Order ID",
    enableSorting: false,
    cell: (info: any) => (
      <Link
        to={`/orders/${info.row.original._id}`}
        className="txt-12 clr-black"
      >
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("sellerName", {
    header: "Seller Name",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("categories", {
    header: "Category",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: (info: any) => (
      <RoleStatusBadge type={info.getValue().toLowerCase()} />
    ),
  }),

  columnHelper.accessor("paymentMethod", {
    header: "Payment",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("totalPrice", {
    header: "Price",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("totalQuantity", {
    header: "Quantity",
    enableSorting: false,
    cell: (info: any) => info.getValue(),
  }),
]

export const mobilityColumns = [
  columnHelper.accessor("customerOrderId", {
    header: "Ride ID",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("driverName", {
    header: "Driver Name",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("vehicleNumber", {
    header: "Vehicle Number",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("vehicle", {
    header: "Vehicle",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("pickupLocation", {
    header: "Pickup Location",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("dropoffLocation", {
    header: "Dropoff Location",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("price", {
    header: "Price",
    cell: (info: any) => "₹" + padDecimals(info.getValue()),
  }),
  columnHelper.accessor("payment", {
    header: "Payment",
    cell: (info: any) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info: any) => (
      <RoleStatusBadge type={info.getValue().toLowerCase()} />
    ),
  }),
]
