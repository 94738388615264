interface searchBarContainerProps {
  item: string[];
  itemsCount: number;
  listingCount: number;
}

const SearchBarContainer = ({
  item,
  itemsCount,
  listingCount,
}: searchBarContainerProps) => {
  const itemText = listingCount === 1 ? item[0] : item[1] || item[0];

  return (
    <div className="search-container flex align-center">
      <p className="mr-10 txt-16 txtw-6 clr-grey">
        {listingCount && listingCount !== 0 ? (
          <>
            Showing {listingCount || 0} {itemText} out of {itemsCount}
          </>
        ) : (
          "No data exists"
        )}
      </p>
    </div>
  );
};

export default SearchBarContainer;
