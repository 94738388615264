import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Box, Menu, NavLink } from "@mantine/core"
import { NAVTABS_LIST } from "constants/index"
import "./NavBar.scss"
import { getStorageItem } from "services/storage"
import { useAdminTypeContext } from "context/adminType"

const NavBar = () => {
  // let adminVisible = false
  const [selectedTab, setSelectedTab] = useState("")
  const [adminVisible, setAdminVisible] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()

  const checkAdmin = () => {
    if (
      getStorageItem("role") === "PROTEAN_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_SUPER_ADMIN"
    ) {
      setAdminVisible(true)
    } else {
      setAdminVisible(false)
    }
  }

  useEffect(() => {
    const page = location.pathname.match(/\w+/gi)
    if (page) {
      setSelectedTab(page[0])
    }
  }, [location])

  useEffect(() => {
    checkAdmin()
  }, [])

  const { adminType } = useAdminTypeContext()

  const updatedNavTabs = NAVTABS_LIST.filter((tab) => {
    if (tab.value !== "orders" && adminType === "mobility") {
      return {
        ...tab,
      }
    } else if (
      tab.value !== "rides" &&
      (adminType === "e-commerce-b2c" || adminType === "e-commerce-b2b")
    ) {
      return tab
    } else {
      return null
    }
  })

  return (
    <Box className="flex">
      {updatedNavTabs.map((tab) => {
        return tab.value === "management" ? (
          <Menu trigger="hover" width={190} key={tab.value}>
            <Menu.Target>
              <div className="flex align-center cursor-ptr pl-10 nav-parent nav-root nav-body pr-12">
                {selectedTab === "campaign" || selectedTab === "tickets" ? (
                  <span className="tab-active-marker"></span>
                ) : null}
                <p
                  className={`txt-14 txtw-6 ${
                    selectedTab === "campaign" || selectedTab === "tickets"
                      ? "tab-label-active"
                      : ""
                  }`}
                >
                  {tab.name}
                </p>
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {/* <Menu.Item
                onClick={(e: any) => {
                  e.stopPropagation()
                  navigate("/campaign-management")
                }}
              >
                Campaign Management
              </Menu.Item> */}
              <Menu.Item
                onClick={(e: any) => {
                  e.stopPropagation()
                  navigate("/tickets-management")
                }}
              >
                Tickets Management
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : tab.value === "admins" ? (
          <NavLink
            style={adminVisible ? { display: "flex" } : { display: "none" }}
            key={tab.value}
            label={
              <div className="flex align-center">
                {selectedTab === tab.value ? (
                  <span className="tab-active-marker"></span>
                ) : null}
                {tab.name}
              </div>
            }
            active={selectedTab === tab.value}
            classNames={{
              root: "nav-root",
              body: "nav-body",
              label: selectedTab === tab.value ? "tab-label-active" : "",
            }}
            component={Link}
            to={`/${tab.value}`}
          />
        ) : (
          <NavLink
            key={tab.value}
            label={
              <div className="flex align-center">
                {selectedTab === tab.value ? (
                  <span className="tab-active-marker"></span>
                ) : null}
                {tab.name}
              </div>
            }
            active={selectedTab === tab.value}
            classNames={{
              root: "nav-root",
              body: "nav-body",
              label: selectedTab === tab.value ? "tab-label-active" : "",
            }}
            component={Link}
            to={`/${tab.value}`}
          />
        )
      })}
    </Box>
  )
}

export default NavBar
