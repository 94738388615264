import { Box } from "@mantine/core"
import { ORDER_STATUS } from "constants/index"
import {
    getStatusBgColor,
    getStatusImgSrc,
    getStatusTxtColor,
} from "utils/helper"
import "./Badge.scss"

interface propsType {
    type: string
}

const OrderStatusBadge = ({ type }: propsType) => {
    return (
        <Box
            sx={{
                backgroundColor: getStatusBgColor(type),
                textAlign: "center",
                width: "110px",
                color: getStatusTxtColor(type),
                padding: "5px 8px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                src={getStatusImgSrc(type)}
                alt={ORDER_STATUS[type as keyof typeof ORDER_STATUS]}
                className="status-img"
            />
            <p className="txt-12 txt-ucase txtw-5 ml-5">
                {ORDER_STATUS[type as keyof typeof ORDER_STATUS]}
            </p>
        </Box>
    )
}

export default OrderStatusBadge
