// src/mocks.js
// 1. Import the library.
import { http, HttpResponse } from "msw"
import { setupWorker } from "msw/browser"
import { faker } from "@faker-js/faker"
import { userAppBaseURL } from "services/url"

const BASE_URL = userAppBaseURL

// 2. Describe network behavior with request handlers.
export const worker = setupWorker(
  http.get(BASE_URL + "/order", () => {
    return HttpResponse.json({
      page: 1,
      size: 10,
      totalRecords: 130,
      records: new Array(10).fill(0).map(() => ({
        totalAmount: faker.finance.amount({ max: 4000, min: 150 }),
        id: "#" + faker.string.numeric(8),
        status: faker.helpers.arrayElement(["Delivered", "Cancelled"]),
        products: new Array(faker.number.int({ min: 1, max: 10 }))
          .fill(0)
          .map(() => faker.commerce.productName()),
        category: new Array(faker.number.int({ min: 1, max: 3 }))
          .fill(0)
          .map(() => faker.commerce.department()),
        payment_type: faker.finance.transactionType(),
      })),
    })
  })
)
