export const constant: any = {
  order: [
    {
      label: "Created At: High-to-Low",
      value: "createdAt-desc",
      key: "desc",
    },
    {
      label: "Created At: Low-to-High",
      value: "createdAt-asc",
      key: "asc",
    },
    {
      label: "Updated At: High-to-Low",
      value: "updatedAt-desc",
      key: "desc",
    },
    {
      label: "Updated At: Low-to-High",
      value: "updatedAt-asc",
      key: "asc",
    },
    {
      label: "Total Amount: High-to-Low",
      value: "totalAmount-desc",
      key: "desc",
    },
    {
      label: "Total Amount: Low-to-High",
      value: "totalAmount-asc",
      key: "asc",
    },
  ],
  customers: [
    {
      label: "Created At: High-to-Low",
      value: "createdAt-desc",
      key: "desc",
    },
    {
      label: "Created At: Low-to-High",
      value: "createdAt-asc",
      key: "asc",
    },
    {
      label: "Updated At: High-to-Low",
      value: "updatedAt-desc",
      key: "desc",
    },
    {
      label: "Updated At: Low-to-High",
      value: "updatedAt-asc",
      key: "asc",
    },
    {
      label: "Total Revenue: High-to-Low",
      value: "totalRevenue-desc",
      key: "desc",
    },
    {
      label: "Total Revenue: Low-to-High",
      value: "totalRevenue-asc",
      key: "asc",
    },
    {
      label: "Total Orders: High-to-Low",
      value: "totalOrders-desc",
      key: "desc",
    },
    {
      label: "Total Orders: Low-to-High",
      value: "totalOrders-asc",
      key: "asc",
    },
  ],
  ticketManagement: [
    {
      label: "Title: High-to-Low",
      value: "title-desc",
      key: "desc",
    },
    {
      label: "Title: Low-to-High",
      value: "title-asc",
      key: "asc",
    },
  ],
  admin: [
    {
      label: "First Name: High-to-Low",
      value: "firstName-desc",
      key: "desc",
    },
    {
      label: "First Name: Low-to-High",
      value: "firstName-asc",
      key: "asc",
    },
    {
      label: "Last Name: High-to-Low",
      value: "lastName-desc",
      key: "desc",
    },
    {
      label: "Last Name: Low-to-High",
      value: "lastName-asc",
      key: "asc",
    },
    {
      label: "Email: High-to-Low",
      value: "email-desc",
      key: "desc",
    },
    {
      label: "Email: Low-to-High",
      value: "email-asc",
      key: "asc",
    },
    {
      label: "Role: High-to-Low",
      value: "role-desc",
      key: "desc",
    },
    {
      label: "Role: Low-to-High",
      value: "role-asc",
      key: "asc",
    },
  ],
  categories: [
    {
      label: "Category Name: A to Z",
      value: "categoryName-asc",
      key: "desc",
    },
    {
      label: "Category Name: Z to A",
      value: "categoryName-desc",

      key: "asc",
    },
    {
      label: "Visible: Active",
      value: "visible-desc",
      key: "desc",
    },
    {
      label: "Visible: Inactive",
      value: "visible-asc",
      key: "asc",
    },
  ],
};
