import { Modal } from "components/Common/Modal";
import BasicDetails from "./BasicDetails";

const AddOfferModal = ({
  isAddBannerModalVisible,
  setAddBannerModalVisible,
  editOfferModal,
  setEditOfferModal,
}: {
  isAddBannerModalVisible: boolean;
  setAddBannerModalVisible: (isAddOfferModalVisible: boolean) => void;
  editOfferModal: any;
  setEditOfferModal: Function;
}) => {
  return (
    <Modal
      opened={isAddBannerModalVisible || editOfferModal.isOpen}
      onClose={() => {
        if (isAddBannerModalVisible) {
          setAddBannerModalVisible(false);
        } else {
          setEditOfferModal({
            isOpen: false,
            offer: "",
          });
        }
      }}
      title="Add a Section"
    >
      <BasicDetails
        editOfferModal={editOfferModal}
        setEditOfferModal={setEditOfferModal}
        setAddBannerModalVisible={setAddBannerModalVisible}
      />
    </Modal>
  );
};

export default AddOfferModal;
