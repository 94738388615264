import { Box, Text } from "@mantine/core"
import { Button } from "components/Common/Button"
import { PencilIconSVG } from "assets/icons/index"
import "components/Common/Card/Card.scss"

const PackagingCard = ({
  packaging,
  setEditPackagingModal,
}: {
  packaging: any
  setEditPackagingModal: Function
}) => {
  return (
    <Box
      sx={{
        borderRadius: "20px",
        background: "#ffffff",
        height: "max-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          borderBottom: "solid thin #EAECF0",
        }}
      >
        <section className="card-info-section">
          <Text size={16} weight={500} mb={8}>
            {packaging.label}
          </Text>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              className="txt-16 txtw-4 txt-cap"
              style={{
                color: "#9B9B9B",
              }}
            >
              base price
            </p>
            <Text size={16} weight={400} color="#9B9B9B">
              ₹{packaging.basePrice}
            </Text>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <p
              className="mr-40 txt-16 txtw-4 txt-cap w-150"
              style={{
                color: "#9B9B9B",
              }}
            >
              free above order of
            </p>
            <Text size={16} weight={400} color="#9B9B9B">
              ₹{packaging.freeOnOrderAmountAbove}
            </Text>
          </Box>
        </section>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "15px",
        }}
      >
        <Button
          leftIcon={<PencilIconSVG />}
          type="default"
          text="Edit"
          onClick={() => {
            setEditPackagingModal({
              isOpen: true,
              packaging: { ...packaging },
            })
          }}
        />
      </Box>
    </Box>
  )
}

export default PackagingCard
