import axios from "axios";
import { getStorageItem } from "services/storage";
import { userAppBaseURL } from "services/url";
import { generateSortUrlSearchParams } from "utils/helper";

export const getAdminList = async (
  page: number,
  size: string,
  sort: String
) => {
  const searchParams = new URLSearchParams();
  searchParams.set("page", String(page));
  searchParams.set("size", String(size));
  if (sort) generateSortUrlSearchParams(sort, searchParams);
  const response = await axios({
    method: "GET",
    url: userAppBaseURL + "/user",
    params: searchParams,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return response.data;
};
export const getAdmin = async (adminId: string) => {
  const response = await axios({
    method: "GET",
    url: `${userAppBaseURL}/user/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return await response.data;
};

export const editAdmin = async (adminId: string, adminData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${userAppBaseURL}/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...adminData,
    },
  });
  return await response.data;
};

export const deleteAdmin = async (adminId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${userAppBaseURL}/user/${adminId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return await response;
};
