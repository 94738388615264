import { Card, Divider, Select } from "@mantine/core";
import { Header } from "components/Common/Header";
import styles from "./TicketManegement.module.scss";
import {
  useGetTicketsCountQuery,
  useLazyGetTicketDetailsQuery,
} from "hooks/Admin/settlementsAPI";
import {
  ClosedIssuesSVG,
  NoOffersSVG,
  OpenIssuesSVG,
  TotalIssuesSVG,
} from "assets/icons";
import { CardDetailType, SettlementTableFields } from "types/ticket";

import { EmptyPage } from "components/Common/EmptyPage";
import CustomTable from "components/CustomTable/CustomTable";
import { getDisplayDate } from "utils/helper";
import { createColumnHelper, Table } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import SettlementDetailModal from "./SettlementDetailModal/SettlementDetailModal";
import { useAdminTypeContext } from "context/adminType";
import { downloadCsv } from "services/apis/admin/media";
import { Button } from "components/Common/Button";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { getStorageItem } from "services/storage";
import { userAppBaseURL } from "services/url";
import { useNotification } from "context";

type SettlementAPIOptionsType = {
  page: number;
  size: string;
  sort: string;
};

const Settlements = () => {
  const [settlementAPIOptions, setSettlementAPIOptions] =
    useState<SettlementAPIOptionsType>({
      page: 1,
      size: "10",
      sort: "",
    });

  const { data: ticketsCount, refetch } = useGetTicketsCountQuery({
    page: settlementAPIOptions?.page,
    size: settlementAPIOptions?.size,
    sort: settlementAPIOptions?.sort,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<
    SettlementTableFields[] | null
  >(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const settlementStatusEnum = ["Pending", "Debit", "Credit", "Settle"];
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null); // state to hold selected status
  const [selectedIds, setSelectedIds] = useState<string[]>([]); // state for selected IDs
  const { setText } = useNotification();
  const isUser = getStorageItem("role") === "USER";

  const [triggerGetTicketDetails, { data: complaintDetails }] =
    useLazyGetTicketDetailsQuery();

  useEffect(() => {
    if (selectedId) triggerGetTicketDetails({ _id: selectedId });
  }, [selectedId, triggerGetTicketDetails]);

  const { adminType } = useAdminTypeContext();

  const { settltements } = complaintDetails?.data ?? {};

  const onEditCategory = (id: string) => {
    setShowModal(true);
    setSelectedId(id);
  };

  const onClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setSelectedData(settltements);
  }, [selectedId, settltements]);

  const {
    sumCompletedOrderAmount,
    sumTodayOrderAmount,
    sumPendingOrderAmount,
  } = ticketsCount?.data || {};

  const cardDetails: CardDetailType[] | null = ticketsCount?.data
    ? [
        {
          title: "Total Completed Amount",
          count: Math.floor(sumCompletedOrderAmount) || 0,
          icon: TotalIssuesSVG,
          issueStatus: "all",
        },
        {
          title: "Today Order Amount",
          count: Math.floor(sumTodayOrderAmount) || 0,
          icon: OpenIssuesSVG,
          issueStatus: "open",
        },
        {
          title: "Pending Order Amount",
          count: Math.floor(sumPendingOrderAmount) || 0,
          icon: ClosedIssuesSVG,
          issueStatus: "closed",
        },
      ]
    : null;

  const tableData: SettlementTableFields[] | undefined = (
    ticketsCount?.data?.settlements ?? []
  )?.map(
    ({
      settlement_id,
      ondc_network_transaction_id,
      buyer_commission,
      setlement_status,
      settlement_amount,
      timestamp,
      total_amount,
      order_status,
      seller_location,
      shipped_date,
      delivered_date,
    }: SettlementTableFields) => ({
      settlement_id,
      ondc_network_transaction_id,
      buyer_commission,
      setlement_status,
      settlement_amount,
      timestamp,
      total_amount,
      order_status,
      seller_location,
      shipped_date,
      delivered_date,
    })
  );

  const columnHelper = createColumnHelper<SettlementTableFields>();

  const columns = [
    {
      id: "selection",
      header: ({ table }: { table: Table<SettlementTableFields> }) => (
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedIds(
                table
                  .getRowModel()
                  .rows.map((row) => row.original.settlement_id)
              );
            } else {
              setSelectedIds([]);
            }
          }}
          disabled={isUser}
          checked={selectedIds.length === table.getRowModel().rows.length} // Check if all checkboxes are selected
        />
      ),
      cell: (info: { row: { original: { settlement_id: string } } }) => (
        <input
          type="checkbox"
          checked={selectedIds.includes(info.row.original.settlement_id)}
          onChange={(e) => {
            const id = info.row.original.settlement_id;
            setSelectedIds((prev) =>
              e.target.checked ? [...prev, id] : prev.filter((i) => i !== id)
            );
          }}
        />
      ),
    },
    columnHelper.accessor("settlement_id", {
      header: "Settlement ID",
      enableSorting: false,
      cell: (info) => (
        <span onClick={() => onEditCategory(info.getValue() as string)}>
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("ondc_network_transaction_id", {
      header: "Order ID",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("buyer_commission", {
      header: "Buyer Commission",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("setlement_status", {
      header: "Settlement Status",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("settlement_amount", {
      header: "Settlement Amount",
      enableSorting: false,
      cell: (info) => <span>₹ {info.getValue()}</span>,
    }),
    columnHelper.accessor("timestamp", {
      header: "Created On",
      enableSorting: false,
      cell: (info) => <span>{getDisplayDate(info.getValue() as string)}</span>,
    }),
    columnHelper.accessor("seller_location", {
      header: "Seller Location",
      enableSorting: false,
      cell: (info) => {
        const { locality, city } = info.getValue() as {
          locality: string;
          city: string;
        };
        return <span>{`${locality}, ${city}`}</span>;
      },
    }),
    // columnHelper.accessor("shipped_date", {
    //   header: "Shipped date",
    //   enableSorting: false,
    //   cell: (info) => <span>{getDisplayDate(info.getValue() as string)}</span>,
    // }),
    columnHelper.accessor("delivered_date", {
      header: "Delivered date",
      enableSorting: false,
      cell: (info) => {
        const deliveredDate = info.getValue() as string;
        return (
          <span>{deliveredDate ? getDisplayDate(deliveredDate) : "-"}</span>
        );
      },
    }),
    columnHelper.accessor("total_amount", {
      header: "Total Amount",
      enableSorting: false,
      cell: (info) => <span>₹ {info.getValue()}</span>,
    }),
    columnHelper.accessor("order_status", {
      header: "Order Status",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    // columnHelper.accessor("settlement_id", {
    //   header: "Action",
    //   enableSorting: false,
    //   cell: (info) => (
    //        <div className={styles.action} onClick={() => onEditCategory(info.getValue() as string)}>
    //        <Link
    //          to="#"
    //        >
    //          <ViewStatusSVG />
    //        </Link>
    //      </div>
    //   ),
    // }),
  ];

  const handleDownloadCSV = async () => {
    downloadCsv(
      `/rsp/settlements/export/csv`,
      `settlements_${adminType === "e-commerce-b2c" ? "b2c" : "b2b"}.csv`
    );
  };

  const onPageChange = (value: number) =>
    setSettlementAPIOptions((prev) => ({ ...prev, page: value }));

  const onRecordsSizeChange = (value: string) =>
    setSettlementAPIOptions((prev) => ({ ...prev, size: value }));

  const handleUpdateStatus = async () => {
    if (!selectedStatus) {
      setText("Please select a status before updating.");
      return;
    }

    try {
      const response = await fetch(`${userAppBaseURL}/rsp/settlements`, {
        method: "PATCH",
        headers: {
          Authorization: `${getStorageItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          settlementIds: selectedIds,
          status: selectedStatus,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        setText("Settlement Status Updated ");
        setSelectedIds([]);
        refetch();
      } else {
        console.error("Update failed", result);
      }
    } catch (error) {
      console.error("Error updating settlements", error);
    }
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ position: "relative" }}
      >
        <div className="flex mr-80 justify-stretch align-center">
          <div className="section-header" style={{ marginBottom: "30px" }}>
            Settlements
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button
              type="filled-primary"
              text="Download CSV"
              onClick={handleDownloadCSV}
              classNames={{
                root: "pg-subheader-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          </div>
        </div>

        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {cardDetails ? (
          <>
            <div className="flex justify-stretch align-center mb-30 mx-120 mt-10 flex-wrap gap-20">
              {cardDetails.map((card: CardDetailType) => (
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  key={card.title}
                  className="flex-1"
                >
                  <Card.Section className="flex flex-col align-start p-24">
                    <img
                      src={card.icon}
                      className="img-contain w-48 h-48 p-10 bd-gray brd-10"
                      alt="icon"
                    />

                    <p className="pt-20 txt-14 txtw-5 clr-grey">{card.title}</p>
                    <div className="flex align-end justify-stretch w-full">
                      <p className="txt-36 txtw-6">{"₹" + card.count}</p>
                    </div>
                  </Card.Section>
                </Card>
              ))}
            </div>

            <div className="flex mr-80 justify-stretch align-center">
              <div className="section-header" style={{ marginBottom: "20px" }}>
                <Select
                  label="Select Status"
                  placeholder="Select Status"
                  data={settlementStatusEnum}
                  value={selectedStatus}
                  onChange={(value) => setSelectedStatus(value)}
                  disabled={!selectedIds.length}
                  style={{ marginLeft: "10px" }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  type="filled-primary"
                  text="Update Status"
                  onClick={handleUpdateStatus}
                  disabled={!selectedIds.length}
                  classNames={{
                    root: "pg-subheader-btn brd-10",
                    label: "txt-14 txtw-5",
                  }}
                />
              </div>
            </div>
            <div>
              {!!tableData?.length ? (
                <>
                  <section
                    className="min-h-70 bd-gray flex-col bg-white mt-5 mb-5 mx-80 mb-40 "
                    style={{ overflowX: "scroll" }}
                  >
                    <CustomTable
                      columns={columns}
                      data={tableData}
                      className={styles.table}
                      // rowClickHandler={(row) =>
                      //   onEditCategory(row.settlement_id)
                      // }
                    />
                  </section>
                </>
              ) : (
                <EmptyPage img={NoOffersSVG} item="Settlement Data found" />
              )}
            </div>
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Tickets" />
        )}

        <PaginationContainer
          page={settlementAPIOptions.page}
          setPage={onPageChange}
          size={settlementAPIOptions.size}
          setSize={onRecordsSizeChange}
          totalEntries={ticketsCount?.data?.count ?? 0}
        />
      </main>
      {showModal && (
        <SettlementDetailModal
          editModalVisible={showModal}
          data={selectedData}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default Settlements;
