import { useEffect, useContext } from "react";
import { AppRouter } from "./router";
import axios from "axios";
import { AppContext } from "context/userContext";
import { useNotification } from "context";

const App = () => {
  const { setSpinner } = useContext(AppContext);
  const { setText } = useNotification();

  const getStorageItem = (key: string) => {
    return localStorage.getItem(key);
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_BRAND
      ? process.env.REACT_APP_BRAND + " ADMIN"
      : "Admin Panel";
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.headers) {
          config.headers = {};
        }
        if (!config?.url?.endsWith("media")) setSpinner(true);
        const token = getStorageItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        setSpinner(false);
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setSpinner(false);
        return response;
      },
      (error) => {
        setSpinner(false);
        if (error.response && error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response && error.response.status === 403) {
          setText(error?.response?.data?.error ??"Something went wrong. Please try again.", "error");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [setSpinner]);

  return <AppRouter />;
};

export default App;
