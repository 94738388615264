import { Modal } from "components/Common/Modal";
import { Button } from "components/Common/Button";
import { Input, Switch, TextInput } from "@mantine/core";
import { ImageUpload } from "components/Common/ImageUpload";
import { useEffect, useRef } from "react";
import { useForm } from "@mantine/form";

type MediaType = {
  _id: string;
  link: string;
};

const CategoryEditModal = ({
  editModalVisible,
  data,
  onSave,
  isAddModal,
  onClose,
}: {
  data: any;
  editModalVisible: any;
  isAddModal: any;
  onSave: any;
  onClose: any;
}) => {
  const categoryDetailsForm = useForm<{
    categoryName: string;
    domain: string;
    displayName: string;
    visible: boolean;
    media: MediaType | null;
  }>({
    validateInputOnChange: true,
    initialValues: {
      domain: "",
      categoryName: "",
      displayName: "",
      visible: false,
      media: null,
    },
    validate: {
      domain: (value) => (!value?.length ? "Domain is required" : null),
      categoryName: (value) =>
        !value?.length ? "Category Name is required" : null,
      displayName: (value) =>
        !value?.length ? "Display Name is required" : null,
      media: (value) => (!value?.link ? "Media is required" : null),
    },
  });

  const prevDataRef = useRef<any>();

  useEffect(() => {
    if (data && data !== prevDataRef.current) {
      categoryDetailsForm.setValues({
        categoryName: data?.categoryName || "",
        domain: data?.domain || "",
        displayName: data?.displayName || "",
        visible: data?.visible || false,
        media: data?.image || null,
      });
      prevDataRef.current = data;
    }
  }, [data, categoryDetailsForm]);

  const handleSubmit = (values: any) => {
    if (isAddModal) {
      onSave({
        categoryName: values.categoryName,
        domain: values.domain,
        displayName: values.displayName,
        visible: values.visible,
        media: values.media?._id,
      });
    } else {
      onSave({
        _id: data._id,
        displayName: values.displayName,
        visible: values.visible,
        media: values.media?._id,
      });
    }
  };

  const onCloseModal = () => {
    onClose();
    categoryDetailsForm.reset();
  };

  return (
    <Modal
      opened={editModalVisible}
      onClose={onCloseModal}
      title={isAddModal ? "Add Category" : "Edit Category"}
    >
      <form
        className="pt-20 pl-20 pr-20 w-500"
        onSubmit={categoryDetailsForm.onSubmit(handleSubmit)}
      >
        <div className="flex justify-center align-center flex-col">
          <ImageUpload
            mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
            media={categoryDetailsForm.values.media?.link}
            setMedia={(img) => {
              categoryDetailsForm.setFieldValue("media", img[0]);
            }}
            mediaValidationText={
              (categoryDetailsForm?.errors?.media as string) || undefined
            }
            setMediaValidationText={(err) =>
              categoryDetailsForm.setFieldError("media", err)
            }
            mediaSize={2000000}
          />
        </div>

        <TextInput
          label="ONDC ID (Domain)"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          variant="filled"
          readOnly={!isAddModal}
          {...categoryDetailsForm.getInputProps("domain")}
        />
        <TextInput
          label="CATEGORY NAME"
          classNames={{
            wrapper: "clr-grey txt-12 txtw-4 pb-10",
            label: "clr-grey txt-12 txtw-4",
          }}
          className="cursor-default"
          placeholder="Enter Category"
          variant="filled"
          readOnly={!isAddModal}
          {...categoryDetailsForm.getInputProps("categoryName")}
        />

        <div className="w-full flex justify-stretch">
          <TextInput
            label="DISPLAY NAME"
            classNames={{
              label: "clr-grey txt-12 txtw-4 pb-10",
              wrapper: "clr-grey txt-12 txtw-4",
            }}
            placeholder="Enter Display Name"
            variant="filled"
            autoFocus={true}
            {...categoryDetailsForm.getInputProps("displayName")}
          />
        </div>
        <div className="w-full flex justify-stretch mt-10">
          <Input.Wrapper
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="VISIBLE"
          >
            <Switch
              color="primary"
              classNames={{ track: "cursor-ptr" }}
              {...categoryDetailsForm.getInputProps("visible", {
                type: "checkbox",
              })}
            />
          </Input.Wrapper>
        </div>

        <div className="flex justify-end mt-20 pt-10">
          <Button type="filled-primary" text="Save" onClick={() => {}} />
        </div>
      </form>
    </Modal>
  );
};

export default CategoryEditModal;
