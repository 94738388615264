import { createContext, useContext, useState } from "react";

const AppContext = createContext<any>("defaultValue");

const AppProvider = ({ children }: any) => {
  const [spinner, setSpinner] = useState(false);

  return (
    <AppContext.Provider
      value={{
        spinner,
        setSpinner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
const usePostContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, usePostContext };
