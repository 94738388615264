export const constant: any = {
  order: [{ label: "Status", value: "status" }],
  customers: [
    { label: "Status", value: "status" }
  ],
  ticketManagement: [
    {
      label: "Issue Status",
      value: "issueStatus",
    },
  ],
}
