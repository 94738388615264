import { NoOffersSVG } from "assets/icons"
import { EmptyPage } from "components/Common/EmptyPage"
import { PaginationContainer } from "components/Common/PaginationContainer"
import CustomTable from "components/CustomTable/CustomTable"
import { CustomerAPIOptionsType } from "types/customerManagement"
import styles from "pages/CustomerManagement/CustomerManagement.module.scss"

type Props = {
  columns: any
  adminType: string
  tableData: any
  customersAPIOptions: CustomerAPIOptionsType
  onPageChange: (value: number) => void
  onRecordsSizeChange: (value: string) => void
  totalEntries: number
}

export default function CustomerTable({
  columns,
  adminType,
  tableData,
  customersAPIOptions,
  onPageChange,
  onRecordsSizeChange,
  totalEntries,
}: Props) {
  return !!tableData?.length ? (
    <>
      <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80"  style={{ overflowX: "scroll" }}>
        <CustomTable
          columns={columns}
          data={tableData}
          className={
            styles[adminType === "mobility" ? "mobility-table" : "ecom-table"]
          }
        />
      </section>
      <PaginationContainer
        page={customersAPIOptions.page}
        setPage={onPageChange}
        size={String(customersAPIOptions.size)}
        setSize={onRecordsSizeChange}
        totalEntries={totalEntries}
      />
    </>
  ) : (
    <EmptyPage img={NoOffersSVG} item="Customers" />
  )
}
