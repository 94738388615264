import { createContext, useContext, useState } from "react"
import { brandInfoType } from "types/brand"

const BrandContext = createContext<any>({})

export const BrandProvider = ({ children }: { children: any }) => {
    const [brand, setBrand] = useState<brandInfoType>()
    const [editBrandModal, setEditBrandModal] = useState<{
        isOpen: boolean
        brandId: string
    }>({
        isOpen: false,
        brandId: "",
    })

    return (
        <BrandContext.Provider
            value={{
                brand,
                setBrand,
                editBrandModal,
                setEditBrandModal,
            }}
        >
            {children}
        </BrandContext.Provider>
    )
}

export const useBrand = () => useContext(BrandContext)
