import { Pagination, Select } from "@mantine/core";
import { useEffect, useState } from "react";

interface paginationType {
  leftMargin?: boolean;
  size: string;
  page: number;
  setPage: (page: number) => void;
  setSize: Function;
  totalEntries: number;
}

const PaginationContainer = ({
  leftMargin,
  size,
  setSize,
  page,
  setPage,
  totalEntries,
}: paginationType) => {
  const [total, setTotal] = useState(1);

  useEffect(() => {
    const newtotal = totalEntries / Number(size);

    if (newtotal <= 1) {
      setTotal(1);
      setPage(1);
    } else {
      const totalPages =
        newtotal % 1 === 0 ? newtotal : Math.floor(newtotal) + 1;
      setTotal(totalPages);
      page > totalPages ? setPage(totalPages) : setPage(page);
    }
    // setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, totalEntries]);

  return (
    <section
      className={`flex justify-stretch ${
        leftMargin ? "ml-20" : "ml-80"
      }  mr-80 mt-20 mb-10`}
    >
      <div>
        <Select
          value={size}
          onChange={(val: string) => setSize(val)}
          data={[
            { value: "5", label: "5" },
            { value: "10", label: "10" },
            { value: "15", label: "15" },
            { value: "20", label: "20" },
            { value: "25", label: "25" },
          ]}
          classNames={{
            root: "w-65",
          }}
        />
        <p className="txt-12 txtw-5 clr-grey mt-5">Records per page</p>
      </div>
      <div>
        <Pagination
          total={total}
          page={page}
          onChange={setPage}
          classNames={{
            item: "pagination-item txt-14",
          }}
        />
        <div className="flex justify-end">
          <p className="txt-12 txtw-5 clr-grey mt-5">
            {/* {`Showing ${page * Number(size) - (Number(size) - 1)} - ${
              page * Number(size)
            } entries`} */}
            {`Showing ${Math.min(
              (page - 1) * Number(size) + 1,
              totalEntries
            )} - ${Math.min(page * Number(size), totalEntries)} entries`}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PaginationContainer;
