import { Modal } from "components/Common/Modal"
import BasicDetails from "./BasicDetails"

const AddAdminModal = ({
    isAddAdminModalVisible,
    setAddAdminModalVisible,
    editAdminModal,
    setEditAdminModal,
}: {
    isAddAdminModalVisible: boolean
    setAddAdminModalVisible: (isAddAdminModalVisible: boolean) => void
    editAdminModal: any
    setEditAdminModal: Function
}) => {
    return (
        <Modal
            opened={isAddAdminModalVisible || editAdminModal.isOpen}
            onClose={() => {
                if (isAddAdminModalVisible) {
                    setAddAdminModalVisible(false)
                } else {
                    setEditAdminModal({
                        isOpen: false,
                        adminId: "",
                    })
                }
            }}
            title="add an admin"
        >
            <BasicDetails
                editAdminModal={editAdminModal}
                setEditAdminModal={setEditAdminModal}
                setAddAdminModalVisible={setAddAdminModalVisible}
            />
        </Modal>
    )
}

export default AddAdminModal
