import axios from "axios"
import { getStorageItem } from "services/storage"
import { adminBaseUrl } from "services/url"

const adminsUrl = `${adminBaseUrl}/admins`

// export const loginUser = async (email: string) => {
//     const response = await axios.post(`${adminsUrl}/login`, {
//         email,
//     })
//     return await response.data
// }

// export const verifyOtp = async (otp: string, email: string) => {
//     const response = await axios.post(`${adminsUrl}/verifyOtp`, {
//         otp,
//         email,
//     })
//     return await response.data
// }

export const logoutUser = async () => {
  const response = await axios({
    method: "POST",
    url: `${adminsUrl}/logout`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getToken = async () => {
  const token = getStorageItem("bsRefreshToken")
  const response = await axios({
    method: "POST",
    url: `${adminsUrl}/refresh`,
    headers: {
      Authorization: `  ${token}`,
    },
  })
  return await response.data
}
