import axios from "axios";
import { userAppBaseURL } from "services/url";
import { getStorageItem } from "services/storage";
const adminType = getStorageItem("adminType");

export const getSectionList = async (page: number = 1, size: string = "10") => {
  const searchParams = new URLSearchParams();
  searchParams.set("page", String(page));
  searchParams.set("size", String(size));
  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }
  const response = await axios({
    method: "GET",
    url: userAppBaseURL + "/section",
    params: searchParams,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return response.data;
};
export const getItemList = async (params: {
  "search-text"?: string;
  "sub-category-id"?: string | string[];
  "price-range"?: string;
}) => {
  const searchParams = new URLSearchParams();
  searchParams.set("search-text", String(params["search-text"]));
  searchParams.set("sub-category-id", String(params["sub-category-id"]));
  searchParams.set("price-range", String(params["price-range"]));
  searchParams.set("limit", "200");

  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }

  const queryString = searchParams.toString();

  const response = await axios({
    method: "GET",
    url: `${userAppBaseURL}/section/items?${queryString}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });

  return response.data;
};
export const getProvidersList = async (params: {
  GPS?: string;
  radius?: string | string[];
  category?: string;
  subCategory?: string | string[];
  offers?: boolean;
}) => {
  const searchParams = new URLSearchParams();
  if (params["GPS"]) {
    searchParams.set("area-code", String(params["GPS"]));
  }
  if (params["radius"]) {
    searchParams.set("radius", String(params["radius"]));
  }
  searchParams.set("category-id", String(params["category"]));
  searchParams.set("sub-category-id", String(params["subCategory"]));
  searchParams.set("offers", String(params["offers"]));

  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }

  const queryString = searchParams.toString();
  const response = await axios({
    method: "GET",
    url: `${userAppBaseURL}/section/providers?${queryString}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return response.data;
};

export const getSectionDetails = async (sectionId: string) => {
  const searchParams = new URLSearchParams();
  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }
  if (sectionId) {
    const response = await axios({
      method: "GET",
      url: `${userAppBaseURL}/section/${sectionId}`,
      params: searchParams,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    });
    return await response.data;
  }
};

export const postSection = async (sectionData: any) => {
  const response = await axios({
    method: "POST",
    url: `${userAppBaseURL}/section`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...sectionData,
    },
  });
  return await response.data;
};

export const patchSection = async (sectionId: string, offerData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${userAppBaseURL}/section/${sectionId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...offerData,
    },
  });
  return await response.data;
};

export const deleteSection = async (sectionId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${userAppBaseURL}/section/${sectionId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return await response;
};
