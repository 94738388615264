import axios from "axios"
import { getStorageItem } from "services/storage"
import { adminBaseUrl } from "services/url"

const deliveryUrl = `${adminBaseUrl}/charges/delivery`

export const getAllDeliveryCharges = async () => {
  const response = await axios({
    method: "GET",
    url: `${deliveryUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const postDelivery = async (deliveryData: any) => {
  const response = await axios({
    method: "POST",
    url: `${deliveryUrl}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...deliveryData,
    },
  })
  return await response.data
}

export const putDelivery = async (deliveryId: string, deliveryData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${deliveryUrl}/${deliveryId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...deliveryData,
    },
  })
  return await response.data
}
