export const groceries = [
  "Pet Care",
  "Stationery",
  "Detergents and Dishwash",
  "Dairy and Cheese",
  "Snacks, Dry Fruits, Nuts",
  "Pasta, Soup and Noodles",
  "Cereals and Breakfast",
  "Sauces, Spreads and Dips",
  "Chocolates and Biscuits",
  "Cooking and Baking Needs",
  "Tinned and Processed Food",
  "Atta, Flours and Sooji",
  "Rice and Rice Products",
  "Dals and Pulses",
  "Salt, Sugar and Jaggery",
  "Energy and Soft Drinks",
  "Water",
  "Tea and Coffee",
  "Fruit Juices and Fruit Drinks",
  "Snacks and Namkeen",
  "Ready to Cook and Eat",
  "Pickles and Chutney",
  "Indian Sweets",
  "Frozen Vegetables",
  "Frozen Snacks",
  "Gift Voucher",
  "Gourmet & World Foods",
  "Foodgrains",
  "Beverages",
  "Beauty & Hygiene",
  "Kitchen Accessories",
  "Baby Care",
  "Snacks & Branded Foods",
];

export const fashion = [
  "Shirts",
  "T Shirts",
  "Sweatshirts",
  "Kurtas & Kurta Sets",
  "Jackets & Coats",
  "Sweaters",
  "Stoles and Scarves",
  "Mufflers",
  "Suits",
  "Sherwanis",
  "Track Shirts",
  "Track Suits",
  "Unstitched Fabrics",
  "Dresses",
  "Tops",
  "Trousers",
  "Capris",
  "Coordinates",
  "Playsuits",
  "Jumpsuits",
  "Shrugs & Blouses",
  "Blazers & Waistcoats",
  "Tights, Leggings & Jeggings",
  "Track Pants",
  "Jeans",
  "Shorts",
  "Joggers",
  "Dhotis & Dhoti Pants",
  "Churidars",
  "Salwars",
  "Dungarees & Jumpsuits",
  "Skirts",
  "Clothing Sets",
  "Belts",
  "Caps & Hats",
  "Kurtis, Tunics",
  "Sarees",
  "Ethnic Wear",
  "Palazzos",
  "Dress Materials",
  "Lehenga Cholis",
  "Dupattas & Shawls",
  "Burqas & Hijabs",
  "Blouses",
  "Blouse Pieces",
  "Briefs",
  "Boxers",
  "Vests",
  "Robes",
  "Night Suits",
  "Thermal Wear",
  "Swim Bottoms",
  "Swimwear",
  "Bra",
  "Shapewear",
  "Sleepwear & Loungewear",
  "Camisoles",
  "Lingerie Sets & Accessories",
  "Bath Robes",
  "Towels",
  "Pyjamas",
  "Party Wear",
  "Innerwear & Sleepwear",
  "Nightwear & Loungewear",
  "Watches",
  "Gloves",
  "Socks",
  "Stockings",
  "Laces",
  "Soles & Charms",
  "Shoe Racks & Organisers",
  "Shoe Care - Accessories",
  "Flip-Flops & Flats",
  "Sandals & Floaters",
  "Backpacks",
  "Handbags",
  "Trolley, Luggage & Suitcases",
  "Formal Shoes",
  "Casual Shoes",
  "Sports Shoes",
  "Outdoor Shoes",
  "Work & Safety Shoes",
  "Ethnic Shoes",
  "Boots",
  "Heels",
  "Contact Lenses",
  "Eye Glasses",
  "Eye Glass Frames",
  "Sunglasses",
  "Contact Lens Cases",
  "Contact Lens Solutions",
  "Contact Lens Tweezers",
  "Eyeglasses Pouches & Cases",
  "Microfiber Wipes",
  "Eyewear Slings",
  "Bracelets",
  "Chains",
  "Mangalsutra",
  "Anklets",
  "Bangles & Bracelets",
  "Necklaces",
  "Earrings",
  "Jewellery Sets",
  "Nosepins & Noserings",
  "Pendants",
  "Rings",
  "Toe Rings",
  "Gold Coins",
  "Brooch",
  "Pouch",
  "Keychain",
];

export const bpc = [
  "Fragrance",
  "Bath Soaps and Gels",
  "Hair Oils, Care, and Styling",
  "Shampoos and Conditioners",
  "Shaving and Grooming",
  "Beard Care and Tools",
  "Grooming Tools and Accessories",
  "Makeup - Nail Care",
  "Makeup - Eyes",
  "Makeup - Face",
  "Makeup - Lips",
  "Makeup - Body",
  "Makeup - Remover",
  "Makeup - Sets and Kits",
  "Makeup - Tools and Brushes",
  "Makeup - Kits and Combos",
  "Skin Care - Face Cleansers",
  "Skin Care - Hand and Feet",
  "Body Care - Cleansers",
  "Body Care - Moisturizers",
  "Body Care - Loofah and Other Tools",
  "Body Care - Bath Salt and Additives",
  "Hair Care - Shampoo, Oils, Conditioners",
  "Skin Care - Lotions, Moisturisers, and Creams",
  "Skin Care - Oils and Serums",
  "Gift Voucher",
  "Trimmer",
  "Shaver",
  "Epilator",
  "Hair Straightener",
  "Hair Dryer",
  "Hair Curler",
  "Hair Crimper",
  "Bath & Body",
  "Feminine Care",
  "Hair Care",
  "Make Up",
  "Men's Grooming",
  "Oral Care",
  "Skin Care",
  "Maternity Care",
  "Nursing & Feeding",
  "Sexual Wellness & Sensuality",
  "Tools & Accessories",
];

export const electronics = [
  "Mobile Phone",
  "Smart Watch",
  "Headset",
  "Laptop",
  "Desktop",
  "Tablet",
  "Keyboard",
  "Monitor",
  "Mouse",
  "Power Bank",
  "Earphone",
  "True Wireless Stereo (TWS)",
  "Adapter",
  "Cable",
  "Extension Cord",
  "Audio Accessories",
  "Home Audio",
  "Microphone",
  "Speaker",
  "Vehicle Audio",
  "Camcorder",
  "Camera",
  "Camera Bag",
  "Batteries",
  "Charger",
  "Camera Lens",
  "Photo Printer",
  "Tripod",
  "Camera Accessories",
  "UPS",
  "Networking Device",
  "Printer",
  "Printer Accessories",
  "Storage Drive",
  "Pen Drive",
  "Memory Card",
  "Computer Component",
  "Cooling Pad",
  "Docking Station",
  "Keyboard Guard",
  "Laptop Skin",
  "Laptop Stand",
  "Mousepad",
  "Laptop Bag",
  "Screen Protector",
  "Computer Accessories",
  "Computer Software",
  "Ebook Reader",
  "Tablet Accessories",
  "Gaming Controller",
  "Gaming Chair",
  "Gaming Accessories",
  "Gaming Console",
  "Video Games",
  "Mobile Cover",
  "Mobile Mount",
  "Mobile Screen Guard",
  "Selfie Stick",
  "Mobile Skin Sticker",
  "Biometrics",
  "Home Alarm",
  "Home Automation",
  "Smart Switch",
  "Smart Lighting",
  "Home Safe",
  "Intercom",
  "Sensor",
  "Smart TV",
  "Standard TV",
  "TV Mount",
  "Remote",
  "Streaming Device",
  "TV Accessories",
  "Virtual Reality Headset",
  "3D Glasses",
  "3D Modulator",
  "Projector",
  "Projector Screen",
  "Projector Mount",
  "Projector Accessories",
  "TV Part",
  "TV Remote",
  "Set Top Box",
  "TV Stand",
  "Video Player",
  "Digital Photo Frame",
  "Home Theatre Projector",
  "Video Player Accessories",
  "Smart Band",
  "Smart Glasses",
  "Watch Strap Band",
  "Wearable Accessories",
  "Smart Ring",
];

export const homeAndKitchen = [
  "Home Decor",
  "Furniture",
  "Home Furnishing - Bedding and Linen",
  "Cleaning Supplies",
  "Bins and Bathroom",
  "Car and Shoe Care",
  "Disposables and Garbage Bags",
  "Fresheners and Repellents",
  "Mops, Brushes and Scrubs",
  "Party and Festive Needs",
  "Flowers",
  "Pooja Needs",
  "Paint and Wallpapers",
  "Hardware, Tools and Home Safety",
  "Electricals",
  "Bathroom and Kitchen fixtures",
  "Garden & Outdoor",
  "Sports and Fitness Equipment",
  "Cookware",
  "Serveware",
  "Kitchen Storage and Containers",
  "Kitchen Tools",
  "Closet/Laundry/Shoe Organization",
  "Toys and Games",
  "Stationery",
  "Gift Voucher",
  "Home Furnishings",
  "Garden and Outdoor Products",
  "Home Improvement",
  "Cookware and Dining",
  "Storage and Organisation",
];

export const appliances = [
  "Air Purifier",
  "Dehumidifier",
  "Humidifier",
  "Air Cleaner Accessories",
  "Air Conditioner",
  "Air Conditioner Accessories",
  "Air Cooler",
  "Trimmer",
  "Shaver",
  "Epilator",
  "Hair Straightener",
  "Hair Dryer",
  "Hair Curler",
  "Hair Crimper",
  "Smart TV",
  "Standard TV",
  "TV Mount",
  "Remote",
  "Streaming Device",
  "TV Accessories",
  "Home Theatre Projector",
  "TV Part",
  "TV Remote",
  "Set Top Box",
  "TV Stand",
  "Electric Brush",
  "Electric Iron",
  "Electric Sewing Machine",
  "Water Heater",
  "Heater Cables",
  "Air Heater",
  "Coffee Maker",
  "Beverage Maker",
  "Roti Maker",
  "Induction Cooktop",
  "Sandwich Maker",
  "Electric Cooker",
  "Electric Kettle",
  "Microwave Oven",
  "OTG",
  "Toaster",
  "Electric Air Fryer",
  "Cooking Appliance Accessories",
  "Coffee Grinder",
  "Food Processor",
  "Pasta Maker",
  "Food Processor Accessories",
  "Blender",
  "Juicer",
  "Mixer Grinder",
  "Wet Grinder",
  "Dishwasher",
  "Dishwasher Accessories",
  "Electric Chimney",
  "Kitchen Accessories",
  "Freezer",
  "Refrigerator",
  "Refrigerator Accessories",
  "Vacuum Cleaner",
  "Vacuum Cleaner Parts and Accessories",
  "Washing Machine",
  "Washing Machine Accessories",
  "Water Purifier",
  "Water Cooler",
  "Water Dispenser",
  "Water Purifier Service Kit",
  "Water Purifier Filter",
  "Water Purifier Candle",
  "Water Purifier Pipe",
  "Water Purifier Accessories",
  "Water Cooler Accessories",
  "Inverter",
  "Inverter Batteries",
  "Battery tray",
  "Voltage Stabilizer",
];

export const healthAndWellness = [
  "Pain Relief",
  "Nutrition and Fitness Supplements",
  "Speciality Care",
  "Covid Essentials",
  "Diabetes Control",
  "Healthcare & Fitness Devices",
  "Ayurvedic",
  "Homeopathy",
  "Unani and Siddha",
  "Elder Care",
  "Baby Care",
  "Orthopaedic Care",
  "Mobility Aids",
  "Medicated Hair Care",
  "Medicated Skin Care",
  "Speciality Face Cleansers",
  "Gastric Care",
  "ENT Care",
  "Eye Care",
  "Cold and Cough",
  "Sexual Wellness",
  "Feminine Care",
  "Maternity Care",
  "Nursing and Feeding",
  "Hand Wash",
  "Sanitizers",
  "Baby Care - Wipes and Buds",
  "Baby Care - Rash Creams",
  "Baby Care - Diapers and Accessories",
  "Health and Safety",
  "Oral Care",
  "Contraceptives",
  "Breathe Easy",
  "Health Foods and Drinks",
  "Wound Care and Dressings",
  "Surgicals",
  "Mental Wellness",
  "Gift Voucher",
  "Personal Care",
  "Healthcare Devices",
  "Ayush",
];
