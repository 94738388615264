import { outletLevelOffers } from "types/outlet";

const AssociatedOffers = ({
  offers,
  type,
}: {
  type: string;
  offers: outletLevelOffers;
}) => {
  return (
    <section className="bd-btm pb-50">
      <div className="flex justify-stretch align-center">
        <p className="txt-14 txtw-6 txt-ucase clr-grey">{type} level offers</p>
        {/* <Button
                    leftIcon={<Plus />}
                    type="filled-secondary"
                    text="Add an Offer"
                    onClick={() => {}}
                /> */}
      </div>
      <div className="grid grid-cols-3 gap-20 mt-15">
        {offers?.map((offer, index) => (
          <article
            className="bg-white brd-10 pt-10 pr-30 pb-10 pl-20"
            key={index}
          >
            <p className="txt-14 txtw-5">{offer.title}</p>
            <p className="txt-12 txtw-5 clr-grey mt-8">{offer.description}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default AssociatedOffers;
