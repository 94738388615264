import { createContext, useContext, useEffect, useState } from "react"

interface intervalType {
    text: string
    setText: Function
}
const NotificationContext = createContext<intervalType>({
    text: "",
    setText: () => {},
})

export const NotificationProvider = ({ children }: any) => {
    const [text, setText] = useState("")

    useEffect(() => {
        if (text.length > 0) {
            setTimeout(() => {
                setText("")
            }, 4000)
        }
    }, [text])

    return (
        <NotificationContext.Provider
            value={{
                text,
                setText,
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}

export const useNotification = () => useContext(NotificationContext)
