import { useEffect, useState } from "react";
import { Input, Select, TextInput, MultiSelect, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
import {
  getItemList,
  getProvidersList,
  getSectionDetails,
} from "services/apis/admin/section";
import "react-quill/dist/quill.snow.css";
import { useGetBannerListQuery } from "hooks/Admin/bannersAPI";
import { useNotification } from "context";
import { DownArrowSVG } from "assets/icons";

import {
  useAddSectionMutation,
  useUpdateSectionMutation,
} from "hooks/Admin/sectionAPI";
import { useGetOfferListQuery } from "hooks/Admin/offersAPI";
import {
  appliances,
  bpc,
  electronics,
  fashion,
  groceries,
  healthAndWellness,
  homeAndKitchen,
} from "constants/subcategories";
import { useGetCategoryListQuery } from "hooks/Admin/categoryAPI";
import { useGetAllSubCategoryListQuery } from "hooks/Admin/subCategoryAPI";

interface LabelValuePair {
  label: string;
  value: string;
}
const BasicDetails = ({
  setAddBannerModalVisible,
  setEditOfferModal,
  editOfferModal,
}: {
  setAddBannerModalVisible: Function;
  setEditOfferModal: Function;
  editOfferModal: any;
}) => {
  const sectionDetailsForm = useForm<any>({
    validateInputOnChange: true,
    initialValues: {
      name: "",
      displayFormat: "",
      redirect: { name: "", url: "" },
      category: "",
      items: "",
      dynamic: {
        action: "",
        limit: "",
      },
    },

    validate: {
      name: (value) => {
        if (value?.length === 0) {
          return "Title is required";
        } else if (value?.length > 30) {
          return "Title must be 30 characters or less";
        }
        return null;
      },
    },
  });

  const { setText } = useNotification();

  const [updateSection] = useUpdateSectionMutation();
  const [addSection] = useAddSectionMutation();
  const [selectedDataType, setSelectedDataType] = useState("");
  const [itemList, setItemList] = useState([]);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [Categ, setCateg] = useState<string>("");
  const [subCategList, setSubCategList] = useState<any[]>([]);
  const [selectedSubCateg, setSelectedSubCateg] = useState<string>("");
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [providerItems, setProviderItems] = useState<string[]>([]);
  const [isOfferAvailable, setIsOfferAvailable] = useState<boolean>(false);
  const [selectedRadius, setSelectedRadius] = useState<number>(0);
  const [selectedGPS, setSelectedGPS] = useState<number>(0);

  const dataTypes = [
    "Providers",
    "Items",
    "Category",
    "Sub-category",
    "Orders",
    "Offers",
    "Banners",
    "Custom",
  ];
  const { data: bannerList } = useGetBannerListQuery(
    {
      page: "1",
      size: "1000",
    },
    { skip: selectedDataType === "Banners" }
  );
  const { data: offersList } = useGetOfferListQuery(
    {
      page: "1",
      size: "1000",
    },
    { skip: selectedDataType === "Offers" }
  );

  const { data: CategoryList } = useGetCategoryListQuery(
    {
      page: "1",
      size: "1000",
    },
    { skip: selectedDataType === "Category" }
  );
  const { data: SubCategoryList } = useGetAllSubCategoryListQuery(
    {
      page: "1",
      size: "1000",
    },
    { skip: selectedDataType === "Sub-category" }
  );

  const fetchSectionDetails = async (id: any) => {
    const response = await getSectionDetails(id);
    if (response.success) {
      const section = response.data.sections[0];

      const itemsData =
        section?.items?.map((item: any) => ({
          value: item._id,
          label: item?.descriptor?.name,
        })) || [];
      setItemList(itemsData);

      sectionDetailsForm.setValues({
        name: section?.name,
        redirect_name: section?.redirect.name,
        redirect_url: section?.redirect.url,
        displayFormat: section?.displayFormat,
        category: section?.category
          ? section?.category === "items"
            ? "Items"
            : section?.category === "category"
            ? "Category"
            : section?.category === "providers"
            ? "Providers"
            : section?.category === "sub-category"
            ? "Sub-category"
            : section?.category === "offers"
            ? "Offers"
            : section?.category === "banners"
            ? "Banners"
            : section?.category
          : "",
        items: section?.items?.map((item: any) => item._id) || [],
        isActive: section?.isActive,
        custom: section?.items && section?.items?.length > 0,
        dynamic_action: section?.dynamic?.action,
        dynamic_limit: section?.dynamic?.limit,
      });
      setSelectedDataType(
        section?.category
          ? section?.category === "items"
            ? "Items"
            : section?.category === "category"
            ? "Category"
            : section?.category === "providers"
            ? "Providers"
            : section?.category === "sub-category"
            ? "Sub-category"
            : section?.category
          : ""
      );
      if (section?.items && section.items.length > 0) {
        setIsCustom(true);
      } else {
        setIsCustom(false);
      }
      if (section?.items && section.items.length > 0) {
        setIsCustom(true);
      } else {
        setIsCustom(false);
      }
    }
  };

  useEffect(() => {
    if (editOfferModal.isOpen) {
      fetchSectionDetails(editOfferModal?.offer?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOfferModal.isOpen]);

  useEffect(() => {
    switch (selectedDataType) {
      case "Banners":
        if (bannerList?.length !== 0) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setItemList(
            bannerList?.data?.banners?.map((banner: any) => {
              return { value: banner._id, label: banner.title };
            })
          );
        }
        break;
      case "Offers":
        if (offersList.length !== 0) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setItemList(
            offersList.data?.updated_offers?.map((offer: any) => {
              return { value: offer._id, label: offer.title };
            })
          );
        }
        break;
      case "Sub-category":
        if (SubCategoryList.length !== 0) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setItemList(
            SubCategoryList.data?.map((subcat: any) => {
              return { value: subcat._id, label: subcat.subCategoryName };
            })
          );
        }
        break;

      case "Category":
        if (CategoryList.length !== 0) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setItemList(
            CategoryList.data?.data?.map((category: any) => {
              return { value: category._id, label: category.displayName };
            })
          );
        }
        break;

      default:
      // setItemList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataType, offersList, bannerList, subCategList]);

  const sectionFormats = [
    "portrait-banner",
    "full-width-banner",
    "6-Grid-Circle",
    "6-Grid-Square",
    "Carousel",
  ];

  const actionFormats = ["all", "recent", "gps"];

  async function handleAddSection() {
    let item_ids;
    switch (sectionDetailsForm.values.category.toLowerCase()) {
      case "banners":
        item_ids = bannerList?.data?.banners?.filter((banner: any) => {
          return sectionDetailsForm.values.items.includes(banner._id);
        });

        break;
      case "offers":
        item_ids = offersList?.data?.updated_offers?.filter((offer: any) => {
          return sectionDetailsForm.values.items.includes(offer._id);
        });
        break;
      case "category":
        item_ids = CategoryList.data.data?.filter((category: any) => {
          return sectionDetailsForm.values.items.includes(category._id);
        });
        break;
      case "items":
        item_ids = providerItems?.filter((item: any) => {
          return sectionDetailsForm.values.items.includes(item._id);
        });
        break;
      case "providers":
        item_ids = providerItems?.filter((provider: any) => {
          return sectionDetailsForm.values.items.includes(provider._id);
        });
        break;
      case "sub-category":
        item_ids = SubCategoryList?.data?.filter((subCat: any) => {
          return sectionDetailsForm.values.items.includes(subCat._id);
        });
        break;

      default:
        break;
    }

    let payload: any = {
      name: sectionDetailsForm.values.name || undefined,
      category: sectionDetailsForm.values.category.toLowerCase(),
      displayFormat: sectionDetailsForm.values.displayFormat.toLowerCase(),
      redirect: {
        name: sectionDetailsForm.values.redirect_name,
        url: sectionDetailsForm.values.redirect_url,
      },
      items: item_ids,
    };

    if (!isCustom) {
      payload.dynamic = {
        action: sectionDetailsForm?.values?.dynamic_action,
        limit: sectionDetailsForm?.values?.dynamic_limit,
      };
    }
    if (!payload.category) {
      delete payload.category;
    }

    const id = editOfferModal?.offer?._id;
    if (editOfferModal.isOpen) {
      const response = await updateSection({ id, payload });

      if (response?.data?.success) {
        setEditOfferModal({
          isOpen: false,
          offer: "",
        });
        setText("Section Updated");
      } else {
        setText(response?.error?.data?.error);
      }
    } else {
      const response = await addSection(payload);

      if (response?.data?.success) {
        setAddBannerModalVisible(false);
        setText("Section Added");
      } else {
        setText(response?.error?.data?.error);
      }
    }
  }

  useEffect(() => {
    switch (Categ) {
      case "ONDC:RET10":
        setSubCategList(groceries);

        break;
      case "ONDC:RET12":
        setSubCategList(fashion);
        break;

      case "ONDC:RET14":
        setSubCategList(electronics);
        break;
      case "ONDC:RET15":
        setSubCategList(appliances);
        break;
      case "ONDC:RET13":
        setSubCategList(bpc);
        break;
      case "ONDC:RET16":
        setSubCategList(homeAndKitchen);
        break;
      case "ONDC:RET18":
        setSubCategList(healthAndWellness);
        break;

      default:
        setSubCategList([]);
        break;
    }
  }, [Categ]);

  const fetchItem = async (e: any) => {
    if (selectedDataType === "Items") {
      const queryParams = {
        "search-text": search ?? "",
        "sub-category-id": selectedSubCateg,
        "price-range": minPrice && maxPrice ? `${minPrice}-${maxPrice}` : "",
      };

      const item = await getItemList(queryParams);

      setProviderItems(item.data);
      setItemList(
        item.data?.map((item: any) => {
          return { value: item._id, label: item.descriptor.name };
        })
      );
    } else if (selectedDataType === "Providers") {
      const queryParams = {
        GPS: String(selectedGPS ? selectedGPS : ""),
        radius: String(selectedRadius ? selectedRadius : ""),
        category: Categ,
        subCategory: selectedSubCateg,
        offers: isOfferAvailable,
      };

      const provider = await getProvidersList(queryParams);

      setProviderItems(provider.data);
      setItemList(
        provider?.data?.map((provider: any) => {
          return { value: provider._id, label: provider.descriptor.name };
        })
      );
    }
  };

  function convertToLabelValuePairs<T>(
    data: T[],
    labelKey: keyof T,
    valueKey: keyof T
  ): LabelValuePair[] {
    if (!Array.isArray(data)) {
      return [];
    }

    return data?.map((item) => ({
      label: String(item[labelKey]),
      value: String(item[valueKey]),
    }));
  }

  return (
    <form
      className="w-650 pt-20 pb-20 pl-20 pr-20"
      onSubmit={sectionDetailsForm.onSubmit(handleAddSection)}
    >
      <p className="txt-16 mb-20">
        <b>Section Details</b>
      </p>
      <div className="grid grid-cols-2 col-gap-40 row-gap-25 pb-10">
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="SECTION TITLE"
          required
        >
          <TextInput
            placeholder="Enter Section Title"
            variant="filled"
            {...sectionDetailsForm.getInputProps("name")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="REDIRECT NAME"
          required
        >
          <TextInput
            placeholder="Enter Redirection Name"
            variant="filled"
            {...sectionDetailsForm.getInputProps("redirect_name")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="REDIRECT URL"
          required
        >
          <TextInput
            placeholder="Enter URL to Redirect"
            variant="filled"
            {...sectionDetailsForm.getInputProps("redirect_url")}
          />
        </Input.Wrapper>
        <Select
          data={sectionFormats}
          label="display format"
          placeholder="Pick the Format of Section"
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          rightSection={<DownArrowSVG />}
          {...sectionDetailsForm.getInputProps("displayFormat")}
          onChange={(e: any) => {
            sectionDetailsForm.setFieldValue("displayFormat", e);
          }}
        />
        <Select
          data={dataTypes}
          label="Data Type"
          placeholder="Pick a Data Type"
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          rightSectionWidth={sectionDetailsForm.values.subCategory ? 50 : 30}
          rightSection={
            sectionDetailsForm.values.subCategory ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    sectionDetailsForm.setFieldValue("category", "")
                  }
                >
                  remove
                </span>
              </div>
            ) : (
              <DownArrowSVG />
            )
          }
          {...sectionDetailsForm.getInputProps("category")}
          onChange={(e: any) => {
            sectionDetailsForm.setFieldValue("category", e);
            setSelectedDataType(e);
          }}
        />
      </div>

      <div className="flex flex-col space-y-4">
        <div className="flex justify-start items-center gcol-start-1 gcol-end-2 space-x-4">
          <p className="txt-12 txtw-5 txt-ucase clr-grey">Custom Items</p>
          <Switch
            checked={sectionDetailsForm.values.custom}
            color="primary"
            {...sectionDetailsForm.getInputProps("custom", {
              type: "checkbox",
            })}
            onChange={(e: any) => {
              isCustom ? setIsCustom(false) : setIsCustom(true);
            }}
          />
        </div>

        {isCustom && selectedDataType === "Items" && (
          <>
            <div style={{ marginTop: "8px" }}>
              <Input.Wrapper
                classNames={{ label: "clr-grey txt-12 txtw-4" }}
                label="SEARCH"
              >
                <TextInput
                  placeholder="Enter Search String"
                  variant="filled"
                  {...sectionDetailsForm.getInputProps("search_string")}
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                />
              </Input.Wrapper>

              <Input.Wrapper
                classNames={{ label: "clr-grey txt-12 txtw-4" }}
                label="MINIMUM PRICE"
              >
                <TextInput
                  placeholder="Enter Minimum Price"
                  variant="filled"
                  {...sectionDetailsForm.getInputProps("min_price")}
                  onChange={(e: any) => {
                    setMinPrice(e.target.value);
                  }}
                />
              </Input.Wrapper>
            </div>
            <div style={{ marginTop: "8px" }}>
              <Input.Wrapper
                classNames={{ label: "clr-grey txt-12 txtw-4" }}
                label="MAXIMUM PRICE"
              >
                <TextInput
                  placeholder="Enter Maximum Price"
                  variant="filled"
                  {...sectionDetailsForm.getInputProps("max_price")}
                  onChange={(e: any) => {
                    setMaxPrice(e.target.value);
                  }}
                />
              </Input.Wrapper>
            </div>
            {/* <div style={{ marginTop: "8px" }}>
              <Select
                data={convertToLabelValuePairs(
                  CategoryList?.data?.data,
                  "displayName",
                  "domain"
                )}
                label="CATEGORY"
                placeholder="Select the Category"
                classNames={{
                  root: "gcol-start-1 gcol-end-3",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                rightSection={<DownArrowSVG />}
                {...sectionDetailsForm.getInputProps("categ")}
                onChange={(e: any) => setCateg(e)}
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <Select
                data={subCategList}
                label="SUB CATEGORY"
                placeholder="Select the Sub-Category"
                classNames={{
                  root: "gcol-start-1 gcol-end-3",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                rightSection={<DownArrowSVG />}
                {...sectionDetailsForm.getInputProps("sub_categ")}
                onChange={(e: any) => setSelectedSubCateg(e)}
              />
            </div> */}

            <div className="flex justify-start bd-top pt-10">
              <Button type="outline" text="Fetch Items" onClick={fetchItem} />
            </div>
          </>
        )}

        {isCustom && selectedDataType === "Providers" && (
          <>
            <div style={{ marginTop: "8px" }}>
              <Input.Wrapper
                classNames={{ label: "clr-grey txt-12 txtw-4" }}
                label="AREA CODE"
              >
                <TextInput
                  placeholder="Enter AREA CODE"
                  type="number"
                  variant="filled"
                  {...sectionDetailsForm.getInputProps("areaCode")}
                  onChange={(e: any) => {
                    setSelectedGPS(e.target.value);
                  }}
                />
              </Input.Wrapper>
            </div>
            {/* <div style={{ marginTop: "8px" }}>
              <Input.Wrapper
                classNames={{ label: "clr-grey txt-12 txtw-4" }}
                label="RADIUS (KMS)"
              >
                <TextInput
                  placeholder="Enter Radius in kms"
                  type="number"
                  variant="filled"
                  {...sectionDetailsForm.getInputProps("radius")}
                  onChange={(e: any) => {
                    setSelectedRadius(e.target.value);
                  }}
                />
              </Input.Wrapper>
            </div> */}
            <div style={{ marginTop: "8px" }}>
              <Select
                data={convertToLabelValuePairs(
                  CategoryList?.data?.data,
                  "displayName",
                  "domain"
                )}
                label="CATEGORY"
                placeholder="Select the Category"
                classNames={{
                  root: "gcol-start-1 gcol-end-3",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                rightSection={<DownArrowSVG />}
                {...sectionDetailsForm.getInputProps("categ")}
                onChange={(e: any) => setCateg(e)}
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <Select
                data={subCategList}
                label="SUB CATEGORY"
                placeholder="Select the Sub-Category"
                classNames={{
                  root: "gcol-start-1 gcol-end-3",
                  label: "txt-12 txtw-4 clr-grey txt-ucase",
                }}
                rightSection={<DownArrowSVG />}
                {...sectionDetailsForm.getInputProps("sub_categ")}
                onChange={(e: any) => setSelectedSubCateg(e)}
              />

              <div className="flex justify-start items-center gcol-start-1 gcol-end-2 space-x-4 p-10">
                <p className="txt-12 txtw-5 txt-ucase clr-grey pr-10">
                  Offers Available
                </p>
                <Switch
                  checked={sectionDetailsForm.values.custom}
                  color="primary"
                  {...sectionDetailsForm.getInputProps("offers", {
                    type: "checkbox",
                  })}
                  onChange={(e: any) => {
                    isOfferAvailable
                      ? setIsOfferAvailable(false)
                      : setIsOfferAvailable(true);
                  }}
                />
              </div>
            </div>

            <div className="flex justify-start bd-top pt-10">
              <Button
                type="outline"
                text="Fetch Providers"
                onClick={fetchItem}
              />
            </div>
          </>
        )}
        {isCustom ? (
          <>
            <MultiSelect
              data={itemList}
              disabled={!itemList?.length || !isCustom}
              label="Items"
              placeholder="Pick Items"
              classNames={{
                root: "gcol-start-1 gcol-end-3",
                label: "txt-12 txtw-4 clr-grey txt-ucase",
              }}
              multiple
              rightSection={
                sectionDetailsForm.values.subCategory ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={(e) =>
                        sectionDetailsForm.setFieldValue("items", "")
                      }
                    >
                      remove
                    </span>
                  </div>
                ) : (
                  <DownArrowSVG />
                )
              }
              {...sectionDetailsForm.getInputProps("items")}
            />
          </>
        ) : (
          <>
            <Input.Wrapper
              classNames={{
                label: "clr-grey txt-12 txtw-4",
              }}
              label="Limit"
              required
            >
              <TextInput
                placeholder="Enter limit eg. 10-d20"
                variant="filled"
                {...sectionDetailsForm.getInputProps("dynamic_limit")}
              />
            </Input.Wrapper>
            <Select
              data={actionFormats}
              label="Select Action"
              placeholder="Action"
              classNames={{
                root: "gcol-start-1 gcol-end-3",
                label: "txt-12 txtw-4 clr-grey txt-ucase",
              }}
              rightSection={<DownArrowSVG />}
              {...sectionDetailsForm.getInputProps("dynamic_action")}
              onChange={(e: any) => {
                sectionDetailsForm.setFieldValue("dynamic_action", e);
              }}
            />
          </>
        )}
      </div>
      <div className="flex justify-end bd-top pt-10">
        <Button
          type="filled-primary"
          text={`${editOfferModal.isOpen ? "Edit" : "Add"}`}
          loading={false}
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

export default BasicDetails;
