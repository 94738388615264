import CustomTable from "components/CustomTable/CustomTable"
import { Link } from "react-router-dom"
import {
  capitalize,
  formatCurrencyValue,
  getTimeAgo,
  rideStatusMap,
} from "utils/helper"
import styles from "./Dashboard.module.scss"
import { createColumnHelper } from "@tanstack/react-table"

import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import {
  useGetCountQuery,
  useGetRecentRidesQuery,
  // useGetRidesByLocationQuery,
  useGetRidesRatioByMonthQuery,
  useGetTopRidesByLocationQuery,
} from "hooks/Mobility/dashboardAPI"
import getSymbolFromCurrency from "currency-symbol-map"
import BarChartComponent from "./Charts/BarChart"

export default function MobilityDashboard() {
  const { data: countDetails } = useGetCountQuery({})
  const { data: ridesRatioByMonth } = useGetRidesRatioByMonthQuery({})
  // const { data: ridesByLocationList } = useGetRidesByLocationQuery({})
  const { data: topRidesByLocationList } = useGetTopRidesByLocationQuery({})
  const { data: recentRidesList } = useGetRecentRidesQuery({})

  const {
    cancelledRides,
    completedRides,
    currency,
    customers,
    rideCount,
    totalRevenue,
  } = countDetails?.result ?? {}

  const overviewData = countDetails
    ? [
        { title: "Total rides", value: rideCount, link: "/orders" },
        { title: "Total riders", value: customers, link: "" },
        {
          title: "Total revenue",
          value: getSymbolFromCurrency(currency) + totalRevenue,
          link: "",
        },
        { title: "Completed rides", value: completedRides, link: "" },
        { title: "Cancelled rides", value: cancelledRides, link: "" },
      ]
    : null

  const currentYearRidesData = ridesRatioByMonth?.result[0]?.months

  const ridesRatioByMonthData = currentYearRidesData?.length
    ? currentYearRidesData.map(
        ({
          month,
          totalRides,
          totalRevenue,
        }: {
          month: string
          totalRides: number
          totalRevenue: number
        }) => ({
          name: month,
          "Total Rides": totalRides,
          "Total Revenue": totalRevenue,
        })
      )
    : null

  const topTripLocations =
    topRidesByLocationList && topRidesByLocationList?.result?.length > 0
      ? topRidesByLocationList.result.map(
          (item: { _id: string; totalRides: number }, sno: number) => ({
            sno: sno + 1,
            location: item._id,
            rides: item.totalRides,
          })
        )
      : null

  const recentRides =
    recentRidesList?.result?.length > 0
      ? recentRidesList.result
          .filter((item: any) => item._id && item.rideStatus && item.updatedAt)
          .map((item: any, sno: number) => ({
            sno: sno + 1,
            _id: item._id,
            username: item.rider.name || "",
            time: item.updatedAt,
            status: capitalize(rideStatusMap(item.rideStatus).toLowerCase()),
          }))
      : null

  const columnHelper = createColumnHelper()

  const topTripLocationsColumns = [
    columnHelper.accessor("sno", {
      header: "S.No",
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("location", {
      header: "Location",
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("rides", {
      header: "No. of Rides",
      cell: (info: any) => info.getValue(),
    }),
  ]

  const recentRidesColumns = [
    columnHelper.accessor("sno", {
      header: "S.No",
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("username", {
      header: "Rider",
      cell: (info: any) => info.getValue(),
    }),
    columnHelper.accessor("time", {
      header: "Last Ride Time",
      cell: (info: any) => getTimeAgo(info.getValue()),
    }),
    columnHelper.accessor("status", {
      header: "Ride Status",
      enableSorting: true,
      cell: (info: any) => <RoleStatusBadge type={info.getValue()} />,
    }),
    columnHelper.accessor("rideDetails", {
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <Link
          to={`/orders/${info.row.original._id}`}
          className="txt-14 txtw-6 clr-primary no-underline"
        >
          View Details
        </Link>
      ),
      size: 80,
    }),
  ]

  return (
    <div className="flex flex-col justify-stretch my-30 mx-80">
      <p className="txtw-7 pb-10 txt-18">Quick Overview</p>
      <div className="flex w-full flex-wrap justify-stretch gap-20">
        {overviewData !== null &&
          overviewData.map(({ title, value, link }) => (
            <div
              className="flex flex-1 bd-full brd-8 bg-white px-24 py-20"
              key={title}
            >
              <div className="flex flex-col">
                <span className="txt-14 clr-grey txtw-5">{title}</span>
                <span className="txtw-8 txt-36 mt-8 mb-16">
                  {typeof value === "string" && value.includes("₹") && "₹"}
                  {formatCurrencyValue(value)}
                </span>
                {link && (
                  <Link
                    to={link}
                    className="txt-14 txtw-6 clr-primary no-underline"
                  >
                    View Details
                  </Link>
                )}
              </div>
            </div>
          ))}
      </div>
      {ridesRatioByMonthData && ridesRatioByMonthData.length > 0 && (
        <div className="mt-30">
          <p className="txtw-7 pb-10 txt-18">Total Revenue & Rides Taken</p>
          <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
            <BarChartComponent
              data={ridesRatioByMonthData}
              dataKeys={[
                {
                  key: "Total Rides",
                  fill: "#129b4d",
                  stackId: "a",
                  radius: 0,
                },
                {
                  key: "Total Revenue",
                  fill: "#f0792e",
                  stackId: "a",
                },
              ]}
            />
          </div>
        </div>
      )}
      {/* 
      <div className="mt-30">
        <p className="txtw-7 pb-10 txt-18">Trip Trends</p>
        <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
          <LineChartComponent
            data={ridesRatioVsMonths}
            dataKeys={[
              {
                key: "rides",
                fill: "#f0792e",
                legend: "Rides",
              },
            ]}
            yaxis={{
              ticks: [0, 1000, 2000, 3000, 4000, 5000],
            }}
          />
        </div>
      </div> */}

      {topTripLocations && (
        <div className="flex justify-stretch">
          <div className="mt-30 flex-1">
            <div className="h-full flex flex-col bd-full brd-8 bg-white px-20">
              <div className="flex justify-stretch align-center pt-20">
                <p className="txtw-7 pb-10 txt-18">Top Trip Locations</p>
                {/* <Link
                  to="/"
                  className="pb-10 txt-14 txtw-6 clr-primary no-underline"
                >
                  View All
                </Link> */}
              </div>
              <CustomTable
                data={topTripLocations.slice(0, 10)}
                columns={topTripLocationsColumns}
                className={styles["trip-table"]}
              />
            </div>
          </div>
        </div>
      )}

      {/* {ridesByLocation && (
        <div className="mt-30">
          <p className="txtw-7 pb-10 txt-18">Rides by Location</p>
          <div className="w-full h-300 flex justify-stretch bd-full brd-8 bg-white px-50 py-20">
            <BarChartComponent
              data={ridesByLocation.slice(0, 6)}
              dataKeys={[
                {
                  key: "rides",
                  fill: "#f0792e",
                },
              ]}
            />
          </div>
        </div>
      )} */}
      {recentRides && recentRides.length > 0 && (
        <div className="flex justify-stretch">
          <div className="mt-30 flex-1">
            <div className="h-full flex flex-col bd-full brd-8 bg-white px-20">
              <div className="flex justify-stretch align-center pt-20">
                <p className="txtw-7 pb-10 txt-18">Recent Rides</p>
                <Link
                  to="/orders"
                  className="pb-10 txt-14 txtw-6 clr-primary no-underline"
                >
                  View All
                </Link>
              </div>
              <CustomTable
                data={recentRides.slice(0, 5)}
                columns={recentRidesColumns}
                className={styles["recent-rides-table"]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
