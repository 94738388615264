import axios from "axios"
import { postOutletType } from "types/outlet"
import { adminBaseUrl } from "services/url"
import { getStorageItem } from "services/storage"

const outletsUrl = `${adminBaseUrl}/outlets`

export const getOutlets = async (page: string | null, size: string | null) => {
  if (page !== null && size !== null) {
    const response = await axios({
      method: "GET",
      url: `${outletsUrl}?page=${page}&size=${size}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const getAllOutlets = async () => {
  const response = await axios({
    method: "GET",
    url: outletsUrl,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response.data
}

export const getOutlet = async (outletId: string | undefined) => {
  if (outletId) {
    const response = await axios({
      method: "GET",
      url: `${outletsUrl}/${outletId}`,
      headers: {
        Authorization: `${getStorageItem("token")}`,
      },
    })
    return await response.data
  }
}

export const postOutlet = async (outletData: postOutletType) => {
  const response = await axios({
    method: "POST",
    url: outletsUrl,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...outletData,
    },
  })
  return await response.data
}

export const patchOutletBrands = async (
  brands: { brandId: string; petpoojaId: string }[],
  outletId: string
) => {
  const response = await axios({
    method: "PATCH",
    url: `${outletsUrl}/addBrand/${outletId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      updates: [...brands],
    },
  })
  return await response.data
}

export const deleteOutlet = async (outletId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${outletsUrl}/${outletId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
  })
  return await response
}

export const editOutlet = async (outletId: string, outletData: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${outletsUrl}/${outletId}`,
    headers: {
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...outletData,
    },
  })
  return await response.data
}
