import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineProps,
  CartesianGrid,
} from "recharts"
import { generateColor } from "utils/helper"

type Props = {
  data: {}[]
  dataKeys: Array<{
    fill?: string
    key: string
    legend?: string
  }>
  lineType?: Pick<LineProps, "type">
  yaxis?: {
    ticks?: (string | number)[] | undefined
  }
}

function LineChartComponent({ data, dataKeys }: Props) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={600} height={300} data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickMargin={10}
          interval={0}
          tickLine={false}
          padding={{ right: 30, left: 30 }}
        />
        <YAxis hide={false} tickLine={false} />
        <CartesianGrid strokeDasharray="1 3" vertical={false} />
        <Tooltip cursor={{ fill: "transparent" }} />
        {dataKeys.map(({ key, fill, legend }, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={key}
            stroke={fill || generateColor()}
            strokeWidth={2}
            name={legend}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartComponent
