import { MouseEventHandler, ReactElement } from "react"
import { Button as Btn } from "@mantine/core"
import "./Button.scss"

interface propTypes {
  className?: string
  type: string
  text: any
  leftIcon?: ReactElement
  classNames?: {
    root?: string
    label?: string
  }
  loading?: boolean
  onClick: MouseEventHandler<HTMLButtonElement> | undefined
  disabled?: boolean
}

const Button = ({
  type,
  text,
  classNames,
  leftIcon,
  loading = false,
  onClick,
  className,
  disabled = false,
}: propTypes) => {
  return type === "filled-primary" ? (
    <Btn
      className={className}
      type="submit"
      loading={loading}
      onClick={onClick}
      classNames={{
        ...classNames,
      }}
      disabled={disabled}
    >
      {text}
    </Btn>
  ) : type === "filled-secondary" ? (
    <Btn
      className={className}
      leftIcon={leftIcon}
      loading={loading}
      onClick={onClick}
      classNames={{
        root: "filled-sec-btn",
        ...classNames,
      }}
      disabled={disabled}
    >
      {text}
    </Btn>
  ) : type === "subtle" ? (
    <Btn
      className={className}
      variant="subtle"
      loading={loading}
      onClick={onClick}
      leftIcon={leftIcon}
      classNames={{
        ...classNames,
        root: "subtle-btn",
      }}
      disabled={disabled}
    >
      {text}
    </Btn>
  ) : type === "default" ? (
    <Btn
      className={className}
      leftIcon={leftIcon}
      loading={loading}
      variant="default"
      classNames={{
        root: "default-btn",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Btn>
  ) : type === "outline" ? (
    <Btn
      className={className}
      leftIcon={leftIcon}
      loading={loading}
      variant="outline"
      classNames={{}}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Btn>
  ) : (
    <></>
  )
}

export default Button
