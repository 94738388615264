import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ children }: any) => {
    return sessionStorage.getItem("token") ? (
        children
    ) : (
        <Navigate replace to="/login" />
    )
    // return children;
}

export default ProtectedRoute
